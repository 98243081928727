.contactInfo-toolbar {
    background: $blueDark;
    margin: 30px 0;
	padding: 10px 30px;
    > div {
        justify-content: center;
		line-height: 30px;
        color: white;
        padding: 10px 0;
        display: block;
        i {
            float: left;
            font-size: 30px;
            margin-right: 10px;
        }
		b {
			margin-left: 4px;
		}
    }
}

@mixin tablet() {
    .contactInfo-toolbar {
        display: flex;
        > div {
            display:flex;
            flex: 1 1 auto;
            padding: 20px 0;
            white-space: nowrap;
        }
    }
}

@media (min-width: $screen-sm-min) {
    :not(.preview-content) > {
        @include tablet();
    }
}

&.preview-container.desktop, &.preview-container.tablet {
    @include tablet();
}

.conactInfo-emailContainer {
	input[type="text"] {
		display: inline-block;
		&:first-of-type {
			width: 40% !important;
		}
		&:last-of-type {
			width: 50% !important;
		}
	}
}