.no-padding { padding: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding-left { padding-left: 0 !important; }

.padding-xs { padding: 5px !important; }
.padding-sm { padding: 10px !important; }
.padding-md { padding: 15px !important; }
.padding-lg { padding: 20px !important; }
.padding-xl { padding: 30px !important; }
.padding-xxl { padding: 40px !important; }

.padding-top-xs { padding-top: 5px !important; }
.padding-top-sm { padding-top: 10px !important; }
.padding-top-md { padding-top: 15px !important; }
.padding-top-lg { padding-top: 20px !important; }
.padding-top-xl { padding-top: 30px !important; }
.padding-top-xxl { padding-top: 40px !important; }

.padding-right-xs { padding-right: 5px !important; }
.padding-right-sm { padding-right: 10px !important; }
.padding-right-md { padding-right: 15px !important; }
.padding-right-lg { padding-right: 20px !important; }
.padding-right-xl { padding-right: 30px !important; }
.padding-right-xxl { padding-right: 40px !important; }

.padding-bottom-xs { padding-bottom: 5px !important; }
.padding-bottom-sm { padding-bottom: 10px !important; }
.padding-bottom-md { padding-bottom: 15px !important; }
.padding-bottom-lg { padding-bottom: 20px !important; }
.padding-bottom-xl { padding-bottom: 30px !important; }
.padding-bottom-xxl { padding-bottom: 40px !important; }

.padding-left-xs { padding-left: 5px !important; }
.padding-left-sm { padding-left: 10px !important; }
.padding-left-md { padding-left: 15px !important; }
.padding-left-lg { padding-left: 20px !important; }
.padding-left-xl { padding-left: 30px !important; }
.padding-left-xxl { padding-left: 40px !important; }


@media (max-width:$screen-sm-max) {
	.mobile-no-padding-bottom {
		padding-bottom:0 !important;
	}
}