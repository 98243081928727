.givingToolbar-toolbar {
	background: $sa-red;
	display: flex;
	overflow: visible;
	width: 100%;
	position: relative;
	justify-content: center;
	z-index: 1;

	.givingToolbar-slant {
		position: absolute;
		top: -100px;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
		overflow: hidden;
		z-index: -1;

		&:before {
			content: '';
			position: absolute;
			top: 100px;
			right: -30px;
			bottom: 0;
			left: -30px;
			background: $sa-red;
			transform-origin: top left;
			transform: rotate(-1.5deg);
		}
	}

	>div,
	>a {
		font-size: 14px;
		flex: 1 1 auto;
		display: flex;
		justify-content: center;
		color: white;
		text-transform: uppercase;
		padding: 5px;
		cursor: pointer;
		transition: all .35s;
		max-width: 220px;

		&:hover {
			background: none;
			text-decoration: none;

			span.line.double {
				&:after {
					opacity: 1;
				}
			}
		}

		i {
			height: 38px;
			line-height: 38px;
			font-size: 28px;
		}

		span.line {
			line-height: 38px;

			&.double {
				line-height: 38px;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					height: 2px;
					background: white;
					left: 0;
					right: 0;
					opacity: 0;
					transition: all .35s;
				}
			}

			span {
				display: none;
			}
		}
	}

	&.isMainNav {
		overflow: visible;
		font-size: 12px;

		ul.flyoutNav {
			list-style: none;
			padding: 0;
			margin: 0 auto;
			display: flex;
			width: 100%;
			@extend .container;

			.fa-angle-down {
				display: none;
			}

			li.flyoutNavItem {
				position: relative;
				list-style: none;
				flex: 1;

				a.flyoutNavItem-link {
					height: 48px;
					line-height: 1em;
					color: white;
					text-decoration: none;
					flex: 1 1 auto;
					display: flex;
					justify-content: center;
					flex-direction: column;
					text-align: center;
					text-transform: uppercase;
					padding: 5px 10px;
					cursor: pointer;
					// transition: all .35s;

					&:hover {
						text-decoration: none;
					}

				}

				&:hover {
					background: $brandLight;
				}

				ul {
					display: none;
					position: absolute;
					top: 48px;
					left: 0;
					right: 0;
					background: $brandLight;
					z-index: 10000000;
					padding: 0;
					margin: 0;
					box-shadow: $shadowLight;

					li.flyoutNavItem {
						background: $greyLighter;

						a.flyoutNavItem-link {
							color: black;

							&:hover {
								background: rgba(0, 0, 0, .1);
							}
						}
					}
				}

				&:hover {
					ul {
						display: block;
					}
				}
			}
		}
	}

	body:not(.localSite) & {
		display: none;
	}
}

@mixin tablet() {
	.givingToolbar-toolbar {
		&.isMainNav {
			font-size: 13px;

			@media (min-width:1450px) {
				font-size: 15px;
			}
		}

		>div,
		>a {

			i {
				float: left;
				margin-right: 14px;
			}

			// span.line {
			// 	span:not('.tablet') {
			// 		display: none;
			// 	}

			// 	span.tablet {
			// 		display: inline;
			// 	}
			// }
		}
	}
}

@media (min-width: $screen-sm-min) {
	:not(.preview-content)> {
		@include tablet();
	}
}

&.preview-container.tablet,
&.preview-container.desktop {
	@include tablet();
}

@mixin desktop() {
	.givingToolbar-toolbar {

		>div,
		>a {
			padding: 25px 5px;

			.localSite & {
				padding: 14px 0;
			}

			i {
				font-size: 34px;

				.localSite:not(.home):not(.ministry) & {
					font-size: 20px;
					height: auto;
					line-height: 20px;
				}
			}

			span.line {
				line-height: 18px;
				display: block;

				&.double {
					line-height: 36px;
				}

				span {
					display: inline;
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	:not(.preview-content)> {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}

.givingToolbar-links {
	color: $greyMedium;

	>div,
	>a {
		display: flex;
		padding: 10px 0;

		>* {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.givingToolbar-link {
			text-transform: uppercase;
			white-space: nowrap;
			width: 190px;

			>span {
				height: 38px;

				i {
					float: left;
					margin-right: 14px;
					font-size: 28px;
					line-height: 38px;
					height: 38px;
				}
			}
		}

		>i {
			width: 110px;
			font-size: 28px;
			text-align: center;
		}

		.givingToolbar-description {
			flex: 1;
		}
	}
}

.givingToolbar-text {
	display: inline-block;
	vertical-align: middle;
}

.givingToolbar-toolbar>a {
	text-decoration: none;
}

.emailCaptureModal {
	margin: 0 auto;
	background: $brand;
	color: white;
	max-width: 580px;
	padding: 2em;
	border-radius: 5px;
	position: relative;

	.mfp-close {
		color: white;
	}

	input[type="submit"] {
		border: 1px solid white;
		max-width: none;
		padding-left: 0;
		padding-right: 0;
	}

	.hearMore-error {
		opacity: 1;
		transition: opacity .3s;

		&.u-hidden {
			display: block;
			opacity: 0;
		}
	}

	input {
		width: 100% !important;
	}

	button.submit {
		border: 1px solid white;
		line-height: 22px;
	}

	.facebookLink {
		color: white;
		font-size: 12px;
		line-height: 1;
		text-decoration: none;
		display: inline-block;
		padding: 1em 0 0;
		margin: 2em 0 0;
		font-weight: 100;
		opacity: 1;

		&:hover {
			opacity: .7;
			transition: opacity .35s;
		}

		strong {
			font-weight: 600;
			display: block;
		}

		i {
			color: $brand;
			background: white;
			width: 20px;
			height: 20px;
			text-align: center;
			line-height: 20px;
			border-radius: 50%;
			margin: 2px 0 0 5px;
		}
	}

	div[class^="col-sm"] {
		padding-bottom: 5px !important;
	}

	@media (max-width: $screen-xs-max) {
		div[class^="col-sm"] {

			&.no-padding,
			&.no-padding-right {
				padding-right: 15px !important;
				padding-left: 15px !important;
			}
		}
	}
}

// hide main nav giving toolbar on mobile
@mixin mainNavMobile {
	.givingToolbar-toolbar {
		&.isMainNav {
			display: none;
		}
	}
}

@media (max-width:$screen-sm-max) {
	@include mainNavMobile();
}

&.preview-container.tablet,
&.preview-container.mobile {
	@include mainNavMobile();
}
