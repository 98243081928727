.wuFooForm {
	padding-top: 1em;
	[class*="col-md"] {
		margin-bottom: 1em;
	}
	.btn {
		width: 100%;
		text-align: center;
	}
	.select {
		height: 43px;
	}
}

@media (min-width:$screen-md-min) {
	.wuFooForm [class*="col-md"] {
		margin-bottom:0;
	}
	.wuFooForm .row {
		margin-bottom: 1em;
	}
}