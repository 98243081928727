.footerContact {
    clear: both;
    position: relative;
    background: $sa-greyLight;
    text-align: center;
    padding-bottom: 50px;

    h2 {
        margin: 0;
        padding: 50px 0 30px;
    }

    p {
        margin: 0 auto;
        padding: 0 15px 30px;
        max-width: 650px;
    }

    i.top-icon {
        position: absolute;
        top: -23px;
        left: calc(50% - 23px);
        display: inline-block;
        width: 46px;
        height: 46px;
        line-height: 46px;
        background: $sa-navy;
        border-radius: 50%;
        color: white;
        z-index: 300;
    }

    a.btn {
        font-size: 16px;
        padding: 0px 25px;
        line-height: 34px;
        margin: 0;

        &+a.btn {
            margin-left: 10px;
        }
    }
}
