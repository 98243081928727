.story-gridRow {
	&.row {
		margin: 0 -5px;

		>[class^="col-"] {
			padding: 0 5px 10px;

			a.story-featured {
				display: block;
				background-size: cover !important;
				background-repeat: no-repeat !important;
				background-position: center !important;
				color: white;
				height: 250px;
				position: relative;

				&.story-big {
					height: 350px;
				}

				>div {
					position: absolute;
					left: 40px;
					bottom: 40px;
					right: 40px;
					z-index: 1;
				}

				.news-tags {

					a,
					span {
						color: white;

						&:hover {
							color: $brand;
						}
					}
				}
			}

			&.col-sm-12 a.story-featured>div {
				right: 50%;
			}

			&.col-sm-8 a.story-featured>div {
				right: 30%;
			}
		}
	}
}

.news-tags {

	a,
	span {
		cursor: pointer;
		color: $brand;
		text-decoration: underline;
		transition: color .35s;

		&:hover {
			color: $blueDark;
		}
	}
}

.story-listItem {
	margin-bottom: 30px;
	position: relative;

	.story-image {
		float: left;
		height: 200px;
		width: 300px;
		margin-right: 30px;
		margin-bottom: 30px;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		text-decoration: none;
	}

	.btn {
		padding: 5px 35px;
		position: relative;
		margin-top: 20px;
	}

	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

.story-title {
	font-family: $trajan-pro;
}

.stories-container.slick-initialized {
	.slick-prev {
		left: -19px !important;
	}

	.slick-next {
		right: -19px !important;
	}

	.slick-prev:before {
		color: black !important;
	}

	.slick-next:before {
		color: black !important;
	}

	.slick-dots {
		bottom: -20px;

		li.slick-active {
			button:before {
				color: black;
			}
		}

		li {
			button:before {
				color: grey;
			}
		}
	}



	.story-footerItem {
		padding: 15px;

		.story-image {
			display: block;
			height: 150px;
			margin-bottom: 15px;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			text-decoration: none;
		}

		.story-more {
			float: right;
			font-weight: bold;
		}
	}
}

.story-footerItem {
	.story-image {
		display: block;
		height: 150px;
		margin-bottom: 15px;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		text-decoration: none;
	}

	.story-more {
		float: right;
		font-weight: bold;
	}
}


.storyContent-meta {
	color: #28abe2;
	margin-top: -1em;
}

.storyContent-relatedContent {
	float: left;
	font-size: 90%;
	color: black;
	padding: .5em 0 0;
	line-height: 30px;
}

.storyContent-share {
	list-style-type: none;
	padding: .5em 0 0;
	text-align: right;
	float: right;
	white-space: nowrap;
	margin-bottom: 2em;

	li {
		display: inline-block;
		margin-right: 5px;
		position: relative;

		&.isClicked {
			margin-right: 45px;
		}

		>a {
			border-radius: 50%;
			width: 30px;
			height: 30px;
			display: block;
			text-align: center;
			transition: background .35s;

			.fa,
			.fab,
			.fas,
			.far {
				line-height: 30px;
				font-size: 15px;
				color: white;
				position: relative;
				z-index: 30;
			}

			&:hover {
				background-color: $brand;
			}
		}

		.at-icon-wrapper {
			width: 32px !important;
			height: 32px !important;
			line-height: 32px !important;
			border-radius: 50%;
			display: block;

			svg {
				width: 32px !important;
				height: 32px !important;
			}
		}
	}

}

.icon-x-twitter-story {
	position: relative;
}

.icon-x-twitter-story:before {
	content: url(../images/global/x-twitter-admin.svg);
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: sub;
    text-align: center;
    position: relative;
	top: -2px;
}

.storyContent-twitter {
	background-color: #d6d6d6;
}

.storyContent-facebook {
	background-color: $facebook;
}

.storyContent-generic {
	background-color: $greyDark;
}



.sidebarBlock-heading {
	padding: 1em 0 1em 1.5em;
	line-height: 1em;
	position: relative;
	color: $brand;

	&:before {
		content: "";
		width: 15px;
		background: $brand;
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
	}

	h5 {
		font-family: $sans-serif;
		font-size: 17px;
		font-weight: 600;
		margin: 0;
		line-height: 1em;
	}

	.fa,
	.fab,
	.fas,
	.far,
	.icon {
		margin-right: 5px;
	}

	.icon {
		fill: $brand;
		height: 20px !important;
		width: 20px !important;
		display: inline-block;
	}
}

.sidebarBlock {
	background: $greyLighter;
	margin-bottom: 1em;
}

.sidebarBlock-content {
	padding: 0 25px 25px;
	line-height: 1.3;
	font-size: 14px;

	input[type=email],
	input[type=text] {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right-width: 0;
		max-width: 81%;
		margin-right: -4px;
		padding: 9px 10px 10px;
		font-size: 13px;
		height: 34px;
		line-height: 34px;
	}

	input[type=submit],
	input.seeWhatsHappening-submit {
		border-radius: 5px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		font-size: 13px;
		min-width: 50px;
		padding-top: 0;
		padding-bottom: 0;
		height: 34px;
		line-height: 34px;

		&.big {
			min-width: 100px;
			padding: 0;
		}
	}

	.btn.btn-red {
		padding: 8px 15px;
		font-size: 13px;
	}

	.donateHeader-zipInput {
		padding-right: 10px !important;
	}

	.seeWhatsHappening-form,
	.donateHeader-goods {
		display: flex !important;
		justify-content: center;

		input[type=email] {
			margin: 0 !important;
			border-right-width: 0 !important;
		}

		.donateHeader-zip {
			width: auto;
			text-align: center;
		}

		.seeWhatsHappening-input,
		.donateHeader-zipInput {
			max-width: 100px !important;
			margin: 0 !important;
		}
	}
}

.addthis_sharing_toolbox {
	position: absolute;
	box-shadow: 0 0 3px #ccc;
	right: -44px;
	background: #313435;
	top: -3px;
	width: 44px;
	padding: 5px;
	z-index: 20;
	border-radius: 16px;
	display: none;

	&:before {
		content: "";
		display: block;
		background: #313435;
		height: 37px;
		width: 33px;
		left: -32px;
		top: 0;
		z-index: 5;
		border-bottom-left-radius: 50%;
		border-top-left-radius: 50%;
		position: absolute;
	}

	&.footer-share {
		top: auto;
		bottom: -3px;

		&:before {
			bottom: 0;
			top: auto;
		}
	}
}

.addthis_sharing_toolbox.isVisible {
	display: block;
	overflow: hidden;
}

.addthis_sharing_toolbox .at-share-btn {
	display: block;
}

.newsSource {
	font-size: 80%;
	font-style: italic;
	opacity: .8;
	margin-bottom: 5px;
}


.pagination {
	width: 100%;
	text-align: center;

	a {
		text-decoration: none;
		min-width: 20px;
		display: inline-block;
		text-align: center;
	}

	.arrow-container {
		display: flex;
		align-items: center;
	}

	.page-nav {
		color: #bbb;

		&:hover {
			color: black;
		}
	}

	.active-page {
		color: black;
	}
}

@mixin tabletDesktop() {
	.story-listItem {
		.story-image {
			margin-bottom: 1em;
		}
	}

	.storyContent-share {
		li {
			transition: margin .35s;

			&.isClicked {
				margin-right: 25px;
			}
		}
	}

	.storyContent-relatedContent {
		font-weight: 600;
	}

	.addthis_sharing_toolbox {
		border-top-left-radius: 0;

		&.footer-share {
			border-top-left-radius: 16px;
			border-bottom-left-radius: 0;
		}
	}

	.addthis_sharing_toolbox.isVisible {
		overflow: visible;
	}
}

@mixin tablet() {
	@include tabletDesktop();

	.sidebarBlock-content {

		.seeWhatsHappening-form,
		.donateHeader-goods {
			display: block !important;

			.seeWhatsHappening-input,
			.donateHeader-zipInput,
			input[type=email] {
				border-right-width: 1px !important;
				margin-bottom: 10px !important;
				max-width: none !important;
			}
		}

		input[type=submit],
		.btn.btn-red {
			border-radius: 5px;
			display: block;
			max-width: none !important;
			width: 100%;
		}
	}
}

@mixin desktop() {
	@include tabletDesktop();
}

@media (max-width: 1024px) {
	.stories-module {
		.story-footerItem {
			margin-bottom: 25px;
		}
	}
}

@media (max-width:$screen-sm-max) and (min-width:$screen-sm-min) {
	:not(.preview-content)> {
		@include tablet();
	}
}

@media (min-width: $screen-md-min) {
	:not(.preview-content)> {
		@include desktop();
	}
}

&.preview-container.tablet {
	@include tablet();
}

&.preview-container.desktop {
	@include desktop();
}
