.activityBoxesGrid {
	background: $greyBg;

	.container {
		margin: auto;
		@extend .u-resetList;
		font-size: 0;
		text-align: center;
		padding: 13px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		align-items: center;
		max-width: 1275px;

		.no-objectfit & {
			align-items: stretch;
		}
	}
}

.activityBoxesGrid-item {
	@extend .u-inlineBlock;
	font-size: 16px;
	flex: 1 1 50%;
	position: relative;
	height: 150px;
	overflow: hidden;

	&.hasMaxWidth {
		max-width: 50%;
		background-color: transparent;
	}
}

.activityBox-img-container {
	position: absolute;
	top: 5px;
	left: 25%;
	height: calc(100% - 51px);
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.activityBoxesGrid-image {
		max-width: 100%;
		max-height: 100%;
		margin: auto;
	}
}

.activityBoxesGrid-link {
	text-decoration: none;
	display: block;
	background: $white;
	color: $font-black;
	height: calc(100% - 8px);
	margin: 4px;
	position: relative;
	border-radius: 3px;
	overflow: hidden;

	&.compat-object-fit {
		background-color: transparent;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: inline-block;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
	}
}

.activityBoxesGrid-title {
	display: block;
	color: $font-black;
	position: absolute;
	bottom: 15px;
	width: 100%;
	opacity: 1;
	transition: opacity .35s;
	font-weight: 600;
	padding: 0 10px;
	height: 32px;
	line-height: 16px;
	word-break: break-word;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 2;
	@extend .u-textCenter;

	bottom: 15px;
	font-size: 20px;
	font-weight: 500;
	height: 40px;
	line-height: 20px;

}

.no-touchevents .activityBoxesGrid-item:hover .activityBoxesGrid-title {
	opacity: 0;
}

.no-touchevents .localSite .activityBoxesGrid-item:hover .activityBoxesGrid-title {
	z-index: 120;
	opacity: 1;
}

.activityBoxesGrid-overlay {
	background: rgba(0, 0, 0, .1);
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 100;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	border-radius: 3px;
}

.activityBoxesGrid-content {
	position: relative;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0 10px;
}

.activityBoxesGrid-caption {
	font-size: 13px;
	line-height: 1.2;
	margin: 0;
}

.activityBoxesGrid-icon {
	@extend .icon;
	height: 15px;
	margin: 10px auto;
}

.activityBoxesGrid-btn {
	max-width: 100%;
	font-size: 12px;
	padding: 5px 10px;
}

// .activityBoxesGrid {
// 	&.tile-view {
// 		.activityBoxesGrid-item {
// 			flex: none;
// 			height: 156px;
// 			width: 156px;
// 		}
// 	}
// }

@mixin tablet() {
	.activityBoxesGrid {
		&.tile-view {
			.activityBoxesGrid-item {
				flex: none;
				height: 156px;
				width: 156px;
			}
		}

		&:not(.tile-view) {

			.activityBoxesGrid-icon {
				height: 20px;
			}

			.activityBoxesGrid-btn {
				font-size: 14px;
			}

			.activityBoxesGrid-item {
				flex: 1 1 50%;
			}

		}
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.tablet {
	@include tablet();
}

@mixin desktop() {

	.activityBoxesGrid {

		&:not(.tile-view) {
			background: $white;

			.activityBoxesGrid-image.lazyloaded {
				max-height: 225px;
			}

			.activityBoxesGrid-icon {
				height: 30px;
			}

			.activityBoxesGrid-item {
				flex: 1 1 25%;
				height: 200px;

				&.hasMaxWidth {
					max-width: 50%;
					background-color: transparent;
				}
			}
		}
	}

	.activityBoxesGrid-container.isContained .activityBoxesGrid-item {
		flex: 1 1 20%;
		margin: 0 15px 15px 0;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include tablet();
	@include desktop();
}

@media (min-width:1200px) {
	&:not(.preview-container) .activityBoxesGrid-image.lazyloaded {
		max-height: 200px;
	}
}

@media (min-width:1600px) {
	&:not(.preview-container) .activityBoxesGrid-image.lazyloaded {
		max-height: 222px;
	}
}

@media (min-width:1900px) {
	&:not(.preview-container) .activityBoxesGrid-image.lazyloaded {
		max-height: 290px;
	}
}
