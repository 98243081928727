.globalFooter {
	clear: both;
	font-size: 14px;
	line-height: 1.3;
	background: $footerBackground;
	color: white;
	padding: 23px 30px 5px;
	position: relative;
	z-index: 100;

	.globalFooter-copyright {
		font-size: 12px;
		line-height: 1.4;
		padding: 0 1em;
		display: block;

		.globalFooter-spacer {
			padding: 0;


			&:not(.no-content):before {
				content: ', ';
			}
		}
	}

	.globalFooter-copyrightLink {
		@extend .u-linkReset;
		color: white;

		&:hover {
			color: $greyLight;
		}
	}

	.globalFooter-btn {
		font-size: 16px;
		line-height: 36px;
		height: 40px;
		padding: 0;
		margin: 35px auto;
		display: block;
		width: 136px;
	}

	input:not([type=submit]):not([type=radio]):not([type=checkbox]).globalFooter-input {
		flex-grow: 1;
		margin: 0 5px 0 0;
		color: white;
		border: none;
		border: 1px solid #41454e;
		background: #41454e;
		padding: 10px;
	}

	input[type="submit"].globalFooter-submit {
		background: rgb(105, 109, 120);
		color: white;
		margin: 0;
		padding: 2px 8px 3px;
		font-size: 12px;
		min-width: 50px;
	}

	input[type="submit"].globalFooter-submit:hover {
		background: $greyLight;
	}

	.globalFooter-desktop {
		display: none;
	}

	.globalFooter-mobile {
		font-size: 10px;
	}


	hr {
		border: none;
		border-top: 1px solid white;
		opacity: .8;
		margin: 2em 0;
	}
}

.icon-x-twitter-kroc-footer:before {
	content: url(../images/global/x-twitter-admin.svg);
    display: inline-block;
	height: 15px;
    width: 15px;
}

.globalFooter-nav {
	@extend .nav;
	text-align: center;

	li {
		display: inline-block;

		.globalFooter-navLink {
			@extend .globalFooter-copyrightLink;
			display: inline-block;
			padding: 12px;
			font-size: 16px;
			color: white;
			text-decoration: none;

			&:hover {
				color: $greyLight;
			}
		}
	}
}

@mixin tablet() {
	.globalFooter-copyright {
		padding: 0;
	}
}

@mixin desktop() {
	.globalFooter-copyright {
		font-size: 12px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		line-height: 40px;

		>*:not(.globalFooter-spacer) {
			flex: none;
		}

		.globalFooter-spacer {
			flex: 2;
			max-width: 50px;
			padding: 0 5px;

			&:not(.no-content):before {
				content: '|';
			}

			&.no-content {
				flex: 1;
				max-width: 25px;
				padding: 0 0 0 5px;
			}
		}

		.globalFooter-copyrightLink {
			margin-bottom: 25px;
		}

		.globalFooter-btn {
			display: inline-block;
			margin: 0 0 20px 0;
		}

	}

	.globalFooter-desktop {
		display: block;
	}

	.globalFooter-mobile {
		display: none;
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
	@include tablet();
}
