.impactStories-slider {
	.slick-slide,
	.slick-list,
	.slick-track {
		height: auto;
	}
}

.impactStories-slide {
	width:50%;
	padding:0 10px;
	//float:left;
}

.impactStories-link {
	text-decoration:none;
	color:black;
	display:block;
}

.impactStories-image {
	margin: 0 0 1em;
}

.impactStories-link:hover {
	.btn {
		color:black;
	}
}

.impactStories-btn {
	padding: 5px 10px;
	font-size: 15px;
}


@mixin tablet() {
	.impactStories-container {
		position:relative;
	}

	.impactStories-slider {
		position:static;
		.slick-next,
		.slick-prev {
			color: white;
			background:#bfbfbf;
			opacity:1;
			//position:fixed;
			border-radius:0;
			padding:40px 5px;
			height: 120px;
			z-index:10;
		}

		.slick-next:before, 
		.slick-prev:before {
			opacity:1;
		}

		.slick-next {
			right:0;
		}

		.slick-prev {
			left:0;
		}
	}
	.impactStories-slide {
		width: 33%;
	}
	.impactStories-slide h6 {
		padding-right:1em;
	}
	.impactStories-image {
		height:200px;
		overflow:hidden;
	}
	.impactStories-image img {
		object-fit:cover;
		height: 100%;
		width:100%;
		opacity:1;
		transition:opacity .35s;
	}
	.impactStories-image img:hover {
		opacity:.75;
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}