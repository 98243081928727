.ministriesGrid {
	@extend .u-resetList;
	font-size:0;
	text-align: center;
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    .no-objectfit & {
    	align-items: stretch;
    }
}

.ministriesGrid-item {
	@extend .u-inlineBlock;
	font-size:16px;
	flex: 1 1 50%;
	position:relative;
	background: black;
	height: 150px;
	overflow: hidden;
	&.hasMaxWidth {
		max-width: 50%;
		background-color: transparent;
	}
}

.ministriesGrid-image {
	object-fit:cover;
	opacity:0;
	float:left;
	transition:opacity .5s;

	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	min-height: 100%;
}

.ministriesGrid-image.lazyloaded {
	width:100%;
	height:100%;
	//max-height: 95px;
	opacity:.8;
}

.no-objectfit .ministriesGrid-image.lazyloaded {
	height:auto;
	// max-height: 200px !important;
	max-height: none !important;
}

.ministriesGrid-link {
	text-decoration: none;
	display: block;
	color: $font-black;
	height: 100%;
	&:not(.compat-object-fit):before {
		content: " ";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom,rgba(0,0,0,.1) 20%,rgba(0,0,0,.7) 100%);
		z-index: 1;
	}
	&.compat-object-fit {
		background-color:transparent;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: inline-block;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
	}
}

.ministriesGrid-title {
	color:white;
	position: absolute;
	bottom:1em;
	width:100%;
	opacity:1;
	transition:opacity .35s;
	font-weight: 600;
	text-shadow: 0 0 3px rgba(0,0,0,.25);
	padding: 0 10px;
	line-height: 1.2;
	z-index: 2;
	@extend .u-textCenter;
}

.no-touchevents .ministriesGrid-item:hover .ministriesGrid-title {
	opacity:0;
}

.no-touchevents .localSite .ministriesGrid:not(.ministriesGrid-useDescriptions) .ministriesGrid-item:hover .ministriesGrid-title {
	z-index: 120;
	opacity: 1;
}

.ministriesGrid-overlay {
	background:rgba(207,227,232,.95);
	width:100%;
	height:100%;
	position: relative;
	z-index:100;
	position:absolute;
	left:0;
	top:0;
	bottom:0;
	right:0;
}

.localSite .ministriesGrid:not(.ministriesGrid-useDescriptions) .ministriesGrid-overlay {
	background:rgba(0,0,0,.2);
}

.ministriesGrid-content {
	position:relative;
	top:50%;
	transform:translate(0,-50%);
	padding:0 10px;
}

.ministriesGrid-caption {
	font-size:13px;
	line-height: 1.2;
	margin:0;
}

.ministriesGrid-icon {
	@extend .icon;
	height: 15px;
	margin:10px auto;
}

.ministriesGrid-btn {
	max-width:100%;
	font-size:12px;
	padding:5px 10px;
}

.worship-service {
	font-size: 12px;
}

@mixin tablet() {
	.ministriesGrid-image.lazyloaded {
		max-height: 225px;
	}
	.ministriesGrid-icon {
		height:20px;
	}
	.ministriesGrid-btn {
		font-size:14px;
	}
	.ministriesGrid-item {
		flex: 1 1 50%;
		//height: 190px;
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.tablet {
	@include tablet();
}

@mixin desktop() {
	.ministriesGrid-image.lazyloaded {
		//max-height: 238px;
	}
	.ministriesGrid-icon {
		height:30px;
	}
	.ministriesGrid-item {
		flex: 1 1 25%;
		height: 200px;
		&.hasMaxWidth {
			max-width: 50%;
			background-color: transparent;
		}
	}
	.ministriesGrid-container.isContained .ministriesGrid-item {
		flex: 1 1 20%;
		margin: 0 15px 15px 0;
	}
	.worship-service {
		font-size: 15px;
	}
}


@media (min-width:$screen-lg-min) {
	.ministriesGrid-image.lazyloaded {
		//max-height: 290px;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}

// @media (min-width:993px) {
// 	&:not(.preview-container) .ministriesGrid-image.lazyloaded {
// 		max-height: 162px;
// 	}
// }

@media (min-width:1200px) {
	&:not(.preview-container) .ministriesGrid-image.lazyloaded {
		max-height: 200px;
	}
}

@media (min-width:1600px) {
	&:not(.preview-container) .ministriesGrid-image.lazyloaded {
		max-height: 222px;
	}
}

@media (min-width:1900px) {
	&:not(.preview-container) .ministriesGrid-image.lazyloaded {
		max-height: 290px;
	}
}
