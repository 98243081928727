// TRG Color Swatches
$brand: #ef3e42;
$brandLight: #f14955;
$brandLighter: #f47780;
$error: #f2adb5;

$blueDark: #2a313f;
$blue: #29abe2;
$blueLight: #555a65;
$blueLighter: #dfedf0;
$blueLightReally: #a5dcf3;

$greyLighter: #eff1f0;
$greyLight: #afb7bb;
$greyMedium: #989799;
$grey: #5a5d5d;
$greyDark: #313435;
$greyIcon: #4d4d4d;
$greyBg: #f5f5f5;

$adminNav: $blueDark;
$adminSectionMenu: #f2f2f3;
$adminSectionMenuHeader: #e6e6e7;
$adminFooter: $adminSectionMenu;

$font-black: #575757;
$black: #111;
$white: #ffffff;
$offWhite: #f2f2f2;
$green: #22b573;
$greenDark: #1d965c;
$active: #ffffcc;
$red: #ef3e42;
$redDark: #b42832;
$orange: #f8991d;
$gold: #f2c75d;
$goldDark: #c9a54c;

$dn-grey: #717073;
$dn-greyDark: #484848;
$dn-greyLight: #afb7bb;

$sa-white: white;
$sa-black: black;
$sa-grey: $greyMedium;
$sa-greyLight: #dfdce3;
$sa-greyVeryLight: $greyBg;
$sa-red: $red;
$sa-redDark: $redDark;
$sa-orange: $orange;
$sa-gold: $gold;
$sa-cream: #f1ebde;
$sa-green: #94ca5e;
$sa-teal: #20b3a8;
$sa-blueLight: #4daee2;
$sa-navy: #005788;

$ribbon-blue: $sa-navy;
$ribbon-blueDark: #063B5B;

$alertGreen: $sa-green;
$alertBlue: $sa-navy;
$alertYellow: $sa-gold;
$alertRed: $sa-red;

$footerBackground: #343434;

$blueGradient: linear-gradient(135deg, rgba(41, 171, 226, .25), rgba(0, 0, 0, 0));
$greenGradient: linear-gradient(135deg, rgba(34, 181, 115, .25), rgba(0, 0, 0, 0));
$greyGradient: linear-gradient(135deg, rgba(0, 0, 0, .07), rgba(0, 0, 0, 0));

$treePageHover: #f7f7f7;

$defaultAccordionColor: $sa-greyLight;

// Social Media Icons
$twitter: #55acee;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #bd081c;
$linkedin: #0e76a8;
$tiktok: #010101;
$x-twitter: #010101;
$youtube: #cd201f;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #8a3ab9;
$flickr: #ff0084;
$vine: #00bf8f;

// Typography
$proxima-nova: "proxima-nova",
sans-serif;
$trajan-pro: "trajan-pro-3",
sans-serif;
$museo-slab: museo-slab,
serif;

$sans-serif: $proxima-nova;
$serif: $trajan-pro;

$heading: $proxima-nova;
$body: $proxima-nova;

// Shadow
$shadowLight: 0 2px 2px rgba(0, 0, 0, .1);
$shadow: 0 0 1px rgba(0, 0, 0, .11),
0 1px 2px rgba(0, 0, 0, .22);
$shadowTop: 0 0 1px rgba(0, 0, 0, .11),
0 -1px 2px rgba(0, 0, 0, .22);
$shadowRight: 0 0 1px rgba(0, 0, 0, .11),
1px 0px 2px rgba(0, 0, 0, .22);
$shadowTrans: box-shadow .15s cubic-bezier(.4, 0, .2, 1);
$shadowHover: 0 0 3px rgba(0, 0, 0, .13),
0 3px 6px rgba(0, 0, 0, .26);
$shadowXl: 0 0 6px rgba(0, 0, 0, .13),
0 6px 12px rgba(0, 0, 0, .26);

$adminHeaderHeight: 60px;
$adminPageTitleHeight: 55px;
$adminFooterHeight: 34px;

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder($amp) {
    @include optional-at-root($amp + '::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root($amp + ':-moz-placeholder') {
        @content;
    }

    @include optional-at-root($amp + '::-moz-placeholder') {
        @content;
    }

    @include optional-at-root($amp + ':-ms-input-placeholder') {
        @content;
    }
}
