@mixin defaultSection {
	clear: both;
	padding-top: 1em;
	padding-bottom: 1em;
	// background: white;
	position: relative;

	.localSite & {
		overflow: hidden;

		&.localName,
		&.greatestNeed {
			padding: 4em 0;

			@media (max-width:$screen-sm-max) {
				padding: 2em;
			}
		}
	}
}

.section {
	@include defaultSection;
	// 	.heroContainer + &.u-textCenter {
	// 		padding-top: 0em !important;
	// 	}
}

.section-container {
	@extend .container;
}

.section-row {
	@extend .row;
}

.section-row img,
.section-row svg {
	margin: 0 auto;
	display: block;
}

.section-title {
	font-size: 18px;
	margin-bottom: 1em;
}

.section-title br,
.section-subtitle br {
	display: none;
}

.section-title,
.section-subtitle {
	@extend .u-textCenter;
	margin-right: 10px;
	margin-left: 10px;
}

@mixin desktopDefaultSection {
	padding: 2em 0;
}

@mixin desktop() {
	.section {
		@include desktopDefaultSection;
	}

	.section-title {
		margin-bottom: 50px;
		//font-size: 30px;
	}

	.section-title,
	.section-subtitle {
		margin-right: 0px;
		margin-left: 0px;
	}

	.section-title+.section-subtitle {
		margin: -50px 0 50px;
	}

	.section-title br,
	.section-subtitle br {
		display: block;
	}

	.section-row {
		display: flex;
		align-items: center;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
