.mediaBoxHalf-preview,
.mediaBoxFull-preview {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-color: #fff;
	height: 380px !important;
	max-height: 100vh;
	overflow: hidden;
	position: relative;
	//max-width: 1280px;
	width: 100% !important;
	margin: 0 auto;

	iframe,
	video {
		position: absolute;
		top: 50%;
		right: 0;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		z-index: 0;
	}

	&.map {
		iframe {
			top: 0;
			left: 0;
			transform: none;
			height: 100%;
			width: 100%;
		}
	}
}

.mediaBoxHalf-preview {
	//height: 100%;
	position: relative;
	min-height: 100%;

	.no-flexbox & {
		height: 100% !important;
		min-height: 380px;
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 20;
	}

	&.isDraggable {
		&:before {
			display: none;
		}
	}
}

.mediaBoxHalf-container {
	align-items: stretch;
}

.mediaBox-thumbs.row {
	margin: 5px -5px 0;

	&:not(.mediaBox-video):not(.mediaBox-file) {
		max-height: 140px;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 12px;
		}

		&::-webkit-scrollbar-track {
			background-color: #f0f0f0;
			border-left: none;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #ccc;
		}

		&::-webkit-scrollbar-thumb:hover {
			background-color: #aaa;
		}
	}

	>div {
		position: relative;
		float: left;
		width: 23%;
		height: 120px;
		margin: 5px;
		cursor: pointer;
		box-shadow: $shadow;
		background-color: #f8f8f8;
		transition: all .15s;

		&.mediaBox-imgThumb {
			>div {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-size: cover !important;
				background-repeat: no-repeat !important;
				background-position: center !important;
				z-index: 2;
			}

			&:after {
				// content: "\f110";

				display: inline-block;
				font: normal normal normal 14px/1 "Font Awesome 5 Pro";
				font-weight: 900;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				font-size: 2em;

				-webkit-animation: fa-spin 2s infinite linear;
				animation: fa-spin 2s infinite linear;

				position: absolute;
				left: 50%;
				top: 50%;
				margin-top: -0.5em;
				margin-left: -0.5em;
				z-index: 1;
			}
		}

		&.mediaBox-vidThumb,
		&.mediaBox-fileThumb {
			white-space: nowrap;
			line-height: 46px;
			padding: 0 10px;

			>div {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&:hover {
			box-shadow: 0 0 4px 2px $blue;
			border-radius: 1px;
		}

		&.selected {
			box-shadow: 0 0 4px 4px $blue;
			border-radius: 1px;
		}
	}
}

@mixin desktop() {
	.mediaBoxFull-preview {
		height: 670px !important;
	}
}

// @media (min-width:$screen-md-min) {
// 	&:not(.preview-container) {
// 		@include desktop();
// 	}
// }

&.preview-container.desktop {
	@include desktop();
}
