@mixin colors($variable) {
	border-color: $variable;
	color: $variable;

	a {
		color: $variable;
	}

	@if ($variable==$white) {
		background-color: transparent;
	}

	@else if ($variable==transparent) {
		background-color: transparent;
		color: $brand;
		border-color: $brand;

		a {
			color: $brand;
		}
	}

	&:hover {
		background-color: $variable;
		color: white;

		a {
			color: white;
		}

		@if ($variable==$white) {
			color: $blueDark;

			a {
				color: $blueDark;
			}
		}

		@else if ($variable==transparent) {
			background-color: $brand;
			color: $white;
			border-color: $brand;

			a {
				color: $white;
			}
		}

		border-color:$variable;
	}

	&.btn-solid {
		background-color: $variable;
		color: white;

		a {
			color: white;
		}

		@if ($variable==$white or $variable==$greyLighter or $variable==$sa-greyLight or $variable==$sa-greyVeryLight or $variable==$sa-cream) {
			color: $font-black;

			a {
				color: $font-black;
			}
		}
	}

	&.btn-solid:hover {
		color: $variable;
		background-color: white;

		a {
			color: $variable;
		}

		@if ($variable==$greyLighter or $variable==$sa-greyLight or $variable==$sa-greyVeryLight or $variable==$sa-cream) {
			color: white;
			background-color: $brand;

			a {
				color: white;
			}
		}

		@if ($variable==$white) {
			background-color: $brand;
		}
	}

	// +.btn-solid:not(.btn-red) {
	// 	background-color: lighten($variable, 20);
	// 	border-color: lighten($variable, 20);
	// }

	&.btn-inverse {
		background-color: transparent;
		border-color: $variable;
		color: $variable;

		a {
			color: $variable;
		}
	}

	&.btn-inverse:hover {
		.no-touchevents & {
			background-color: $variable;
			color: white;

			a {
				color: white;
			}

			@if ($variable==$white) {
				color: $blueDark;

				a {
					color: $blueDark;
				}
			}
		}
	}
}

.btn {
	padding: 15px 35px;
	border: 2px solid $greyLight;
	border-radius: 5px;
	background: white;
	color: $greyDark;
	font-size: 16px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	text-decoration: none;
	transition: all .35s;
	margin: .25em 0;
	text-align: center;

	a {
		color: $greyDark;
		text-decoration: none;
		transition: all .35s;
	}

	.fa,
	.fab,
	.fas,
	.far {
		margin-right: 10px;
	}

	&:hover {
		.no-touchevents & {
			color: white;
			background: $greyLight;
			border-color: $greyLight;
		}
	}

	&.btn-block {
		display: block;
		clear: both;
		max-width: 100%;
	}

	&.btn-mobile-block {
		&+.btn-mobile-block {
			margin-left: 10px;
		}
	}

	&.btn-sm {
		font-size: 16px !important;
		padding: 10px 20px;
	}

	&.btn-md {
		font-size: 20px;
	}

	&.btn-lg {
		font-size: 22px;
	}

	&.btn-caps {
		text-transform: uppercase;
	}

	&.btn-new {
		background: white;
		color: $greyDark;

		&:hover {
			background: $greyDark;
			color: white;
		}
	}

	&.btn-save {
		background: $vine;
		color: white;
		padding: 5px 40px;
		font-weight: bold;
		border-color: $vine;

		&:hover {
			background: white;
			color: $vine;
		}

		&.inactive {
			opacity: .4;
			cursor: default;
			background: #ccc;
			padding: 5px 10px;

			&:hover {
				background: $vine;
				color: white;
			}
		}
	}

	&.btn-delete {
		background: none;
		color: $brand;
	}

	.stat-Cta & {
		@media (min-width:$screen-lg-min) {
			text-align: left;
			padding-left: 6em;
		}
	}

	.worship-service-container & {
		margin-bottom: 1.5em;
	}
}

// Remove the margin if the button is displayed inline with other elements
.form-group .btn {
	margin: 0;
}

.btn.btn-white {
	@include colors($white);
}

.btn.btn-black {
	@include colors($black);
}

.btn.btn-grey {
	@include colors($sa-grey);
}

.btn.btn-greyLighter {
	@include colors($greyLighter);
}

.btn.btn-greyLight {
	@include colors($sa-greyLight);
}

.btn.btn-greyVeryLight {
	@include colors($sa-greyVeryLight);
}

.btn.btn-red {
	@include colors($sa-red);
}

.btn.btn-orange {
	@include colors($sa-orange);
}

.btn.btn-gold {
	@include colors($sa-gold);
}

.btn.btn-cream {
	@include colors($sa-cream);
}

.btn.btn-green {
	@include colors($sa-green);
}

.btn.btn-teal {
	@include colors($sa-teal);
}

.btn.btn-blueDark {
	@include colors($blueDark);
}

.btn.btn-blue {
	@include colors($sa-blueLight);
}

.btn.btn-blueLight {
	@include colors($sa-blueLight);
}

.btn.btn-navy {
	@include colors($sa-navy);
}

.btn.btn-transparent {
	@include colors(transparent);
}

.btn.btn-translucent {
	background-color: rgba(0, 0, 0, .16);
	border-color: transparent;
	color: white;

	a {
		color: white;
	}

	&:hover {
		background-color: rgba(0, 0, 0, .32);
		border-color: transparent;
		color: white;

		a {
			color: white;
		}
	}

	.u-sa-blackBg & {

		background-color: rgba(255, 255, 255, .27) !important;
		border-color: transparent !important;
		color: white !important;

		&:hover {
			background-color: rgba(255, 255, 255, .13) !important;
			border-color: transparent !important;
			color: white !important;
		}
	}
}

input[type="button"],
.button {
	display: inline-block;
	padding: 10px 15px;
	background: black;
	border: none;
	color: white;
	font-weight: bold;
	cursor: pointer;
	text-decoration: none;
}

@mixin tablet() {
	.btn {
		font-size: 18px;
	}

	// .btn.btn-inverse:hover {
	// 	.no-touchevents & {
	// 		background: $variable;
	// 		color: $variable;
	// 	}
	// }
}

@mixin mobile() {
	&.btn-mobile-block {
		display: block;
		clear: both;
		max-width: 100%;

		&+.btn-mobile-block {
			margin-left: 0;
		}
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
	@include tablet();
}

@media (max-width:$screen-xs-max) {
	&:not(.preview-container) {
		@include mobile();
	}
}

&.preview-container.mobile {
	@include mobile();
}
