.nav {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.navItem {
	@extend .u-inlineBlock;
	display: none;
	margin: 0 10px 0 0 !important;
}

.navItem.isShownOnMobile {
	@extend .u-inlineBlock;
}

.navItem-icon {
	vertical-align: middle;
	position: relative;
	top: -2px;
	margin: 0 2px 0 0;
	transition: color .35s;
}

.navItem-iconLocations {
	font-size: 18px;
	top: -1px;
	position: relative;
}

.navItem-iconDonate {
	font-size: 19px;
}

.navItem-link {
	color: black;
	text-decoration: none;
	font-size: 12px;
	line-height: 20px;
	transition: color .35s;
	border: 1px solid transparent;
	border-radius: 3px;

	&:hover .navItem-icon {
		color: $grey;
	}

	&.navItem-btn {
		color: white;
		border-color: $brand;
		background-color: $brand;
		transition: all .35s;
		padding: 4px 12px;
		font-size: 12px;
		cursor: pointer;

		&.donateHeader-expanded {
			color: white !important;
			border-color: $brand;
			background-color: $brand;
		}

		&:hover {
			color: $brand;
			background-color: transparent;
		}

		&.btn-secondary {
			color: $dn-grey;
			border-color: $dn-grey;
			background-color: transparent;

			&:hover {
				color: white;
				background-color: $dn-grey;
			}
		}
	}
}

.nav.hasScrolled {
	background: white;
}

@mixin desktop() {
	.navItem {
		display: inline-block;
	}

	.navItem-link {
		color: white;
		font-weight: 600;
		padding: 5px 15px;
		border-radius: 5px;
		font-size: 15px;
		border-width: 2px;

		&:not(.navItem-btn) {
			border-color: transparent;

			&:hover {
				color: white;
			}
		}

		&:hover .navItem-icon {
			color: $brand;
		}

		&.navItem-btn {
			padding: 5px 30px !important;
			font-size: 14px;
			font-weight: normal;
		}
	}

	.navItem-iconLocations {
		font-size: 22px;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
