.eventDetails {
    font-weight: bold;
    .header {
        padding: 20px;
        background: #B7B7B7;
        color: white;
        font-weight: normal;
        font-size: 16px;
    }
    .section {
        padding-top: 15px;
        .section-header {
            padding: 0 0 15px;
            margin-bottom: 15px;
            background: none;
            color: $greyLight;
            border-bottom: 1px solid #B7B7B7;
        }
        // .eventMap {
        //     margin-top: 15px;
        //     height: 200px;
        //  }
    }
}

.preview-container.preview-container.desktop.eventPreview .section {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.eventsList-single {
    color:$black;
    text-decoration: none;
    display: block;
    transition:color .35s;
    overflow: hidden;
    font-size: 13px;
    img {
        opacity: 1;
        transition: opacity .35s;
        transform: translate3d(0,0,0);
    }
    .isFeatured & {
        margin: 0 0 .5em 0;
    }
    &:hover {
        color:$greyMedium;
        img {
            opacity: .7;
        }
        .eventsList-month {
            background:$black;
        }
        .eventsList-calendar {
            color: $black;
        }
    }
}

.eventsList-title {
    margin-bottom: 0;
    .eventsList-details & {
        margin-top: 0;
    }
}

h3.eventsList-title {
    font-size: 20px;
}

.eventsList-blueText {
    color:$blue;
}

.eventsList-thumbnailImage {
    float: left;
    width: 200px;
    height: auto;
    margin: 0 1em 0 0;
}

.eventsList-details,
.eventsList-calendar {
    display: inline-block;
    vertical-align: top;
}

.eventsList-details {
    width: 206px;
    width: calc(100% - 64px);
    font-size: 13px;
    line-height: 1.4;
}

.eventsList-calendar {
    display: inline-block;
    border: 1px solid $greyMedium;
    text-align: center;
    color: $greyMedium;
    margin: 0 .25em 1em 0;
    width: 55px;
    transition: color .35s;
}

.eventsList-month {
    background: $greyMedium;
    color: white;
    display: block;
    padding: 0 10px;
    transition:background .35s;
}

.eventsList-day {
    font-weight: 600;
    color: $greyMedium;
    padding: 3px 0;
    display: block;
}

.eventsList .sidebar-header {
    margin-bottom: 1em;
}

.eventsList-single hr {
    margin: 0 0 1em 0 !important;
}

.event-divide {
    display: none;
}

@media only screen and (max-width: 812px) {
    .eventsList-thumbnailImage {
        float: none;
        width: 100%;
    }

    .event-divide {
        display: block;
        margin: 20px 0px 25px 0px;
    }
}
