body {
	&:not(.admin-body) {
		padding-top: 110px;
	}
}

.globalHeader {
	@extend .container-fluid;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 3000;
	height: 110px;
	padding-top: 10px;
	padding-bottom: 15px;
	transition: all .35s;
	transform: translate3d(0, 0, 0);
	background: white;
	font-size: 14px;
	line-height: 1.4;
	box-shadow: $shadowLight;
	border-top: 6px solid $sa-red;

	body.header-unSticking & {
		position: fixed;
		top: -200px;
		transition: all .35s;
	}

	body.header-isSticking & {
		position: fixed;
		top: -200px;
		transition: none;
	}

	body.header-isSticky & {
		position: fixed;
		top: 0;
		transition: all .35s;
	}
}

.globalHeader input:not([type=submit]) {
	font-size: 17px;
}

.globalHeader-logoContainer,
.globalHeader-search,
.globalHeader-navContainer {
	flex: 1;
}

.globalHeader-wrapper {
	text-align: center;
	max-width: 1170px;
	margin: auto;
}

.globalHeader-logoContainer {
	height: 34px;
	margin: 0;
	text-decoration: none;
	text-align: left;
}

.globalHeader-logo {
	height: 34px;
	opacity: 1;
	float: left;
	transition: all .35s;
}

.globalHeader-logoContainer:hover .globalHeader-logo {
	filter: drop-shadow(0 0 0 10px #888);
	//transform:translate(-1px,-1px);
}

.globalHeader-tagline {
	@extend .serif;
	font-size: 16px;
	line-height: 16px;
	display: inline-block;
	width: 112px;
	margin: 1px 0 0 6px;
	color: black;
	.language-es & {
		width: 162px;
	}
}

.globalHeader-logoContainer:hover .globalHeader-tagline {
	color: $brand;
}

.globalHeader-navContainer,
.globalHeader-logoContainer,
.globalHeader-nav,
.globalHeader-menuTrigger {
	display: inline-block;
}

.globalHeader-search {
	padding: 0;
	margin-top: 10px;

	i.globalHeader-searchInput,
	input.globalHeader-searchInput[type=text] {
		float: left;
		border: none;
		background: #F2F2F2;
		color: #484848;
		margin: 0;
		padding: 0;
		line-height: 32px;
		height: 32px;
		-webkit-appearance: none;
		font-size: 16px;

		&.sk-icon-search {
			font-size: 20px;
			width: 32px;
			border-radius: 3px 0 0 3px;
			text-align: center;
			cursor: pointer;
			transition: color .35s;

			&:hover {
				color: $sa-red;
			}
		}

		&[type=text] {
			width: calc(100% - 32px);
			border-radius: 0 3px 3px 0;

			@include placeholder('&') {
				color: $dn-grey;
			}

			&:focus {
				background: #F2F2F2;
			}
		}
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.globalHeader-hours {
	max-width: 400px;
	display: none;
	flex: none;
	color: $dn-grey;
	font-size: 12px;
	line-height: 16px;
	padding-right: 15px;
	text-transform: uppercase;

	p {
		margin: 0px;
	}
}

a.globalHeader-krocSales {
	position: absolute;
	top: 0;
	right: 0;
	padding: 11px 15px;

	text-decoration: none;
	flex: none;
	color: $dn-grey;
	line-height: 32px;
	font-size: 25px;

	i {
		line-height: 32px;
	}

	&:hover {
		color: $sa-red;
	}
}

.globalHeader-nav {
	display: none;
}

.globalHeader-menuTrigger {
	position: absolute;
	left: 0;
	top: 0;
	padding: 20px 15px 15px;
	cursor: pointer;
	z-index: 2001;

	.globalHeader-menuIcon {
		margin: 0;
		position: relative;
		font-size: 14px;
		height: 14px;
		line-height: 14px;
		width: 14px;
		color: $dn-grey;
		display: inline-block;
		transition: border-color .35s, color .35s;
	}

	&:hover {
		.globalHeader-menuIcon {
			color: $sa-red;
		}
	}
}

.globalHeader-menuTrigger:hover,
body.header-isSticky .globalHeader .globalHeader-menuTrigger:hover {
	color: $brand;
}

.globalHeader-siteTitle {
	color: white;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;

	&:hover {
		color: $brand;
	}

	body.header-isSticky .globalHeader & {
		color: black;
	}

	@media (max-width:$screen-sm-max) {
		display: none;
	}
}

@mixin tablet() {

	body {
		&:not(.admin-body) {
			padding-top: 143px;
		}
	}

	.globalHeader {
		height: auto;
		border-top-width: 8px;
		padding-top: 20px;
		padding-bottom: 0px;
	}

	.globalHeader-wrapper {
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.globalHeader-menuTrigger {
		position: relative;
		top: auto;
		left: auto;
		padding: 0;

		.globalHeader-menuIcon {
			top: 6px;
			height: 20px;
			line-height: 20px;
			width: 24px;
			border-top: 2px solid $dn-grey;
			border-bottom: 2px solid $dn-grey;

			&:before {
				content: none;
			}

			&:after {
				content: '';
				border-top: 2px solid $dn-grey;
				position: absolute;
				top: calc(50% - 1px);
				left: 0;
				right: 0;
				transition: border-color .35s;
			}
		}

		&:hover {
			.globalHeader-menuIcon {
				border-color: $sa-red;

				&:after {
					border-color: $sa-red;
				}
			}
		}
	}

	.globalHeader-logoContainer {
		height: 40px;
		padding-right: 15px;

		.globalHeader-logo {
			height: 40px;
			display: inline-block;
		}

		.globalHeader-tagline {
			margin: 4px 0 0 7px;
		}
	}

	.globalHeader-search {
		padding-right: 15px;
		margin: 0;
	}

	.globalHeader-hours {
		display: block;
	}

	.globalHeader-krocSales {
		position: relative;
		top: auto;
		right: auto;
		padding: 0 15px 0 0;
	}

	.globalHeader-navContainer {
		display: inline-block;
		width: auto;
		line-height: 32px;
		transition: all .35s;
		text-align: right;

		.globalHeader-nav {
			margin-right: 5px;
			display: inline-block;
		}
	}

	.globalHeader-logoContainer,
	.globalHeader-navContainer {
		flex: none;
	}

	.globalHeader-logoContainer+.globalHeader-navContainer {
		flex-grow: 1;
	}

	.globalHeader-siteTitle {
		flex: 1;
	}

	.globalHeader-siteTitle {
		padding-left: 15px;
	}
}

@media (min-width:800px) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.tablet {
	@include tablet();
}
