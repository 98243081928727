.volunteerOpportunities {
    text-align: center;

    .volunteerBlurb {
        text-align: left;
        display: inline-block;

        .volunteerBlurb-container {
            display: flex;
            flex-wrap: wrap;

            .volunteerBlurb-section {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0;

                &.volunteerBlurb-equals {
                    padding: 0 20px;
                    font-size: 40px;
                    line-height: 36px;
                }

                img {
                    max-height: 34px;
                    margin-right: 20px;
                }

                &>div p {
                    margin: 0;
                    font-size: 18px;
                    line-height: 18px;

                    &+p {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .volunteerOpportunities-title {
        font-size: 33px !important;
        padding: 0 30px;
        margin-bottom: 28px;
    }

    .volunteerOpportunities-module {
        position: relative;

        .volunteerOpportunities-subtitle {
            line-height: 23px;
            font-size: 23px;
            color: #666666;
            font-weight: 500;
            padding: 0 15px;
        }

        .volunteerOpportunities-map {
            height: 500px !important;
            width: auto !important;
            max-height: 40vh;
        }

        .volunteerOpportunities-datepicker {
            position: relative;
            background: $sa-navy;
            padding: 20px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;

            @mixin datepickerArrow() {
                font-size: 40px;
                color: white;
                flex: none;
                cursor: pointer;

                &.doesnt-exist {
                    opacity: 0;
                    pointer-events: none;
                    cursor: default;
                }
            }

            .datepicker-prev {
                @include datepickerArrow();
            }

            .datepicker-next {
                @include datepickerArrow();
            }

            .datepicker-date {
                flex: auto;

                .datepicker-months {
                    font-size: 18px;
                    line-height: 1em;
                    margin-bottom: 12px;
                    overflow: auto;
                    white-space: nowrap;

                    .datepicker-month {
                        position: relative;
                        padding-bottom: 7px;
                        display: inline-block;
                        text-transform: uppercase;
                        cursor: pointer;

                        &+.datepicker-month {
                            margin-left: 10px;
                        }

                        &.isActive {
                            font-weight: 600;
                            cursor: default;
                        }

                        &:hover,
                        &.isActive {
                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                height: 3px;
                                background: $sa-red;
                            }
                        }
                    }

                }

                .datepicker-week {
                    font-size: 34px;
                    line-height: 1em;

                    sub {
                        bottom: 0;
                    }
                }
            }
        }

        .volunteerOpportunities-locationList {
            @extend .container;
            max-height: calc(60vh - 114px);
            overflow: auto;
            padding-bottom: 100px;
            position: relative;

            .location-item {
                text-align: left;
                font-size: 14px;
                padding: 30px;
                border-bottom: 1px solid rgba(112, 112, 112, .26);
                transition: all .35s;

                .location-item-cont {
                    border-radius: 6px;
                    cursor: pointer;
                    padding: 8px 15px;
                    margin: -15px -15px 15px;
                    transition: all .35s;

                    &:hover {
                        background: #ebebeb;
                    }

                    b,
                    div,
                    span {
                        display: block;
                    }

                    b {
                        font-size: 22px;
                        line-height: 26px;
                        padding-bottom: 6px;

                        img {
                            height: 16px;
                            margin-right: 2px;
                        }
                    }
                }

                .location-item-shifts {
                    line-height: 36px;
                    padding: 0;

                    &.local-loading {
                        &:before {
                            z-index: 480;
                        }

                        &:after {
                            z-index: 481;
                        }
                    }

                    .assignment-day {

                        &:not(:first-child) {
                            padding-top: 7px;
                        }

                        &:not(:last-child) {
                            border-bottom: 1px solid rgba(112, 112, 112, .26);
                            padding-bottom: 5px;
                        }

                        .assignment-times {
                            text-align: right;

                            .assignment-time {
                                span {
                                    float: left;
                                }

                                input[type="checkbox"] {
                                    display: none;

                                    &+label {
                                        display: inline-block;
                                        background: #EBEBEB;
                                        border-radius: 6px;
                                        color: #3E3E3E;
                                        font-size: 16px;
                                        text-decoration: none;
                                        padding: 0 13px;
                                        width: 90px;
                                        text-align: center;
                                        height: 31px;
                                        line-height: 31px;
                                        margin-left: 15px;
                                        cursor: pointer;

                                        &>span {
                                            display: block;

                                            &+span {
                                                display: none;
                                            }
                                        }

                                        .language-es & {
                                            width: 122px;
                                        }

                                        &:hover {
                                            background: #cecece;
                                        }
                                    }

                                    &:checked {
                                        &+label {
                                            background: $sa-red;
                                            color: white;

                                            &>span {
                                                display: none;

                                                &+span {
                                                    display: block;
                                                }
                                            }

                                            &:hover {
                                                background: $sa-redDark;
                                            }
                                        }
                                    }
                                }

                                &:after {
                                    content: '';
                                    display: table;
                                    clear: both;
                                }
                            }
                        }

                        &:after {
                            content: '';
                            display: table;
                            clear: both;
                        }
                    }
                }

                &.isActive {
                    background: #fafafa;
                    border-color: $sa-red;
                    z-index: 1;
                    position: relative;

                    &:before {
                        content: '';
                        height: 1px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        background: $sa-red;
                    }

                    .location-item-shifts {

                        .assignment-day {

                            &:not(:last-child) {
                                border-bottom: 1px solid rgba(255, 255, 255, .26);
                            }

                            .assignment-times {
                                float: none;

                                .assignment-time {

                                    span {
                                        float: left;
                                    }
                                }
                            }
                        }
                    }
                }

                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }
        }

        .volunteerOpportunities-locationFade {
            pointer-events: none;
            height: 100px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, white, rgba(255, 255, 255, 0));
            z-index: 499;
        }
    }

    &.volunteerStats {

        .volunteerStats-container {
            background: url("/templates/symphony/static_resources/images/global/volunteer/background-texture.jpg") no-repeat;
            background-size: cover;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 20px;

            .volunteerStats-item {
                flex: auto;
                padding: 10px 5px;
                max-width: 150px;

                img {
                    display: block;
                    margin: 0 auto 20px;
                    height: 72px;
                }

                span {
                    display: block;

                    &.volunteerStats-statistic {
                        color: black;
                        font-size: 24px;
                        line-height: 1.2em;
                        margin-bottom: 8px;
                    }

                    &.volunteerStats-name {
                        color: $grey;
                        font-size: 15px;
                        line-height: 1.25em;
                    }
                }
            }
        }

        .volunteerStats-message {
            background: $sa-navy;
            color: white;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.2em;
            padding: 20px 15px;
        }
    }
}

.volunteerOpportunities-toolbar {
    display: none;
    z-index: 1000;
    background: $sa-red;
    padding: 18px 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    &.shown {
        display: block;
    }

    &>div {
        display: flex;
        max-width: 1000px;
        margin: 0 auto;
        flex-wrap: wrap;

        &>div {
            color: white;
            flex: 1;
            align-items: center;
            // justify-content: center;
            display: flex;
            flex-direction: column;
            padding-left: 15px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                flex: none;
                display: block;
                width: 100%;
                padding: 15px 0 0;
            }

            img {
                margin: 0 15px 10px;
                height: 30px;
            }

            &>div {
                position: relative;

                &>span {
                    display: block;
                    font-size: 13px;
                    width: 100%;
                }
            }

            .btn.btn-white.btn-solid {
                padding: 15px 30px;
                margin: 0;
                color: $sa-red;
                font-size: 18px;
                font-weight: 500;
                line-height: 18px;
                display: block;

                &:hover {
                    color: white;
                }
            }
        }
    }
}



@mixin tablet() {
    .volunteerOpportunities {

        .volunteerBlurb {

            .volunteerBlurb-container {
                flex-wrap: nowrap;

                .volunteerBlurb-section {

                    &.volunteerBlurb-equals {
                        padding: 0 30px;
                    }

                    img {
                        margin-right: 10px;
                        max-height: 74px;
                    }

                    &>div p {
                        font-size: 30px;
                        line-height: 22px;

                        &+p {
                            font-size: 17px;
                        }
                    }
                }
            }
        }

        .volunteerOpportunities-module {

            .volunteerOpportunities-map {
                margin-top: 40px;
            }

            .volunteerOpportunities-locationList {

                .location-item {

                    .location-item-cont {
                        margin: 0;
                    }

                    .location-item-shifts {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

@media (min-width:$screen-sm-min) {
    &:not(.preview-container) {
        @include tablet();
    }
}

&.preview-container.tablet {
    @include tablet();
}

@mixin desktop() {
    .volunteerOpportunities {

        .volunteerBlurb {

            .volunteerBlurb-container {
                flex-wrap: nowrap;

                .volunteerBlurb-section {

                    &.volunteerBlurb-equals {
                        padding: 0 40px;
                    }

                    img {
                        margin-right: 20px;
                        max-height: 94px;
                    }

                    &>div p {
                        font-size: 40px;
                        line-height: 32px;

                        &+p {
                            font-size: 27px;
                        }
                    }
                }
            }
        }

        .volunteerOpportunities-module {

            .volunteerOpportunities-locationFade-top {
                pointer-events: none;
                height: 125px;
                position: absolute;
                left: 0;
                right: 0;
                background: linear-gradient(180deg, white 25%, rgba(255, 255, 255, 0));
                z-index: 496;
            }

            .volunteerOpportunities-map {
                margin-top: 40px;
            }

            .volunteerOpportunities-datepicker {
                border-radius: 6px;
                padding: 20px 40px;
                margin: -22px auto 0;
                min-height: 114px;
                max-width: 700px;
                z-index: 496;

                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
            }

            .volunteerOpportunities-locationList {
                padding-top: 100px;

                .location-item {

                    &.isActive {
                        top: -1px;
                    }

                    .location-item-cont {
                        margin: 0;
                    }

                    .location-item-shifts {
                        padding-left: 15px;

                        .assignment-day {

                            .assignment-times {
                                float: right;

                                .assignment-time {

                                    span {
                                        float: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.volunteerStats {
            .volunteerStats-container {
                padding: 30px;

                .volunteerStats-item {
                    padding: 30px 5px;
                    max-width: 250px;

                    img {
                        margin: 0 auto 35px;
                        height: 120px;
                    }

                    span {

                        &.volunteerStats-statistic {
                            font-size: 40px;
                            margin-bottom: 13px;
                        }

                        &.volunteerStats-name {
                            font-size: 24px;
                        }
                    }
                }
            }

            .volunteerStats-message {
                font-weight: 600;
                font-size: 30px;
                padding: 30px 15px;
            }
        }
    }

    .volunteerOpportunities-toolbar {

        &>div {

            &>div {
                flex: auto;
                flex-direction: row;

                &:last-child {
                    flex: auto;
                    display: flex;
                    width: auto;
                    padding: 0 0 0 15px;
                }

                img {
                    margin: 0 15px 0 0;
                    height: 65px;
                }

                &>div {

                    &>span {
                        position: absolute;
                        top: 100%;
                    }
                }

                .btn.btn-white.btn-solid {
                    display: inline-block;
                }
            }
        }
    }
}

@media (min-width:$screen-md-min) {
    &:not(.preview-container) {
        @include desktop();
    }
}

&.preview-container.desktop {
    @include desktop();
}
