.gdosController {
	margin-bottom: 15px;
}

.gdosResults {
	font-size: 14px;
	line-height: 1.2;
}

.gdosResult {
	border-bottom: 1px solid $font-black;
	padding-bottom: 10px;

	&:last-child {
		border: none;
	}
}

.gdosResults a {
	text-decoration: none;
}

.gdosResults-category {
	padding: 1em 0 0;
	font-size: 23px;
	
	img {
		height: 20px;
		line-height: 20px;
		float: left;
		margin: 2px 8px 0px 0;
	}
}

.gdosResults-serviceList ul {
	margin: 0;
	padding: 0 0 15px;
	line-height: 1.2;
	list-style-type: none;
	list-style-image: none;
	column-count: 2;
	column-gap: 20px;

	li {
		padding-left: 1em;
		text-indent: -1em;
		position: relative;

		a {
			line-height: 1.6em;
			font-size: 16px;

			&.close {
				float: left;
				height: 20px;
				width: 20px;
				text-decoration: none;

				i {
					display: block;
					line-height: 1.4;
				}
			}
		}
	}

	@media (max-width:$screen-xs-max) {
		column-count: 1;
		column-gap: normal;
	}
}

.gdosDetails {
	transition: max-height .35s, border-bottom-width .35s;
	max-height: 0;
	overflow: hidden;
	background: #f7f7f7;
	background: url(../images/global/shield-en.svg) no-repeat center left;
	background-size: 50px;
	padding-left: 70px;
	line-height: 1.2;
	border-bottom: 0 solid $font-black;
	.language-es & {
		background: url(../images/global/shield-en.svg) no-repeat center left;
	}
}

.gdosDetails.isActive {
	max-height: 100px;
	border-bottom-width: 1px;
}

#map {
	max-height: 75vh;
}

@media (min-width:$screen-md-min) {
	#map {
		max-height: 400px;
	}
}
