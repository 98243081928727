.margin-auto { margin: auto !important; }

.no-margin { margin: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.no-margin-right { margin-right: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-left { margin-left: 0 !important; }

.margin-xs { margin: 5px !important; }
.margin-sm { margin: 10px !important; }
.margin-md { margin: 15px !important; }
.margin-lg { margin: 20px !important; }
.margin-xl { margin: 30px !important; }
.margin-xxl { margin: 40px !important; }

.margin-top-xs { margin-top: 5px !important; }
.margin-top-sm { margin-top: 10px !important; }
.margin-top-md { margin-top: 15px !important; }
.margin-top-lg { margin-top: 20px !important; }
.margin-top-xl { margin-top: 30px !important; }
.margin-top-xxl { margin-top: 40px !important; }

.margin-right-xs { margin-right: 5px !important; }
.margin-right-sm { margin-right: 10px !important; }
.margin-right-md { margin-right: 15px !important; }
.margin-right-lg { margin-right: 20px !important; }
.margin-right-xl { margin-right: 30px !important; }
.margin-right-xxl { margin-right: 40px !important; }

.margin-bottom-xs { margin-bottom: 5px !important; }
.margin-bottom-sm { margin-bottom: 10px !important; }
.margin-bottom-md { margin-bottom: 15px !important; }
.margin-bottom-lg { margin-bottom: 20px !important; }
.margin-bottom-xl { margin-bottom: 30px !important; }
.margin-bottom-xxl { margin-bottom: 40px !important; }

.margin-left-xs { margin-left: 5px !important; }
.margin-left-sm { margin-left: 10px !important; }
.margin-left-md { margin-left: 15px !important; }
.margin-left-lg { margin-left: 20px !important; }
.margin-left-xl { margin-left: 30px !important; }
.margin-left-xxl { margin-left: 40px !important; }