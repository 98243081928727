@mixin subMenu () {
	&.hasChildren {

		>ul.flyoutNav {
			position: absolute;
			top: 0;
			right: 0;
			max-width: 0;
			opacity: 0;
			transition: max-width .35s, opacity .35s;
			pointer-events: none;
			z-index: 2;

			.flyoutNavItem.hasChildren {
				.flyoutNavItem-link {
					padding-right: 35px !important;
				}
			}
		}

		&.viewingChild {
			>ul.flyoutNav {
				max-width: 100%;
				opacity: 1;
				pointer-events: all;
			}
		}

		&:hover {
			&.viewingChild {
				>ul.flyoutNav {
					max-width: 100%;
					opacity: 1;
					pointer-events: all;
				}
			}
		}
	}

	&.flyoutNavItem-back {
		.flyoutNavItem-link {
			padding-right: 17px;
			padding-left: 35px;
			background-color: rgba(151, 202, 235, .5);
			padding-bottom: 14px;

			i {
				right: auto;
				left: 0;
			}

			&:after {
				content: none;
			}
		}
	}

	&.flyoutNavItem-extra {
		.flyoutNavItem-link {
			color: $sa-blueLight !important;
		}
	}
}

.flyoutContainer {
	background: $offWhite;


	.flyoutNav {
		// height: calc(100% - 150px);
		height: 100%;
		width: 100%;
		@extend .nav;

		background: $offWhite;
		padding: 53px 0 30px;
		overflow: auto;

		.flyoutNavItem {

			.flyoutNavItem-link {
				color: #191919;
				color: $dn-greyDark;
				text-decoration: none;
				font-weight: normal;
				font-size: 15px;
				text-transform: uppercase;
				display: block;
				padding: 12px 19px 13px;
				line-height: 18px;
				position: relative;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				transition: all .35s;

				i {
					position: absolute;
					right: 5px;
					top: 0;
					width: 35px;
					text-align: center;
					height: 43px;
					line-height: 43px;
					background: 0 0;
					cursor: pointer;
					font-size: 18px;
					z-index: 1;

					&:hover {
						color: $sa-red;
					}
				}

				&:after {
					content: '';
					height: 1px;
					background: rgba(0,0,0,.05);
					position: absolute;
					left: 17px;
					right: 17px;
					bottom: 0;
				}
			}

			@include subMenu();

			&:last-child {
				.flyoutNavItem-link {
					&:after {
						content: none; // remove bottom border from last one
					}
				}
			}
		}
	}

	.flyoutHeader {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		display: flex;
		line-height: 20px;

		.flyoutClose {
			flex: none;
			color: $dn-grey;
			padding: 15px 18px;
			font-size: 24px;
			cursor: pointer;
			z-index: 5;
	
			&:hover i {
				fill: $sa-red;
			}
		}

		.flyoutHeader-search {
			@extend .globalHeader-search;
			width: auto;
			flex: auto;
			padding-right: 10px;

			i.sk-icon-search.globalHeader-searchInput,
			input.globalHeader-searchInput[type=text] {
				&, &:focus {
					background: rgba(0, 0, 0, .05) !important;
				}
			}
		}

		.flyoutHeader-krocSales {
			@extend .globalHeader-krocSales;
			position: relative !important;
		}
	}
}

@mixin desktop() {
	.globalHeader-menuTrigger {
		display: none;
	}

	.flyoutContainer:not(.desktop-nav) {
		display: none;
	}

	.desktop-nav {
		margin-left: -15px;
		margin-right: -15px;
		margin-top: 20px;
		display: flex;
		justify-content: center;

		&>.flyoutNav>.flyoutNavItem>.flyoutNavItem-link>i {
			display: none !important;
		}


		.flyoutNav {

			.flyoutNavItem {

				.flyoutNavItem-link {

					&:after {
						border-bottom: 2px dashed #484848;
						content: '';
						display: block;
						position: absolute;
						left: 10px;
						right: 10px;
						bottom: 10px;
						opacity: 0;
						transition: opacity .35s;
					}

					i {
						right: 0;
					}

					&:hover {

						&:after {
							opacity: 1;
						}
					}
				}
			}
		}


		&>.flyoutNav {
			height: 55px;
			width: 100%;
			max-width: 1200px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			@extend .nav;

			background: $offWhite;
			overflow: visible;

			&>.flyoutNavItem {
				display: flex;
				align-items: center;

				&>.flyoutNavItem-link {
					color: #191919;
					color: $dn-greyDark;
					text-decoration: none;
					font-weight: normal;
					font-size: 14px;
					text-transform: uppercase;
					text-align: center;
					display: inline-block;
					padding: 17px 10px;
					line-height: 14px;
					position: relative;
					white-space: normal;
					overflow: unset;
					text-overflow: unset;
					width: 100%;
					max-width: 100%;
					transition: all .35s;
				}

				&.hasChildren {
					position: relative;
					height: 130%;

					>.flyoutNav {
						display: none;
						opacity: 0.9;
						position: absolute;
						border-radius: 6px;
						top: 73px;
						left: calc(50% - 110px); // 50% minus half of the width
						max-width: 220px;
						width: 220px;
						transition: max-width .35s, padding .35s;
						z-index: 2;

						.flyoutNavItem {
							@include subMenu();

							.flyoutNavItem-link {
								white-space: normal;
								text-align: left;
								line-height: 18px;
							}

							.flyoutNav {
								padding-left: 0px;
								padding-right: 0px;
								bottom: 0px;
							}

							&.viewingChild>.flyoutNav {
								padding: 30px 18px;
							}
						}
					}

					.flyoutNav {
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						background-color: white;
						height: auto;
						padding: 30px 18px;
					}

					&>.flyoutNav>.flyoutNavItem-back,
					&>.flyoutNav>.flyoutNavItem-overview {
						display: none !important;
					}


					// CHILD FLYOUT
					&:hover {
						.pointer {
							position: absolute;
							top: 63px;
							left: calc(50% - 7px); // 50% minus half of the width
							height: 10px;
							width: 0;
							height: 0;
							border-left: 7px solid transparent;
							border-right: 7px solid transparent;
							border-bottom: 10px solid rgba(255, 255, 255, 0.9);
						}

						>.flyoutNav {
							display: flex;


							.flyoutNavItem {
								.pointer {
									display: none;
								}

								.flyoutNavItem-link {
									padding: 13px 0 10px;
									word-wrap: normal;

									>i.fa-chevron-right {
										background: transparent;
										height: 41px;
										line-height: 42px;
									}

									&:after {
										left: 0;
										right: 0;
										bottom: 8px;
									}
								}

								&.hasChildren {
									.flyoutNavItem-link {
										padding-right: 35px;

										&:after {
											right: 35px;
										}
									}
								}

								&.flyoutNavItem-back {
									margin-left: -18px;

									.flyoutNavItem-link {
										display: inline-block;
										padding-right: 17px !important;
										padding-left: 40px;
										background: none;

										&:after {
											border-bottom: 2px dashed #484848;
											content: '';
											display: block;
											position: absolute;
											left: 34px;
											right: 10px;
											bottom: 8px;
											opacity: 0;
											transition: opacity .35s;
										}

										i {
											left: 5px;
										}
									}
								}
							}
						}

						&.viewingChild {
							>ul.flyoutNav {
								max-width: 100%;
								opacity: 1;
								pointer-events: all;

							}
						}

						&:hover {
							&.viewingChild {
								>ul.flyoutNav {
									max-width: 100%;
									opacity: 1;
									pointer-events: all;
									padding: 30px 18px;
									height: auto;
								}
							}
						}
					}
				}


			}
		}
	}

	&>.flyoutNav>.flyoutNavItem>.flyoutNav {
		pointer-events: all !important;
	}
}

@media (min-width:800px) {
	&:not(.preview-container) {
		@include desktop();
	}
}

@media (max-width:799px) {
	&:not(.preview-container) {

		.flyoutContainer {
			border-top: 6px solid $sa-red;
			box-shadow: $shadowLight;
			position: fixed;
			top: 0;
			left: 0;
			min-height: 400px;
			height: 100%;
			width: 0;
			max-width: 100%;
			padding: 0;
			text-align: left;
			opacity: 0;
			z-index: 3000;
			transition: all .35s;
			transform: translate3d(-400px, 0, 0);

			.flyoutNav {
				.flyoutNavItem {
					.flyoutNavItem-link {
						color: #707070;
						font-weight: bold;
					}
				}
			}


			body.flyoutContainer-isVisible & {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				width: 100%;
			}

			&+.flyoutBackdrop {
				display: block;
				position: fixed;
				background: rgba(0, 0, 0, .4);
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2999;
				pointer-events: none;
				opacity: 0;
				transition: .35s;

				body.flyoutContainer-isVisible & {
					opacity: 1;
					pointer-events: all;
				}
			}
		}
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
	@include desktop();
}

#chat-widget-container {
    z-index: 2999 !important;
}
