.givingGrid {
	position: relative;
	width: 100%;
	margin: 0 0 1em;
}

.givingGrid-header {

	.fa,
	.fab,
	.fas,
	.far {
		border: 1px solid $grey;
		border-radius: 15px;
		padding: 7px 35px;
		margin: 1em 0 2em;
	}
}

.givingGrid-item,
.givingGrid-filler {
	position: relative;
	float: left;
	background-color: $brand;
	background-position: center center;
	background-size: cover;
	color: $white;
	border-radius: 4px;
	box-shadow: 0 0 4px $greyLight;
	transition: box-shadow .35s;
	text-align: center;
	display: block;
	overflow: hidden;
}

.givingGrid-item {
	position: relative;
	cursor: pointer;
	text-decoration: none;

	&:hover:after {
		opacity: .7;
	}

	&:hover {
		color: $white;
		box-shadow: 0 0 5px $greyLight;
	}

	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 10;
		display: none;
		transition: opacity .35s;
		opacity: 1;
	}
}

.givingGrid .mason_filler {
	background-color: $blueDark;

	//background-color:$black;
	// background-image: url(//static.salvationarmy.org/us-east-1/templates/symphony/static_resources/images/global/shield-padding.svg);
	// background-size: contain;
	// background-repeat: no-repeat;
	&:before {
		content: '';
		background-image: url(//static.salvationarmy.org/us-east-1/templates/symphony/static_resources/images/global/shield-blank-padding.svg);
		position: absolute;
		width: 200%;
		height: 200%;
		background-size: 60px;
		left: -50%;
		top: -50%;
		transform: rotate(13deg);
		opacity: .6;
	}
}

.givingGrid-caption {
	text-transform: uppercase;
	padding: 1em 0;
	font-size: 19px;
	line-height: 1.2;
	position: relative;
	z-index: 20;
	height: 100%;
	display: table;
	width: 100%;

	.givingGrid-caption-details {
		display: table-cell;
		vertical-align: bottom;
	}

	img {
		margin: 0 auto 1em;
		display: block;
		max-width: 100px !important;
	}
}

#fillers {
	display: none;
}

// Specific Blocks

.givingGrid-memorial {
	&:after {
		display: block;
		background-color: rgba($blueDark, .8);
	}
}

.givingGrid-amazon {
	background: url(../images/stock/amazonSmile.png) no-repeat center center;
	background-size: cover;
}

:not(.preview-container) .section.giving-leadForm {
	margin-bottom: 2em;
}
