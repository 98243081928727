.filter {
	position: relative;
	&:before {
		content: " ";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&.slide-preview {
		&:before {
			z-index: 2;
		}
		.slide-content {
			z-index: 3;
		}
	}
	&.filter-black-blue {
		&:before,
		&.text-left:before {
			background-image: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,38,85,.6) 100%) !important;
		}
		&.to-top:before {
			background-image: linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,38,85,.6) 100%) !important;
		}
		&.to-bottom:before,
		&.text-center:before {
			background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,38,85,.6) 100%) !important;
		}
		&.to-left:before,
		&.text-right:before {
			background-image: linear-gradient(to left, rgba(0,0,0,0.6) 0%, rgba(0,38,85,.6) 100%) !important;
		}
	}
	&.filter-black-trans {
		&:before,
		&.text-left:before {
			background-image: linear-gradient(to right, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 100%) !important;
		}
		&.to-top:before {
			background-image: linear-gradient(to top, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 100%) !important;
		}
		&.to-bottom:before,
		&.text-center:before {
			background-image: linear-gradient(to bottom, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 100%) !important;
		}
		&.to-left:before,
		&.text-right:before {
			background-image: linear-gradient(to left, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 100%) !important;
		}
	}
	&.filter-white-trans { 
		&:before,
		&.text-left:before {
			background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%) !important;
		}
		&.to-top:before {
			background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%) !important;
		}
		&.to-bottom:before,
		&.text-center:before {
			background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%) !important;
		}
		&.to-left:before,
		&.text-right:before {
			background-image: linear-gradient(to left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%) !important;
		}
		.heroText-container,
		.slide-content,
		.btn.btn-inverse:not(:hover) {
			color: #000 !important;
		}
	}
	&.filter-black:before {
		background-color: rgba(0,0,0,.6) !important;
		background-image: none !important;
	}
	&.filter-blue-trans {
		&:before,
		&.text-left:before {
			background-image: linear-gradient(to right, rgba(0,38,85,.6) 0%, rgba(0,38,85,0) 100%) !important;
		}
		&.to-top:before {
			background-image: linear-gradient(to top, rgba(0,38,85,.6) 0%, rgba(0,38,85,0) 100%) !important;
		}
		&.to-bottom:before,
		&.text-center:before {
			background-image: linear-gradient(to bottom, rgba(0,38,85,.6) 0%, rgba(0,38,85,0) 100%) !important;
		}
		&.to-left:before,
		&.text-right:before {
			background-image: linear-gradient(to left, rgba(0,38,85,.6) 0%, rgba(0,38,85,0) 100%) !important;
		}
	}
	&.filter-none:before {
		background-image: none !important;
	}
}

.givingGrid-item {
	&.filter-white .givingGrid-caption-details {
		transition:color .35s;
	}
	&.filter:before {
		z-index: 20;
		transition:background .35s;
	}
	&.filter-red:before {
		background-color:rgba($brand, .5);
		.no-touchevents & {
			&:hover {
				background-color:rgba($brand, 1);
			}
		}
	}
	.no-touchevents & {
		&.filter-red:hover:before {
			background-color:rgba($brand, .75);
		}
	}
	&.filter-blue:before {
		background-color:rgba($blueDark, .5);
	}
	.no-touchevents & {
		&.filter-blue:hover:before {
			background-color:rgba($blueDark, .75);
		}
	}
	&.filter-blueLight:before {
		background-color:rgba(#28abe2, .5);
	}
	.no-touchevents & {
		&.filter-blueLight:hover:before {
			background-color:rgba(#28abe2, .75);
		}
	}
	&.filter-grey:before {
		background-color:rgba($greyLight, .5);
	}
	.no-touchevents & {
		&.filter-grey:hover:before {
			background-color:rgba($greyLight, .75);
		}
	}
	&.filter-green:before {
		background-color:rgba($greenDark, .5);
	}
	.no-touchevents & {
		&.filter-green:hover:before {
			background-color:rgba($greenDark, .75);
		}
	}
	&.filter-gold:before {
		background-color:rgba(#edb95d, .5);
	}
	.no-touchevents & {
		&.filter-gold:hover:before {
			background-color:rgba(#edb95d, .75);
		}
	}
	&.filter-white:before {
		background-color:rgba(255,255,255, .5);
	}
	.no-touchevents & {
		&.filter-white:hover .givingGrid-caption-details {
			color:black;
		}
		&.filter-white:hover:before {
			background-color:rgba(255,255,255, .75);
		}
	}
	&.filter-none:before {
		display: none;
	}
}