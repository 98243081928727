.testimonialBox {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-color: #fff;
	overflow: hidden;
	position: relative;
	margin: 0 auto;
	text-align: center;
	color: white;
	.localSite & {
		//background-attachment: fixed;
	}
	.testimonialBox-content {
		@media (min-width:$screen-md-min){
			max-width: 960px;
		}
		max-width: 1280px;
		margin: 0 auto;
		text-align: center;
		padding: 60px 40px;
		position: relative;
		&.right {
			text-align: right;
			padding-left: 20%;
		}
		&.left {
			text-align: left;
			padding-right:20%;
		}
		h1 {
			margin-top: 0;
		}
	}
}
