.alerts {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;

    .alert {
        color: white;
        padding: .75em 1em;
        text-align: center;
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        font-size: 14px;
        background-color: $alertRed;

        &.alertGreen {
            background-color: $alertGreen;
        }

        &.alertBlue {
            background-color: $alertBlue;
        }

        &.alertYellow {
            background-color: $alertYellow;
        }

        &.alertRed {
            background-color: $alertRed;
        }

        .close {
            position: absolute;
            top: 15px;
            right: 30px;
            font-size: 18px;
        }

        @media (min-width:$screen-sm-min) {
            padding: 1em;
            height: auto;
        }
    }

    .alert a {
        color: white;
        text-decoration: underline;
        opacity: 1;
        transition: opacity .35s;

        &:hover {
            opacity: .7;
        }
    }
}
