.u-heading {
	font-family: $heading;
}

.u-body {
	font-family: $body;
}

.u-serif {
	font-family: $serif;
}

.u-sansserif {
	font-family: $sans-serif;
}

.u-sansSerif {
	@extend .u-sansserif;
}

.u-inlineBlock {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
}

.u-inlineBlock-important {
	display: inline-block !important;
}

.u-block {
	display: block;
}

.u-floatLeft,
.pull-left {
	float: left;
}

.u-floatRight,
.pull-right {
	float: right;
}

.u-floatRight-md {
	@media (min-width: 992px) {
		float: right;
	}
}

.u-noFloat {
	float: none !important;
}

.u-clear {
	clear: both;
}

.u-clear-after {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.u-textCenter {
	text-align: center;
}

.u-textRight {
	text-align: right;
}

.u-textLeft {
	text-align: left;
}

// wordpress styles
.aligncenter,
div.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 7px;
}

.alignleft {
	float: left;
	margin: 7px 7px 7px 0px;
}

.alignright {
	float: right;
	margin: 7px 0px 7px 7px;
}

.u-uppercase {
	text-transform: uppercase;
}

.u-standardCase {
	text-transform: none !important;
}

.u-capitalizeCase {
	text-transform: capitalize;
}

.u-positionAbsolute {
	position: absolute !important;
}

.u-positionRelative {
	position: relative;
}

.u-positionStatic {
	position: static !important;
}

.u-borderRadius {
	border-radius: 6px;
}

.u-overflowEllipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.u-noWrap {
	white-space: nowrap;
}

.u-fullParentHeight {
	height: 100%;
}

.u-fullMinParentHeight {
	min-height: 100%;
}

.u-fullHeight {
	height: 100%;
	height: 100vh;
}

.u-fullMinHeight {
	min-height: 100%;
	min-height: 100vh;
}

.u-halfHeight {
	height: 400px;
	height: 50vh;
}

.u-autoWidth {
	width: auto !important;
}

.u-noMinWidth {
	min-width: 0 !important;
}

.u-fullWidth {
	width: 100%;
}

.u-maxWidth320 {
	display: block;
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

.u-maxWidth360 {
	display: block;
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;
}

.u-maxWidth480 {
	display: block;
	max-width: 480px;
	margin-left: auto;
	margin-right: auto;
}

.u-flexBox {
	display: flex;
	align-items: center;
}

.u-flexBox-row-center {
	justify-content: center;
}

.u-flexBox-stretch {
	align-items: stretch;
}

.u-flexBox-row-space-btwn {
	justify-content: space-between;
}

.u-flexBox-column {
	flex-direction: column;
}

.u-flexChild {
	flex: 1;

	>*:first-child {
		margin-top: 0;
	}

	>*:last-child {
		margin-bottom: 0;
	}
}

.u-cursorDefault {
	cursor: default !important;
}

.u-cursorPointer {
	cursor: pointer !important;
}

.u-cursorMove {
	cursor: move !important;
}

@media (max-width:$screen-sm-max) {
	.u-flexBox {
		display: block;
	}

	.u-flexChild {
		display: block;
		flex: none;
		width: 100%;
	}

	.u-flexChild {
		&[class*="margin"] {
			margin-right: 0 !important;
			margin-left: 0 !important;
			margin-bottom: 1em;
		}
	}
}

.u-bold {
	font-weight: bold;
}

.u-semibold {
	font-weight: 400;
}

.u-italic {
	font-style: italic;
}

.u-normalWeight {
	font-weight: normal !important;
}

.u-overflowHidden {
	overflow: hidden;
}

.u-linkReset,
.u-linkReset a {
	text-decoration: none;
	transition: color .35s;
}

.u-hasBgImage {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	color: white;
	//background-attachment: fixed;
}

.u-coverBgImage {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
}

.u-parallax-simple {
	background-attachment: fixed;
}

.u-parallax {
	position: relative;

	.parallax-image {
		position: absolute;
		top: -400px;
		right: 0;
		bottom: auto;
		left: 0;
		height: calc(100% + 400px)
	}
}

.u-centerBgImage {
	background-position: center !important;
}

.u-verticalAlign {
	top: 50%;
	transform: translate(0, -50%);
	position: relative;
}

.u-resetList {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.u-showOnHover {
	opacity: 0;
	transition: all .35s;
}

.u-showOnHover.isShown {
	opacity: 1;
}

.u-hidden {
	display: none;
}

.u-hiddenOnly {
	visibility: hidden;
}

.u-really-hidden {
	display: none !important;
}

.u-rotate {
	animation: rotate 1s infinite linear;
}

.u-linethru {
	position: relative;
	text-align: center;

	span {
		background: white;
		padding: 0 10px;
		position: relative;
		z-index: 1;
	}

	&:after {
		content: " ";
		position: absolute;
		z-index: 0;
		left: 20px;
		right: 20px;
		background: $greyLighter;
		height: 1px;
		top: 50%;
	}
}

.u-textXs {
	font-size: 50% !important;
}

.u-textSm {
	font-size: 75% !important;
}

.u-textMd {
	font-size: 100% !important;
}

.u-textLg {
	font-size: 125% !important;
}

.u-textXl {
	font-size: 150% !important;
}

.u-textXxl {
	font-size: 200% !important;
}

.u-textBlack {
	color: black !important;
}

.u-textRed {
	color: $brand;
}

.u-textGreyMedium {
	color: $greyMedium;
}

.u-textGrey {
	color: $grey !important;
}

.u-textGreen {
	color: $green;
}

.u-textGold {
	color: $gold !important;
}

.u-textWhite {
	color: white !important;
}

.u-textBlue {
	color: $sa-blueLight !important;
}

.u-textOrange {
	color: $sa-orange !important;
}

%hasDarkBg {
	color: white;

	input:not([type="submit"]) {
		background-color: white;
	}
}

.u-noBg {
	background: none !important;
}

.u-blueLighterBg {
	background-color: $blueLighter;
}

.u-greyLightBg {
	background-color: $greyLighter;
}

.u-greyDarkBg {
	background-color: $greyDark;
	@extend %hasDarkBg;
}

.u-redLightBg {
	background-color: $brandLighter;
}

.u-blueDarkBg {
	background-color: $blueDark;
	@extend %hasDarkBg;
}

.u-blueLightBg {
	background-color: $blueLight;
	@extend %hasDarkBg;
}

.u-redBg {
	background-color: $brand;
	@extend %hasDarkBg;
}

.u-whiteBg {
	background-color: white !important;
}

.u-adminSectionMenuHeaderBg {
	background-color: $adminSectionMenuHeader !important;
}

@mixin lightContent () {
	color: white;

	hr {
		border-top: 1px solid rgba(255, 255, 255, .27);
	}
}

@mixin mediumContent () {
	color: $font-black;

	hr {
		border-color: #e2e2e2;
	}
}

@mixin darkContent () {
	color: $font-black;

	hr {
		border-color: rgba(0, 0, 0, .1);
	}
}

.u-sa-whiteBg {
	background-color: $sa-white !important;
	@include mediumContent();
}

.u-sa-blackBg {
	background-color: $sa-black !important;
	@include lightContent();
}

.u-sa-greyBg {
	background-color: $sa-grey !important;
	@include lightContent();
}

.u-sa-greyLightBg {
	background-color: $sa-greyLight !important;
	@include darkContent();
}

.u-sa-greyVeryLightBg {
	background-color: $sa-greyVeryLight !important;
	@include darkContent();
}

.u-sa-redBg {
	background-color: $sa-red !important;
	@include lightContent();
}

.u-sa-orangeBg {
	background-color: $sa-orange !important;
	@include lightContent();
}

.u-sa-goldBg {
	background-color: $sa-gold !important;
	@include lightContent();
}

.u-sa-creamBg {
	background-color: $sa-cream !important;
	@include darkContent();
}

.u-sa-greenBg {
	background-color: $sa-green !important;
	@include lightContent();
}

.u-sa-blueLightBg {
	background-color: $sa-blueLight !important;
	@include lightContent();
}

.u-sa-tealBg {
	background-color: $sa-teal !important;
	@include lightContent();
}

.u-sa-navyBg {
	background-color: $sa-navy !important;
	@include lightContent();
}

.u-sa-greyLightBg-trans {
	background-color: rgba(223, 220, 227, .5) !important;
}

.u-redBorder {
	border-color: $brand !important;
}

.u-borderGrey {
	border: 1px solid $sa-grey !important;
}

.u-borderGreyLight {
	border: 1px solid $sa-greyLight !important;
}

.u-borderGreyVeryLight {
	border: 1px solid $sa-greyVeryLight !important;
}

.u-borderOrange {
	border: 1px solid $sa-orange !important;
}

.u-borderGold {
	border: 1px solid $sa-gold !important;
}

.u-borderCream {
	border: 1px solid $sa-cream !important;
}

.u-borderGreen{
	border: 1px solid $sa-green !important;
}

.u-borderRed {
	border: 1px solid $sa-red !important;
}

.u-borderBlueLight {
	border: 1px solid $sa-blueLight !important;
}

.u-borderTeal {
	border: 1px solid $sa-teal !important;
}

.u-borderNavy {
	border: 1px solid $sa-navy !important;
}

.u-borderBlack {
	border: 1px solid $sa-black !important;
}

.u-borderWhite {
	border: 1px solid $sa-white !important;
}

.u-borderBottom {
	border-bottom: 1px solid $greyLight;
}

.u-borderTop {
	border-top: 1px solid $greyLight;
}

.u-borderRight {
	border-right: 1px solid $greyLight;
}

.u-borderLeft {
	border-left: 1px solid $greyLight;
}

.u-accordion {
	max-height: 0;
	overflow: hidden;
	transition: all .35s;

	&:not(.open) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	&.open {
		max-height: 200vh;
		overflow: auto;
	}

	&.opened {
		overflow: visible;
	}
}

.disappearing-status-message {
	font-size: 14px;
	position: fixed;
	height: 60px;
	line-height: 60px;
	background: $green;
	color: white;
	top: -66px;
	right: 0;
	left: 0;
	text-align: center;
	box-shadow: $shadowHover;
	z-index: 99999;
	transition: top .35s;

	body.status-message-visible & {
		top: 0;
	}

	&.isError {
		background-color: $red;
	}

	&.isWarning {
		background-color: $orange;
	}

	a.u-linkReset {
		color: $sa-red;
	}
}

.u-codeWindow {
	font-family: monospace;
	font-size: 13px;
	background: #f0f0f0;
	border: 1px solid #ccc;
	margin-top: 15px;
	padding: 10px;
	max-height: 100px;
	max-width: 100%;
	word-break: break-word;
	overflow-y: auto;
}

.u-contextualNav {
	background: white;
	box-shadow: $shadowXl;
	position: absolute;
	top: 0;
	right: 0;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	transition: opacity .35s;
	z-index: 999;

	&.open {
		opacity: 1;
		pointer-events: all;
	}

	>* {
		display: block;
		padding: 0 10px;
		color: $grey;

		i {
			width: 20px;
			text-align: center;
			margin-right: 5px;
			display: inline-block;
		}
	}

	.u-contextualNav-item {
		display: block;
		cursor: pointer;
		color: $grey;
		line-height: 40px;
		text-transform: none;
		transition: background-color .35s;

		.admin--icon-toggle {
			float: right;
			height: 22px;
			margin: 9px 0 0 10px !important;
		}

		&:hover,
		&.selected {
			color: $grey;
			text-decoration: none;
			background-color: $adminSectionMenu;
		}
	}
}

.u-shadow {
	box-shadow: $shadow;
}

.u-shadowHover {
	box-shadow: $shadowHover;
}

.u-shadowXl {
	box-shadow: $shadowXl;
}

.u-expandingText {
	&.collapsed {
		position: relative;
		max-height: 150px;
		overflow: hidden;
		transition: all .35s;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff 65%);
		}

		&:after {
			content: 'Read More';
			color: $sa-blueLight;
			cursor: pointer;
			display: block;
			text-align: center;
			text-decoration: underline;
			position: absolute;
			line-height: 50px;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&.expanded {
			max-height: 600px;
			overflow: auto;

			&:before {
				content: '';
				opacity: 0;
				pointer-events: none;
			}

			&:after {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}

.u-cards {
	padding: 0 !important;
	margin: 30px auto;
	word-break: break-word;

	.u-card {
		position: relative;

		.u-card-content {
			position: relative;
			border-radius: 3px;
			overflow: hidden;
			padding: 30px;

			.card-text {
				position: relative;
				z-index: 2;
			}

			.free-text-video {
				position: absolute;
				top: 0px;
				bottom: 0px;
				left: 0px;
				right: 0px;
				height: 100%;
				width: 100%;
				inset: 0px;
				background-color: black;
				pointer-events: none;
			}

			&.u-sa-whiteBg:not([style~="background-image"]) {
				border: 2px solid #e2e2e2;
			}

			h1,
			h2 {
				margin: 10px 0;
			}

			a.btn {
				display: block;
				margin: 0;
			}

			*:not(.u-card-commConnIntegration):not(.u-card-commConnIntegration *) {
				position: relative;
			}

			>*:first-child {
				margin-top: 0;
			}

			>*:last-child {
				margin-bottom: 0;
			}

			>*[class^="fb-"],
			>*[class^="fb-"] span {
				display: block;
				margin: auto;
			}

			iframe:not(.card-slide-video),
			.fb_iframe_widget:not(.fb-page) {
				width: 100% !important;
				min-width: 0 !important;
				max-width: none !important;
				margin: 0 !important;
			}

			&.youtube-video {
				font-size: 0;
				padding: 0;

				iframe {
					height: 100% !important;
					min-height: 300px !important;
					max-height: none !important;
				}
			}

			&.kroc-calendar {
				font-size: 0;
				padding: 15px 0 0;
			}

			// expandingText colors
			&.u-sa-whiteBg {
				.u-expandingText.collapsed {

					&:before {
						background: linear-gradient(180deg, rgba(255, 255, 255, 0), white 65%);
					}

					&:after {
						color: $sa-blueLight;
					}
				}
			}

			.u-expandingText.collapsed:after {
				color: white;
			}

			&.u-sa-greyLightBg,
			&.u-sa-greyVeryLightBg,
			&.u-sa-creamBg {
				.u-expandingText.collapsed:after {
					color: black;
				}
			}

			&.u-sa-greyLightBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-greyLight, 0), $sa-greyLight 65%);
				}
			}

			&.u-sa-greyVeryLightBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-greyVeryLight, 0), $sa-greyVeryLight 65%);
				}
			}

			&.u-sa-creamBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-cream, 0), $sa-cream 65%);
				}
			}


			&.u-sa-blackBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-black, 0), $sa-black 65%);
				}
			}

			&.u-sa-greyBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-grey, 0), $sa-grey 65%);
				}
			}

			&.u-sa-redBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-red, 0), $sa-red 65%);
				}
			}

			&.u-sa-orangeBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-orange, 0), $sa-orange 65%);
				}
			}

			&.u-sa-goldBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-gold, 0), $sa-gold 65%);
				}
			}

			&.u-sa-greenBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-green, 0), $sa-green 65%);
				}
			}

			&.u-sa-tealBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-teal, 0), $sa-teal 65%);
				}
			}

			&.u-sa-blueLightBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-blueLight, 0), $sa-blueLight 65%);
				}
			}

			&.u-sa-navyBg {
				.u-expandingText.collapsed:before {
					background: linear-gradient(180deg, rgba($sa-navy, 0), $sa-navy 65%);
				}
			}


			&.hasSingleButton {
				.btn {
					position: absolute;
					bottom: 30px;
					left: 30px;
					right: 30px;

					&+.u-card-btn-space {
						height: 82px;
					}
				}

			}

			.u-card-commConnIntegration {
				.u-card-commconn-btn {
					position: absolute;
					bottom: 30px;
					left: 30px;
					right: 30px;
					z-index: 2;

					&+.u-card-commconn-btn-space {
						height: 117px;
						clear: both;
					}
				}
			}

			.card-slide-image {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				height: 200px;
				margin: -30px -59px 20px;
				width: calc(100% + 59px + 59px);
			}

			.card-top-image {
				object-fit: cover;
				max-height: 120px;
				margin: -30px -30px 30px -30px;
				max-width: calc(100% + 60px) !important;
				width: calc(100% + 60px);
			}

			&>.background-source {
				background-position: center !important;
				background-size: cover;
				position: absolute;
				right: 0;
				top: 0;
				left: 0;
				bottom: 0;
				height: 100%;
				width: 100%;
			}


			&>.background-source:not(.repeat-background-source) {
				background-repeat: no-repeat;
			}


			&>video,
			iframe.background-source {
				background-color: black;
			}
		}



		&.isLinkedToCommConn {
			.u-card-content {
				padding-top: 46px;

				&:before {
					content: '';
					background-color: rgba(255, 255, 255, .27);
					height: 16px;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					z-index: 1;
				}

				// dark top border
				&.u-sa-whiteBg {
					&:before {
						background-color: #e2e2e2 !important;
					}
				}

				// dark top border
				&.u-sa-greyLightBg,
				&.u-sa-greyVeryLightBg,
				&.u-sa-creamBg {
					&:before {
						background-color: rgba(0, 0, 0, .1) !important;
					}
				}
			}
		}

		.u-ribbon+.u-card-content:not(.has-slide-image) {
			padding-top: 70px;
		}
	}

	&.slick-initialized {
		padding-left: 15px !important;
		padding-right: 15px !important;
		margin-top: 23px !important; // offset for the ribbon top

		.slick-list {
			padding-top: 7px !important; // offset for the ribbon top

			.slick-track {
				display: flex;
				align-items: stretch;
			}
		}

		.slick-arrow {
			position: absolute;
			display: block;
			background: rgba(0, 0, 0, .16);
			height: auto;
			top: 7px;
			bottom: 0;
			transform: none;
			border-radius: 0;
			opacity: 1;
			width: 44px;

			&:before {
				font: normal normal normal 20px/1 "Font Awesome 5 Pro" !important;
				font-weight: 900 !important;
				color: white !important;
				transition: opacity .35s !important;
			}

			&:hover {
				background: rgba(0, 0, 0, .32);
			}
		}

		.slick-next {
			left: auto;
			right: 15px !important;
			border-radius: 0 3px 3px 0;

			&:before {
				content: "\f054" !important;
			}
		}

		.slick-prev {
			left: 15px !important;
			border-radius: 3px 0 0 3px;

			&:before {
				content: "\f053" !important;
			}
		}

		.slick-dots {
			bottom: 15px !important;
			width: auto;
			left: 50%;
			transform: translateX(-50%);

			li {
				button {
					&:before {
						color: rgba(0, 0, 0, .36) !important;
					}
				}
			}
		}

		.u-ribbon {
			top: -7px !important;
			left: 59px !important;
		}

		.u-card-content {
			height: 100%;
			padding: 30px 59px; // 44px arrow width + 15px padding

			.u-card-commConnIntegration {
				.u-card-commconn-btn {
					left: 59px;
					right: 59px;
					bottom: 50px;
				}
			}
		}
	}

	&:not(.slick-initialized) {
		display: flex;
		flex-wrap: wrap;
		padding-left: 7px !important;
		padding-right: 7px !important;

		.u-card {
			flex: 1;
			min-width: 33.33%;
			padding: 8px;

			&.local-loading {
				&:before {
					left: 8px;
					top: 8px;
					bottom: 8px;
					right: 8px;
				}
			}

			.u-card-content {
				position: relative;
				height: 100%;
			}
		}

		&+.u-cards {
			margin-top: 0 !important;
		}

		&+.u-cards.slick-initialized {
			margin-top: -7px !important; // offset for the ribbon top
		}

		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}
}

@media only screen and (min-width:1024px) {
	.u-cards {
		.u-card {
			.u-card-content {
				&>.background-source.desktop-slide {
					width: 40% !important;
				}

				&>.background-source.desktop-slide+.card-text {
					padding-left: 40% !important;
				}

				&>.background-source.desktop-slide+.u-card-commConnIntegration {
					margin-left: 40% !important;
					position: relative;

					.u-card-commconn-btn {
						width: 100% !important;
						left: 0px !important;
					}
				}

				&>.background-source:not(.desktop-slide)+.u-card-commConnIntegration {
					&>.u-expandingText.collapsed {
						&:before {
							background: none !important;
						}
					}
				}
			}
		}
	}
}

@media (max-width:1023px) {
	.u-cards {
		.u-card {
			.u-card-content {
				&>.background-source.mobile-slide {
					height: 200px;
					background-position: 100% 70% !important;
				}

				&>.background-source.mobile-slide+.card-text {
					padding-top: 200px !important;
				}

				&>.background-source.mobile-slide+.u-card-commConnIntegration {
					padding-top: 200px !important;
				}


				&>.background-source:not(.mobile-slide)+.u-card-commConnIntegration {
					&>.u-expandingText.collapsed {
						&:before {
							background: none !important;
						}
					}
				}
			}
		}
	}
}

@mixin desktopCards() {
	.u-card {
		.u-card-content {

			.card-slide-image,
			.card-slide-video {
				position: absolute !important;
				top: 0;
				left: 0;
				bottom: 0;
				width: 40% !important;
				height: auto !important;
				margin: 0 !important;

				&+ {
					div {
						padding-left: 30px;
					}

					.u-card-commConnIntegration {
						.u-card-commconn-btn {
							left: calc(40% + 30px) !important;
						}
					}
				}
			}

			iframe,
			video {
				&.card-slide-video {
					height: 100% !important;
					background-color: black;
				}
			}
		}
	}
}

@mixin desktopCardsNoSlide() {
	.u-card {
		&.resized {
			.u-card-content {

				iframe,
				.fb_iframe_widget,
				.fb_iframe_widget>span {
					height: 100% !important;
					min-height: 0 !important;
					max-height: none !important;

					* {
						max-height: none !important;
					}
				}

				.fb_iframe_widget>span,
				.fb_iframe_widget>span>iframe {
					margin: 0 auto !important;
				}
			}
		}
	}
}

@mixin desktopCardSlides() {
	.slick-arrow {
		width: 70px;
	}

	.u-card {
		.u-ribbon {
			left: 85px !important;
		}

		.u-card-content {
			padding-left: 100px; // 70px arrow width + 30px padding
			padding-right: 100px;

			&.has-slide-image {
				padding-left: 40%;
			}

			.u-card-commConnIntegration {
				.u-card-commconn-btn {
					left: 100px;
					right: 100px;
				}
			}
		}
	}
}


.u-ribbon {
	background-color: $ribbon-blue;
	color: white;
	text-align: center;
	position: absolute;
	top: 1px !important;
	left: 23px !important; // 15px from inside of card + 8px for card margin
	right: auto !important;
	bottom: auto !important;
	min-width: 40px;
	z-index: 2998;
	width: 65px;
	min-height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 12px;
	font-weight: 500;
	padding: 7px;
	line-height: 18px;
	word-break: break-word;
	box-shadow: $shadowHover;

	&:before {
		content: ' ';
		position: absolute;
		top: 2.5px;
		left: calc(100% - 5px);
		border: 5px solid transparent;
		border-top-color: $ribbon-blueDark;
		transform: rotate(45deg);
	}

	svg.u-ribbonBottom {
		position: absolute;
		top: 100%;
		left: 0;
		height: 10px;
		width: 100%;
		fill: $ribbon-blue;
	}
}

@mixin desktopRibbon() {
	.u-ribbon {
		width: 90px;
		min-height: 56px;
		font-size: 16px;
		font-weight: normal;
		padding: 10px;
	}
}


@media (min-width:$screen-md-min) {
	@include desktopRibbon();

	.u-cards.live-site,
	.cards-contentContainer {
		@include desktopCards();
	}

	.u-cards:not(.slick-initialized),
	.cards-contentContainer:not(.cards-contentContainer-slides) {
		&.live-site {
			@include desktopCardsNoSlide();
		}
	}

	.u-cards.slick-initialized.live-site,
	.cards-contentContainer-slides {
		@include desktopCardSlides();
	}
}

&.preview-container.desktop {
	@include desktopRibbon();

	.u-cards {
		@include desktopCards();
	}

	.u-cards:not(.slick-initialized) {
		@include desktopCardsNoSlide();
	}

	.u-cards.slick-initialized {
		@include desktopCardSlides();
	}
}

@mixin tabletCards() {
	.u-cards:not(.slick-initialized) {
		display: block;

		.u-card {
			height: auto !important;
			flex: none;
		}
	}

	video,
	iframe.card-slide-video {
		width: 100%;
	}
}

i.u-highlight {
	font-style: normal;
	background-color: #ccff00;
}

.u-money {
	font-size: 90px;
	line-height: 90px;
	position: relative;
	float: left;

	left: 50%;
	transform: translateX(-50%);

	&:before {
		content: '$';
		pointer-events: none;
		font-size: 55%;
		vertical-align: bottom;
		text-align: right !important;
		width: 30px;

		position: absolute;
		left: -30px;
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	// [style="text-align: left;"] &,
	// [style="text-align: left"] &,
	// [style="text-align:left;"] &,
	// [style="text-align:left"] & {
	// 	left: auto !important;
	// 	transform: none !important;

	// 	&:before {
	// 		position: relative !important;
	// 		left: auto !important;
	// 	}
	// }

	// [style="text-align: right;"] &,
	// [style="text-align: right"] &,
	// [style="text-align:right;"] &,
	// [style="text-align:right"] & {
	// 	float: right !important;
	// 	left: auto !important;
	// 	transform: none !important;

	// 	&:before {
	// 		position: relative !important;
	// 		left: auto !important;
	// 	}
	// }

	&+* {
		clear: both;
	}
}

.u-progressBar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background: $adminSectionMenuHeader;
	line-height: 16px;
	font-size: 12px;
	box-shadow: $shadowHover;
	z-index: 100000;

	span {
		position: relative;
		z-index: 1;
		font-weight: bold;
		display: block;
		text-align: center;
	}

	div {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		background: $sa-blueLight;
		transition: max-width 1s;
	}

	.status-message-visible & {
		top: 60px;
	}
}

.u-siteSearch {
	padding: 0;
	margin: 10px auto 0;
	max-width: 614px;
	font-size: 0; // remove whitespace around elements

	.u-siteSearch-submit {
		display: none !important;
	}

	i.u-siteSearch-input,
	input.u-siteSearch-input[type=text] {
		border: none;
		background: none;
		border-bottom: 2px solid #D0D0D0;
		margin: 0;
		padding: 0;
		line-height: 56px;
		height: 56px;
		-webkit-appearance: none;
		font-size: 27px;
		font-weight: normal;

		&.sk-icon-search {
			float: left;
			font-size: 36px;
			width: 42px;
			text-align: center;
			cursor: pointer;
			color: #bfbfbf;
			transition: color .35s, border-color .35s;

			&:hover {
				color: $font-black;
			}
		}

		&[type=text] {
			float: right;
			width: calc(100% - 42px);
			padding-right: 42px;
			color: $font-black;
			text-align: center;

			@include placeholder('&') {
				color: $font-black;
			}

			&:focus {
				background: none;
				border-color: $font-black;

				+i.u-siteSearch-input {
					border-color: $font-black;
				}
			}
		}
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	&.u-inlineBlock {
		width: 200px;
		margin: 0;
		line-height: 23px;
		position: relative;

		i.u-siteSearch-input,
		input.u-siteSearch-input[type=text] {
			font-size: 23px;
			line-height: 43px;
			height: 43px;
			padding: 0;
			float: left;

			&.sk-icon-search {
				line-height: 43px;
				height: 43px;
				border: none;
				float: right;
			}
		}
	}
}

.u-siteSearch-geocode,
.u-siteSearch-geocode-nudge {
	display: none;
	font-size: 16px;
}

.u-siteSearch-geocode {
	padding: 0 10px;
	margin: 0;
	height: 36px;
	line-height: 32px;

	&.shown {
		display: inline-block;
	}
}

.u-siteSearch-geocode-nudge {
	margin-top: 10px;

	&.shown {
		display: block;
	}
}

@mixin tabletSearch() {
	.u-siteSearch {
		&:not(.u-inlineBlock) {

			i.u-siteSearch-input,
			input.u-siteSearch-input[type=text] {
				border: none;
				background: white;
				font-size: 22px;

				&.sk-icon-search {
					border-radius: 3px 0 0 3px;
					font-size: 31px;
				}

				&[type=text] {
					border-radius: 0 3px 3px 0;

					&:focus {
						background: white;
					}
				}
			}
		}
	}
}

@mixin mobileSearch() {
	.u-siteSearch {
		&.u-inlineBlock {
			display: block;
			width: 300px;
			padding: 15px 0 15px 42px;
			margin: auto;
		}
	}

	.u-siteSearch-geocode,
	.u-siteSearch-geocode-nudge {
		margin: 10px 0;

		&.shown {
			margin: 0 auto 15px;
			display: block;
			width: 216px;
		}
	}
}


// mobile styles

@media (max-width:$screen-sm-max) {
	&:not(.preview-container) {
		@include tabletCards();
		@include tabletSearch();
	}
}

&.preview-container.mobile,
&.preview-container.tablet {
	@include tabletCards();
	@include tabletSearch();
}

@media (max-width:$screen-xs-max) {
	&:not(.preview-container) {
		@include mobileSearch();
	}
}

&.preview-container.mobile {
	@include mobileSearch();
}


.u-positionSelector {
	position: relative;
	height: 100px;
	width: 100px;
	border: 2px solid #e6e6e7;

	input {
		display: none !important;

		&+label {
			display: block;
			border: 2px dashed transparent;
			padding: 0;
			text-align: center;
			position: absolute;
			cursor: pointer;
			z-index: 1;

			&:hover {
				background: rgba(41, 171, 226, .2);
				border-color: #29abe2;
				z-index: 2;
			}
		}

		&.positionBackground+label {
			height: 100%;
			width: 100%;
			line-height: 94px;
			z-index: 1 !important;
		}

		&.positionTop+label {
			left: 0;
			top: 0;
			right: 0;
			height: 32px;
			line-height: 30px;
		}

		&.positionRight+label {
			top: 0;
			right: 0;
			bottom: 0;
			width: 32px;
			line-height: 94px;
		}

		&.positionBottom+label {
			left: 0;
			right: 0;
			bottom: 0;
			height: 32px;
			line-height: 30px;
		}

		&.positionLeft+label {
			top: 0;
			left: 0;
			bottom: 0;
			width: 32px;
			line-height: 94px;
		}

		&:checked+label {
			background: rgba(41, 171, 226, .2);
			border-color: #29abe2;
		}
	}
}

.u-module {
	&.isHalfWidth {
		padding-bottom: 0 !important;

		@media (min-width:$screen-sm-min) {
			display: flex;

			.freeTextArea {
				height: 100%;
			}

			.col-sm-6 {

				&.worship-service-container,
				&.mediaBoxHalf-container {
					width: 100% !important;
					height: 100% !important;
				}
			}
		}
	}
}
