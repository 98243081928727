.freeTextArea {
    overflow: hidden;
    min-height:150px;
    
    img.footerImg {
        margin-bottom: -3em;
    }
}

*[class^=admin--freetextarea] {
    img.footerImg {
        margin-bottom: -15px;
    }
}

@mixin desktop() {
    .freeTextArea {
        .row {
            display: flex;
        }

        img.footerImg {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@media (min-width: $screen-md-min) {
    &:not(.preview-container) {
        @include desktop();
    }
}

&.preview-container.desktop {
    @include desktop();
}
