.boardMembers {
	@extend .u-resetList;
	font-size: 0;
	text-align: center;

	padding: 0 15px 15px 0;
}

.boardMembers-item {
	@extend .u-inlineBlock;
	font-size: 16px;
	position: relative;
	padding: 15px 0 0 15px;
	width: 180px;
	height: 270px;

	cursor: default;

	&::before {
		content: " ";
		position: absolute;
		top: 15px;
		right: 0;
		bottom: 0;
		left: 15px;
		background: linear-gradient(to bottom, rgba(0, 0, 0, .1) 20%, rgba(0, 0, 0, .7) 100%);
		z-index: 1;
	}

	.boardMember-content {
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: top !important;
		color: $white;
		overflow: hidden;
		position: absolute;
		top: 15px;
		right: 0;
		bottom: 0;
		left: 15px;
	}

	.boardMember-text {
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		padding: 15px 15px 5px;
		line-height: 16px;
		z-index: 2;

		.boardMember-title {
			font-size: 18px;
			font-weight: bold;
			padding-bottom: 10px;
		}

		.boardMember-subtitle {
			font-size: 15px;
			font-style: italic;
			padding-bottom: 10px;
		}
	}

	.boardMember-quote {
		font-size: 15px;
		font-style: italic;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, .5);
		opacity: 0;
		transition: opacity .35s;
		z-index: 1;

		div {
			height: 80%;
			max-width: 180px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&:hover {
		.boardMember-quote {
			opacity: 1;
		}
	}
}

@mixin tablet() {
	.boardMembers-image.lazyloaded {
		max-height: 238px;
	}

	.boardMembers-icon {
		height: 20px;
	}

	.boardMembers-btn {
		font-size: 14px;
	}

	.boardMembers-item {
		width: 240px;
		height: 360px;
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.tablet,
&.preview-container.desktop {
	@include tablet();
}

@mixin desktop() {
	.boardMembers-icon {
		height: 30px;
	}

	.boardMembers-item {
		width: 300px;
		height: 450px;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}
