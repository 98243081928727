.countdown-container {
    padding: 15px;
    max-width: 650px;
    margin: 0px auto;
    color: $sa-navy;

    .countdown-header {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 25px;
        font-size: 38px;
    }

    .countdown-timer-container {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;

        .circle {
            height: 110px;
            width: 110px;
            margin-right: 35px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            border-width: medium !important;

            

            .time-remaining {
                position: absolute;
                top: 20px;
                font-size: 32px;
            }
            .time-description {
                font-size: 16px;
                position: absolute;
                bottom: 18px
            }
        }
    }

    p {
        margin: 0px;
        text-align: center;
    }

    .countdown-description {
        text-align: center;
    }
}


@mixin mobile() {
    .countdown-container {
        padding: 0px;
        .countdown-timer-container  {
            justify-content: space-around;

            .circle {
                height: 80px;
                width: 80px;
                margin-right: 0px;

                .time-remaining {
                    font-size: 28px;
                    top: 10px;
                }
                .time-description {
                    font-size: 11px;
                    bottom: 8px
                }
            }
        }
    }
}

@media (max-width:$screen-xs-max) {
    &:not(.preview-container) {
        @include mobile();
    }
}

&.preview-container.mobile {
    @include mobile();
}
