.teamMember-container {
	display: flex;
}

.teamMember {
	width: 50%;
	flex: 1 1 50%;
}

.teamMember {
	position: relative;
}

.teamMember-image {
	object-fit:cover;
	width:100%;
	height: 100%;
	vertical-align: bottom;
}

.teamMember-caption {
	color: white;
	position:absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding:25px 15px;
	width: 100%;
	text-align: center;
	opacity:1;
	transition:opacity .35s;
	//display:none;
}

.teamMember-hoverCard {
	display:none;
}

.teamMember-caption p {
	margin: 0;
	line-height: 1.2;
	font-size: 12px !important;
	@extend .u-textMd;
}

@mixin tablet() {
	.teamMember-container {
		align-items:stretch;
		justify-content:center;
		position:relative;
	}

	.teamMember-caption {
		display:block;
	}

	.teamMember {
		flex: 1;
	}

	.teamMember-caption p {
		font-size: 15px !important;
	}

	.teamMember-hoverCard {
		position: absolute;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0071ad+0,1a1a1a+100&0.4+0,0.7+30,0.7+100 */
		background: linear-gradient(to bottom,  rgba(0,113,173,0.4) 0%,rgba(8,87,129,0.7) 30%,rgba(26,26,26,0.7) 100%);
		width: 100%;
		height:100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		color:white;
		font-style:italic;
		padding: 0 2em;
		font-size: 22px;
		line-height:1.3;
		text-align:center;
		opacity:0;
		transition:opacity .35s ease;
		display:block;
	}

	.teamMember:hover .teamMember-hoverCard {
		opacity:1;
	}

	.teamMember:hover .teamMember-caption {
		opacity:0;
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.desktop, &.preview-container.tablet {
	@include tablet();
}