.storiesGrid {
	
}

.storiesGrid-item {
	margin-bottom:25px;
	overflow:hidden;
}

.storiesLink {
	display:table;
	color:white;
	text-decoration: none;
	width:100%;
	text-align: center;
	height:87px;
	position:relative;
	overflow: hidden;
}

.storiesLink:hover {
	color:$greyLighter;
}

.storiesLink:hover:after {
	opacity:1;
}

.no-touchevents .storiesLink:hover .storiesThumbnail {
	transform:scale(1.1);
	width: 105%;
	height: 105%;
}

.no-objectfit .storiesLink:hover .storiesThumbnail {
	transform:scale(1);
	width:100%;
	width:100%;
}

.storiesTitle {
	display:table-cell;
	vertical-align: middle;
	position:relative;
	z-index:30;
	font-weight:600;
	background:rgba(0,0,0,.4);
}

.storiesTitle-icon {
	display:block;
}

.storiesThumbnail {
	position:absolute;
	left:0;
	top:0;
	text-align:center;
	z-index:10;
	object-fit:cover;
	width: 100%;
	height: 100%;
	transition: all .75s;
	transform:translate(0,0);
}

@mixin tablet() {
	.storiesLink {
		height: 175px;
	}
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.desktop, &.preview-container.tablet {
	@include tablet();
}