.sidebar-header {
	background: $greyLight;
	font-size: 24px;
	color: white;
	padding: 10px 15px;
}

.sidebar-section {
	padding: 1em 0;
	+ .sidebar-section {
		padding-top: 0;
	}
}

.sidebar-content {
	font-weight: 600;
}

.sidebar-sectionHeader {
	font-weight: 18px;
	color:$greyLight;
	border-bottom: 1px solid $greyLight;
	margin: 0 0 5px;
	padding: 0 0 5px;
}