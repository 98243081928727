.worship-action {
	line-height: 36px;
	text-decoration: none;
	color: $blue !important;

	&:hover {
		color: $blueDark !important;
	}

	i {
		font-size: 20px;
		line-height: 36px;
		float: left;
		margin-right: 5px;
		color: $grey !important;

		&.circle {
			border: 1px solid $grey;
			border-radius: 50%;
			height: 20px;
			width: 20px;
			line-height: 18px;
			text-align: center;
			font-size: 10px;
			position: relative;
			float: none;
		}
	}
}

.dn-icn-church.worship {
	font-size: 62px;
}
