.pressRelease {
	color:$greyDark;
}

.pressRelease-synopsis {
	margin-bottom: 2em;
}

.pressArchive {
	list-style-type:none;
	margin: 0;
	padding: 0;
}

.pressArchive-subList {
	list-style-type:none;
	padding:0;
}

.pressArchive-single {
	line-height:1.2;
	padding: 1em;
    border-right: 1px solid #ccc;
	list-style-type:none;
	a {
		color:$greyDark;
		text-decoration: none;
		display:block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		&:hover {
			color:$brand;
		}
	}
	time {
		font-size: 12px;
		color: #6B6B6B;
	}
	&.no-border {
		border: none;
	}
}


@media (min-width:$screen-sm-min) {
	.pressRelease {
		margin-bottom: 4em;
	}
	.pressArchive {
		display:flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
	}
	.pressArchive-subList {
		border-left: 3px solid rgb(234,234,234);
		box-sizing: border-box;
		padding-left: 2em;
		flex: 1 1;
		&:first-child {
			padding-left: 0;
			border: none;
		}
		li {
			padding-right: 2em;
		}
	}
}