@import "kroc/sections/ourLeader";

.ourLeader-preview {
	@import "kroc/sections/ourLeader";

	.ourLeader {
		margin: 20px 0;
		border: 1px solid $greyLight;
	}
}

#ourLeader-BackgroundImgs.row {
	margin: 5px -5px 0;
	max-height: 140px;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-track {
		background-color: #f0f0f0;
		border-left: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ccc;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: #aaa;
	}
}

#ourLeader-BackgroundImgs>div.ourLeader-BackgroundImg {
	position: relative;
	float: left;
	width: calc(15% - 1px);
	height: 46px;
	margin: 5px;
	cursor: pointer;
	box-shadow: $shadow;
	background-color: #f8f8f8;
	transition: all .15s;
}

#ourLeader-BackgroundImgs>div.ourLeader-BackgroundImg>div {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	z-index: 2;
}

#ourLeader-BackgroundImgs>div.ourLeader-BackgroundImg:after {
	content: "\f110";

	display: inline-block;
	font: normal normal normal 14px/1 "Font Awesome 5 Pro";
	font-weight: 900;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-size: 2em;

	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;

	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -0.5em;
	margin-left: -0.5em;
	z-index: 1;
}

#ourLeader-BackgroundImgs>div.ourLeader-BackgroundImg>div:hover {
	box-shadow: 0 0 4px 2px $blue;
	border-radius: 1px;
}

#ourLeader-BackgroundImgs>div.ourLeader-BackgroundImg.selected>div {
	box-shadow: 0 0 4px 4px $blue;
	border-radius: 1px;
}


.ourLeader-alignContainer {
	max-width: 140px;
}

.ourLeader-align {
	background: $greyMedium;
	color: #fff;
	height: 36px;
	margin-right: 8px;
	position: relative;
	cursor: pointer;

	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	width: 100%;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.ourLeader-align:last-child {
	margin-right: 0;
}

.ourLeader-align:hover {
	background: $grey;
}

.ourLeader-align.selected {
	background: $brand;
}

.ourLeader-align:before,
.ourLeader-align span,
.ourLeader-align:after {
	position: absolute;
	height: 2px;
	background: #fff;
}

.ourLeader-align:before {
	content: " ";
	width: 43%;
	top: 8px;
}

.ourLeader-align span {
	width: 63%;
	top: 50%;
	margin-top: -1px;
}

.ourLeader-align:after {
	content: " ";
	width: 23%;
	bottom: 8px;
}

.ourLeader-align.left:before,
.ourLeader-align.left span,
.ourLeader-align.left:after {
	left: 5px;
}

.ourLeader-align.center:before,
.ourLeader-align.center span,
.ourLeader-align.center:after {
	left: 50%;
}

.ourLeader-align.center:before {
	margin-left: -21.5%;
}

.ourLeader-align.center span {
	margin-left: -31.5%;
}

.ourLeader-align.center:after {
	margin-left: -11.5%;
}

.ourLeader-align.right:before,
.ourLeader-align.right span,
.ourLeader-align.right:after {
	right: 5px;
}
