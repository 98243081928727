div[data-name="dn-carousel"] {
    display: none !important;
}

.amazingcarousel-container {
    padding: 0 48px;

    .amazingcarousel {

        /* content */

        .amazingcarousel-image {
            position: relative;
            padding: 0px;

            img {
                display: block;
                width: 100%;
                max-width: 100%;
                border: 0;
                margin: 0;
                padding: 0;
                -moz-border-radius: 4px;
                -webkit-border-radius: 4px;
                border-radius: 4px;
            }
        }

        .amazingcarousel-title {
            position: relative;
            font-size: 14px;
            color: #333333;
            margin: 6px;
            text-align: center;
        }

        /* carousel */

        .amazingcarousel-list-container {
            padding: 16px 0;
        }

        /* item */

        .amazingcarousel-item-container {
            text-align: center;
            padding: 4px;
        }

        /* arrows */

        .amazingcarousel-prev,
        .amazingcarousel-next {
            top: 50%;
            margin-top: -18px;
            background-image: none !important;
            // font-family: "Font Awesome 5 Pro";
            font-size: 18px;
            line-height: 48px;
            text-align: center;
            border-radius: 50%;
            transition: background-color .35s;

            &:hover {
                background-color: rgba(0, 0, 0, .1) !important;
            }
        }

        .amazingcarousel-prev {
            left: 0%;
            margin-left: -48px;

            // &:before {
            //     content: "\f053"
            // }
        }

        .amazingcarousel-next {
            right: 0%;
            margin-right: -48px;

            // &:before {
            //     content: "\f054"
            // }
        }

        /* navigation bullets */

        .amazingcarousel-nav {
            position: absolute;
            width: 100%;
            top: 100%;
        }

        .amazingcarousel-bullet-wrapper {
            margin: 4px auto;
        }

    }


    /* Remove Watermark */
    .amazingcarousel-hover-effect+a,
    .amazingcarousel-hover-effect+div {
        display: none !important;
        opacity: 0 !important;
        pointer-events: none;
    }

}
