.gdos-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0px !important;
    overflow-y: hidden;
    margin-right: 0px !important;
    margin-left: 0px !important;

    .gdos-categories {
        width: 100%;
        display: flex;

        // grid-template-columns: repeat(2, 1fr);
        // justify-items: flex-start;
        // align-items: flex-start;
        // grid-auto-flow: column;
        overflow-y: auto;
        max-height: 400px !important;

        .column {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        label.form-control:hover {
            background-color: transparent;
        }

        label {
            border: 0px;
            padding-bottom: 0px !important;

            input {
                margin-right: 5px;
            }

            .gdos-services {
                margin-left: 10px;

                label {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}
