.fundraisingThermometer {
    padding: 15px;
    max-width: 650px;
    margin: 0px auto;
    color: $sa-navy;

    .fundraisingThermometer-goal,
    .fundraisingThermometer-bigGoal {
        font-size: 35px;
        text-align: right;
        color: $sa-navy;
    }

    .fundraisingThermometer-glass {
        width: 100%;
        height: 20px;
        background: #c7c7c7;
        border-radius: 10px;
        float: left;
        overflow: hidden;
    }

    .fundraisingThermometer-progress {
        float: left;
        height: 20px;
        background: $sa-red;
        z-index: 333;
        width: 0;
        transition: width 1s;
    }

    .fundraisingThermometer-goal-stat {
        width: 33%;
        padding: 10px;
        float: left;
        margin: 0;
        color: $sa-navy;
    }

    .fundraisingThermometer-goal-number,
    .fundraisingThermometer-goal-label {
        display: block;
    }

    .fundraisingThermometer-goal-number {
        font-weight: bold;
    }

    &:after {
        content: '';
        clear: both;
        display: table;
    }
}



@mixin mobile() {
    .fundraisingThermometer {

        .fundraisingThermometer-goal,
        .fundraisingThermometer-bigGoal {
            text-align: center;
        }

        .fundraisingThermometer-glass {
            margin-bottom: 10px;
        }

        .fundraisingThermometer-goal-stat {
            clear: both;
            float: none;
            width: auto;
            text-align: center;
        }
    }
}

@media (max-width:$screen-xs-max) {
    &:not(.preview-container) {
        @include mobile();
    }
}

&.preview-container.mobile {
    @include mobile();
}
