/* Accordion styles */
.tab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    overflow: hidden;

    >input[type="checkbox"],
    >input[type="radio"] {
        position: absolute !important;
        opacity: 0;
        z-index: -1;

        +label {
            position: relative;
            display: block;
            padding: 10px 35px 10px 15px;
            background: $defaultAccordionColor;
            font-weight: bold;
            line-height: 22px;
            min-height: 42px;
            cursor: pointer;

            color: $font-black;

            &.u-sa-whiteBg {
                color: $font-black;

                +.tab-content {
                    background: none !important;
                }
            }

            &.u-sa-blackBg {
                color: white;
            }

            &.u-sa-greyBg {
                color: white;
            }

            &.u-sa-greyLightBg {
                color: $font-black;
            }

            &.u-sa-greyVeryLightBg {
                color: $font-black;
            }

            &.u-sa-redBg {
                color: white;
            }

            &.u-sa-orangeBg {
                color: white;
            }

            &.u-sa-goldBg {
                color: $font-black;
            }

            &.u-sa-creamBg {
                color: $font-black;
            }

            &.u-sa-greenBg {
                color: $font-black;
            }

            &.u-sa-tealBg {
                color: white;
            }

            &.u-sa-blueLightBg {
                color: white;
            }

            &.u-sa-navyBg {
                color: white;
            }

            &::after {
                content: "+";
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                font-size: 24px;
                width: 42px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                opacity: 1;
                transition: all 0.35s;
            }

            +.tab-content {
                max-height: 0;
                overflow: hidden;
                background: white;
                color: $font-black;
                padding: 0 15px;
                transition: max-height 0.35s, padding 0.35s;
            }
        }

        &:checked {
            +label {
                &::after {
                    transform: rotate(135deg);
                }
            }

            ~.tab-content {
                max-height: 8000px;
                overflow: auto;
                padding: 15px;
            }
        }

        &[type="radio"] {
            &:checked {
                +label {
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        overflow: hidden;
    }

    &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        overflow: hidden;
        margin-bottom: 0;
    }
}

@mixin desktop() {

    .tab {

        >input[type="checkbox"],
        >input[type="radio"] {

            &:checked {

                ~.tab-content {
                    max-height: 2000px;
                }
            }
        }
    }
}

@media (min-width:$screen-md-min) {
    &:not(.preview-container) {
        @include desktop();
    }
}

&.preview-container.desktop {
    @include desktop();
}
