.rowDonate {
	text-align: center;
}

.rowDonate-btn {
	@extend .btn;
	@extend .btn-red;
	margin: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
    padding-right: 20px;
}

.no-touchevents .rowDonate-btn:hover,
.rowDonate-btn.isActive {
	background: $brand;
	border-color: $brand;
	color: white;
}

.rowDonate-submit {
	@extend .btn;
	@extend .btn-red;
	@extend .btn-solid;
	font-size: 20px;
	padding: 10px 45px;
}

.rowDonate-caption {
	font-size: 14px;
	opacity: 0;
	transition:all .35s;
	margin-left: 15px;
	margin-right: 15px;
}

.rowDonate-caption.isVisible {
	opacity: 1;
}

@mixin desktop() {
	.rowDonate {
		padding: 2em 0;
	}
	.rowDonate-buttons {
		padding: 2em 0;
		display:flex;
		align-items:center;
		justify-content:center;
	}
	.rowDonate-btn {
		margin:0 20px 0 0;
		padding-left:35px;
		padding-right:35px;
	}
	.rowDonate-caption {
		margin: -1em 0 2em;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}