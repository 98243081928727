@mixin openSign() {
    height: 16px;
    line-height: 16px;
    float: none;

    div {
        margin-top: 1px;
        height: 14px;
        line-height: 14px;
        font-size: 11px;
        font-weight: 600;
        color: white;
        background-color: $sa-blueLight;
        width: 40px;
        text-align: center;
        float: left;
        display: none;
    }

    span {
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
        float: left;
    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin openSignDesktop() {
    height: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    right: 0;

    div {
        margin-top: 0;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        width: 56px;
    }

    span {
        display: none;
    }
}

@mixin unfeaturedHoursLine() {
    display: inline-block;
    line-height: 22px;

    >span {
        white-space: nowrap;

        &.operationHoursAdvanced-hoursLineItem:not(:last-child) {
            &:after {
                content: ';';
            }
        }
    }

    &:after {
        content: "|";
        padding: 0 8px;
    }

    &:last-of-type {
        &:after {
            content: none;
        }
    }
}

@mixin operationHours() {
    .operationHoursAdvanced-container {
        background: $greyBg;

        .container {
            text-align: center;
            font-size: 0; // fixes space between inline-block elements
            padding-left: 7px;
            padding-right: 7px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            * {
                text-align: left;
                flex: none;
            }
        }
    }

    .operationHoursAdvanced-featured,
    .operationHoursAdvanced-unfeatured-container {
        width: 100%;
        max-height: 74px;
        border-radius: 3px;
        overflow: hidden;
        margin: 10px 0;
        display: inline-block;
        background: white;
        font-size: 14px;
        vertical-align: top;
        transition: max-height .35s;

        >.operationHoursAdvanced-head {
            background-size: cover;
            background-position: center center;
            height: 100px;

            &:not([style]) {
                background-color: #cccccc;
            }

            display: none;
        }

        >.operationHoursAdvanced-body {
            padding: 12px 20px;
            transition: padding .35s;

            div {
                &.operationHoursAdvanced-hoursLine {
                    margin-bottom: 5px;
                    line-height: 20px;
                }

                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }

            >.operationHoursAdvanced-title {
                font-size: 22px;
                line-height: 22px;
                margin-bottom: 15px;
                position: relative;
                word-wrap: break-word;
                hyphens: auto;

                >.operationHoursAdvanced-expandToggle {
                    position: absolute;
                    display: block;
                    font-size: 14px;
                    top: 0;
                    right: 0;
                    height: 22px;
                    line-height: 22px;
                    width: 22px;
                    text-align: center;
                    transition: all .35s;
                }

                .operationHoursAdvanced-openSign {
                    @include openSign();
                    margin-top: 11px;
                }
            }

            .operationHoursAdvanced-unfeatured {
                margin-left: 0;
                margin-right: 0;
                max-width: none;
            }
        }

        &.is-open {
            >.operationHoursAdvanced-body {
                >.operationHoursAdvanced-title {
                    .operationHoursAdvanced-openSign {
                        div {
                            display: block;
                        }
                    }
                }
            }
        }

        &:not(.is-open) {
            >.operationHoursAdvanced-body {
                >.operationHoursAdvanced-title {
                    .operationHoursAdvanced-openSign {
                        span {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        &.expanded {
            max-height: 1000px;

            >.operationHoursAdvanced-body {
                padding: 15px 20px;

                >.operationHoursAdvanced-title {
                    >.operationHoursAdvanced-expandToggle {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .operationHoursAdvanced-unfeatured-container {
        >.operationHoursAdvanced-body {
            >.operationHoursAdvanced-title {
                margin-bottom: 0;
            }
        }

        &:not(.expanded) {
            max-height: 52px;

            >.operationHoursAdvanced-body {
                padding: 15px 20px;
            }
        }
    }

    .operationHoursAdvanced-unfeatured {
        width: 100%;
        max-width: calc(100% - 30px);
        display: inline-block;
        border-bottom: 1px solid #CECECE;
        padding: 20px 0;
        margin: 0 15px;
        font-size: 14px;
        vertical-align: top;

        >.operationHoursAdvanced-title {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 16px;
            word-wrap: break-word;
            hyphens: auto;

            .operationHoursAdvanced-openSign {
                @include openSign();
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
            }
        }

        >span {
            @include unfeaturedHoursLine();
        }

        &.is-open {
            >.operationHoursAdvanced-title {
                .operationHoursAdvanced-openSign {
                    div {
                        display: block;
                    }
                }
            }
        }

        &:not(.is-open) {
            >.operationHoursAdvanced-title {
                .operationHoursAdvanced-openSign {
                    span {
                        margin-left: 0;
                    }
                }
            }
        }
    }

}


@mixin mobile() {
    @include operationHours();

    .operationHoursAdvanced-featured,
    .operationHoursAdvanced-unfeatured {
        height: auto !important;
    }
}

@media (max-width:$screen-sm-max) {
    &:not(.preview-container) {
        @include mobile();
    }
}

&.preview-container.mobile,
&.preview-container.tablet {
    @include mobile();
}

@mixin desktop() {
    @include operationHours();

    .operationHoursAdvanced-featured {
        width: 324px;
        max-width: calc(33.33% - 16px);
        max-height: none !important;
        margin-left: 8px;
        margin-right: 8px;

        >.operationHoursAdvanced-head {
            display: block;
        }

        >.operationHoursAdvanced-body {
            padding: 30px !important;

            >.operationHoursAdvanced-title {
                font-size: 27px;
                line-height: 28px;
                position: relative;

                >.operationHoursAdvanced-expandToggle {
                    display: none;
                }

                .operationHoursAdvanced-openSign {
                    @include openSignDesktop();
                    margin-top: 4px;
                    margin-bottom: 0px;
                }
            }
        }

        &.is-open {
            >.operationHoursAdvanced-body {
                >.operationHoursAdvanced-title {
                    padding-right: 60px;
                }
            }
        }
    }

    .operationHoursAdvanced-unfeatured {
        width: 324px;
        max-width: calc(33.33% - 30px);
        margin: 0 15px;
    }

    .operationHoursAdvanced-unfeatured-container {
        max-height: none !important;
        background: none;
        margin: 0;
        max-width: none;

        >.operationHoursAdvanced-body {
            padding: 0;
            text-align: center;
            font-size: 0; // fixes space between inline-block elements

            >.operationHoursAdvanced-title {
                padding-top: 25px;
                padding-left: 15px;
                margin-bottom: 15px;
                font-size: 27px;

                >.operationHoursAdvanced-expandToggle {
                    display: none;
                }
            }


            .operationHoursAdvanced-unfeatured {
                max-width: calc(33.33% - 30px);
                margin: 0 15px;

                >.operationHoursAdvanced-title {
                    position: relative;

                    .operationHoursAdvanced-openSign {
                        @include openSignDesktop();
                        margin-left: 0;
                    }
                }

                &.is-open {
                    >.operationHoursAdvanced-title {
                        padding-right: 60px;
                    }
                }
            }
        }
    }
}

@media (min-width:$screen-md-min) {
    &:not(.preview-container) {
        @include desktop();
    }
}

&.preview-container.desktop {
    @include desktop();
}
