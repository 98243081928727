.seeWhatsHappening {
	@extend .u-greyLightBg;
	@extend .u-textCenter;
	@extend .u-flexBox;
	align-items: center;
	justify-content: center;
	padding: 3em 0;
}

.seeWhatsHappening.isFooter {
	display:block;
	background-color:transparent;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center right;
	//@extend .u-fullHeight;
	.landingpage-container:last-of-type & {
		margin-bottom: -75px;
	}
}

.seeWhatsHapenning-content {
	text-align: right;
}

.seeWhatsHappening.isFooter .seeWhatsHappening-content {
	max-width: 300px;
	margin: 0 auto;
}

.seeWhatsHappening-title {
	font-weight: 600;
	font-size:18px;
	line-height: 1.2;
	margin: 0 0 25px 0;
	text-align: center;
	padding: 0 10px;
}

.seeWhatsHappening-title br {
	display:none;

}

.seeWhatsHappening.isFooter .seeWhatsHappening-title {
	//font-size: 24px;
	font-weight: 700;
}

.seeWhatsHappening-form {
	@extend .u-flexBox;
	text-align:center;
}

input:not([type=submit]):not([type=radio]):not([type=checkbox]).seeWhatsHappening-input {
	background-color: white;
	border-right-width: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	margin: 0;
	max-width: 39%;
}

input.seeWhatsHappening-submit {
	max-width:999px;
	min-width:185px;
	//border-top-left-radius: 0;
	//border-bottom-left-radius: 0;
}

.no-objectfit input.seeWhatsHappening-submit {
	min-width:200px;
}

.seeWhatsHappening-map {
	max-width:50%;
	margin: 0 auto;
}

@mixin tablet() {
	.seeWhatsHappening-form {
		text-align:left;
	}
	input:not([type=submit]):not([type=radio]):not([type=checkbox]).seeWhatsHappening-input {
		max-width:100%;
	}
	.seeWhatsHappening-title {
		font-size: 27px;
		padding: 0;
		text-align: right;
	}
	.seeWhatsHappening-title br {
		display:inline;
	}
	.seeWhatsHappening.isFooter {
		background-position:center top;
		height:100%;
		height:100vh;

	}
	.seeWhatsHappening.isFooter .seeWhatsHappening-content {
		max-width: 400px;
		margin-top: 25vh;
	}
	.seeWhatsHappening-map {
		max-width:100%;
		margin: 0;
	}
	input.seeWhatsHappening-submit {
		min-width:200px;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.desktop, &.preview-container.tablet {
	@include tablet();
}