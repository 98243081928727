.ourLeader {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-color: $greyLight;
	color: white;
	overflow: hidden;
	position: relative;

	// font-size: 12px;
	@media (min-width:$screen-sm-min) {

		// font-size: 15px;
		.localSite & {
			background-attachment: fixed;
		}
	}

	.ourLeader-content {
		padding: 30px;
		text-align: center;
		background: rgba(0, 0, 0, .6);

		// height: 380px;
		&.right {
			text-align: right;

			@media (min-width:$screen-sm-min) {
				padding: 30px 40px 30px 60%;
			}

			.mobile & {
				padding: 1em;
			}

			&.blue {
				background: linear-gradient(to left, rgba(0, 38, 85, .6) 5%, rgba(0, 38, 85, 0) 75%);
			}

			&.black {
				background: linear-gradient(to left, rgba(0, 0, 0, .6) 5%, rgba(0, 0, 0, 0) 75%);
			}

			.ourLeader-blurb {
				padding: 0;
			}
		}

		&.left {
			text-align: left;

			@media (min-width:$screen-sm-min) {
				padding: 30px 60% 30px 40px;
			}

			.mobile & {
				padding: 1em;
			}

			&.blue {
				background: linear-gradient(to right, rgba(0, 38, 85, .6) 5%, rgba(0, 38, 85, 0) 75%);
			}

			&.black {
				background: linear-gradient(to right, rgba(0, 0, 0, .6) 5%, rgba(0, 0, 0, 0) 75%);
			}

			.ourLeader-blurb {
				padding: 0;
			}
		}

		&.center {

			@media (min-width:$screen-sm-min) {
				padding-left: 20%;
				padding-right: 20%;
			}

			.mobile & {
				padding: 1em;
			}

			&.blue {
				background: rgba(0, 38, 85, .6);
			}

			&.black {
				background: rgba(0, 0, 0, .6);
			}
		}

		&.none {
			background: none;
		}

		.ourLeader-blurb {
			// font-size: 13px;
			// line-height: 20px;
			padding: 10px 50px;
		}
	}
}
