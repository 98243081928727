

.story-title {
	font-family: $heading;
}

.sidebarBlock {
	background: white;
	border: 2px solid #e2e2e2;
	border-radius: 3px;
}
