.other_ways_to_give .section .icon,
.other_ways_to_give .section {

	.fa,
	.fab,
	.fas,
	.far {
		width: 100px;
		height: 100px;
		font-size: 88px;
	}
}

.threePage-icons {
	font-size: 18px;
	align-items: center;
	justify-content: center;
	display: flex;

	@media (min-width:$screen-md-min) {
		font-size: 24px;
	}

	a {
		text-decoration: none;
		color: $blueDark;
		transition: color .35s;
		max-width: 33% !important;
		flex: 1 1 33%;
		padding: 2em 0;

		&:hover {
			color: $blue;
		}
	}

	i {
		font-size: 52px;
	}
}

.ae-twitter-link:after {
	font: normal normal normal 14px/1 "Font Awesome 5 Brands";
	font-weight: 900;
	content: "\f099";
	padding-left: 4px;
}

.ae-twitter-link {
	text-decoration: none;
}
