.desktop-calendar {
    display: none;
}

.freeTextArea.padding-right-xl.padding-left-xl .mobile-calendar {
    margin-left: -15px;
    margin-right: -15px;
}

.mobile-calendar {
    position: relative;
    display: block;
    background-color: white;
    max-height: 60vh;
    overflow: hidden;
    border-radius: 3px;

    .agenda-header {
        font-size: 16px;
        position: relative;
        height: 50px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $sa-green;

        .agenda-header-title {
            padding: 0 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            flex: 1;
        }

        .rt-arrow,
        .lft-arrow {
            background-color: rgba(0, 0, 0, .1);
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            flex: none;

            &.arrow-hidden {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .no-activities {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #D3D3D3;
        height: 60px;
    }

    .agenda-activities {
        overflow: auto;
        height: calc(60vh - 50px);

        .agenda-activity-container {
            border-bottom: 1px solid #E3E3E3;
            padding-bottom: 10px;
            min-height: 60px;

            &>a,
            &>div {
                display: inline-block;
                width: 100%;
                height: 100%;
                text-decoration: none;

                .class-info-container {
                    padding-top: 15px;
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-bottom: 5px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 14px;
                    align-items: flex-start;
                    color: #4E4E4E;

                    p {
                        font-size: 16px;
                        margin: 0px;
                    }

                    .image-container {
                        .icon-container {
                            position: relative;

                            .image {
                                background-image: url('//static.salvationarmy.org/us-east-1/templates/symphony/static_resources/images/global/activity-image.png');
                                background-size: cover;
                                position: relative;
                                top: 0;
                                left: 0;
                                border-radius: 26px;
                                height: 43px;
                                width: 43px;
                            }
                        }

                        .drop-in {
                            background-color: #D1BA6B;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            font-size: 10px;
                            height: 16px;
                        }

                        .coach {
                            background-color: #9583A4;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            font-size: 10px;
                            height: 16px;
                        }

                        .roster {
                            background-color: #D9836E;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            font-size: 10px;
                            height: 16px;
                        }

                    }

                    .info-container {
                        position: relative;
                        height: 100%;
                    }

                    .time-container {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        height: 100%;

                        p {
                            width: 100%;
                            text-align: right;
                        }
                    }
                }

                .info {
                    padding-left: 16px;
                    padding-right: 16px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 14px;
                    color: #4E4E4E;

                    p {
                        margin: 0px;
                    }
                }
            }
        }
    }
}

@mixin desktop() {
    .desktop-calendar {
        display: block;
    }

    .mobile-calendar {
        display: none;
    }
}

@media (min-width: 551px) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
    @include desktop();
}
