.localNeeds {
	//height: 50vh;
	position: relative;
	text-align: center;
}

.localNeeds-container {
	//height: 100%;
	// position: absolute;
	// top: 0;
	// left: 0;
	width: 100%;
}

.localNeeds-animation {
	//top: 50%;
	//transform:translate(0,-50%);
	position: relative;
}

.localNeeds-image {
	margin: 0 auto;
	display: block;
	height: 30vh;
	width: auto;
}