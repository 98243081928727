body {
	font-size: 16px;
	line-height: 1.6;
	font-family: $body;
	color: $font-black;
	font-weight: 300;
	-webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.serif,
.sansSerif {
	margin: 0 0 .5em;
}

.serif {
	font-family: $serif;
}

h1,
h2,
h3 {
	font-family: $heading;
	font-weight: 100;
	line-height: 1.3;
}

h4,
h5,
h6,
.sansSerif {
	font-family: $heading;
	font-weight: 500;
	line-height: 1.2;
}

h4,
h5,
h6 {
	text-transform: uppercase;
}

h1,
.h1 {
	font-size: 35px;
}

h2,
.h2 {
	font-size: 30px;
}

h3,
.h3 {
	font-size: 24px;
}

h4,
.h4 {
	font-size: 28px;
}

h5,
.h5 {
	font-size: 23px;
}

h6,
.h6 {
	font-size: 18px;
}

blockquote {
	padding: 10px;
	font-style: italic;
	background: $grey;
	color: white;
	font-size: 20px;
	margin-left: 0;
	margin-right: 0;

	p {
		margin: 0;
	}
}

strong {
	font-weight: 600;
}

@mixin tablet() {
	// body {
	// 	font-size: 17px;
	// 	line-height: 1.6;
	// }

	blockquote {
		padding: 20px 40px;
		font-size: 20px;
		line-height: 1.8;
		background: $greyLighter;
		color: black;
	}

	// h1,
	// .h1 {
	// 	font-size: 35px;
	// }

	// h2,
	// .h2 {
	// 	font-size: 30px;
	// }

	// h3,
	// .h3 {
	// 	font-size: 24px;
	// }

	// h4,
	// .h4 {
	// 	font-size: 28px;
	// }

	// h5,
	// .h5 {
	// 	font-size: 23px;
	// }

	// h6,
	// .h6 {
	// 	font-size: 18px;
	// }
}

@media (min-width:$screen-sm-min) {
	&:not(.preview-container) {
		@include tablet();
	}
}

&.preview-container.desktop,
&.preview-container.tablet {
	@include tablet();
}
