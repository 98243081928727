@include placeholder('') {
	color: $greyLight;
}

// Global styling for input forms that need to line up with submit button
// https://philipwalton.github.io/solved-by-flexbox/demos/input-add-ons/

.admin--flexbox-form,
.form-group {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	margin: 0 0 5px 0;

	input,
	textarea,
	>div {
		// > div is here so that immediate child elements of this admin--flexbox-form div still inherit styling
		flex-grow: 1;
	}

	.btn {
		margin: 0 30px 0 0;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

// Default input styling for admin

input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
textarea,
select {
	width: 100%;
	padding: 10px;
	margin: 0 5px 0 0;
	border: 1px solid $greyLight;
	border-radius: 0;
	background-image: none;
	box-shadow: 0 0 0;
	background: none;
	background-color: white;
	transition: all .35s;
	color: $grey;

	&:focus,
	&.changed {
		color: #444;
		background: white;
		border-color: $grey;
		outline: none;
		box-shadow: none;

		&:invalid {
			border-color: $brandLighter;
		}
	}

	&.changed {
		border-color: $blueLight;

		&:invalid {
			border-color: $brandLighter;
		}
	}
}

select {
	padding: 0 0 0 10px;
}


.input-zip {
	max-width: 150px;
}

textarea {
	// additional height spec for textarea inputs
	height: 250px;

	&.short {
		height: 75px;
	}
}

input[type=text] {
	.admin--editor-page-title & {
		margin: 10px 0;
		color: $black;
		font-weight: bold;
		font-size: 13px;
	}

	&:disabled {
		background: rgba(0, 0, 0, .1) !important;
		opacity: .5 !important;
	}
}

input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]).form-control,
input[type=file].form-control,
select.form-control {
	padding-top: 0;
	padding-bottom: 0;
	height: 36px;
	line-height: 36px;
	width: 100%;
}

body.x-webkit {
	input[type="radio"] {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		display: inline-block;
		width: 5px;
		height: 5px;
		border: 3px solid white;
		border-radius: 50%;
		margin: 0 3px 0 0;
		box-sizing: content-box;
		background-color: white;
		box-shadow: 0 0 0 1px #808080;
		position: relative;
		outline: none !important;

		&:checked {
			background-color: $blue;
		}
	}
}

.form-control {
	font-size: 1em;
	font-family: $proxima-nova;
}

input[type="submit"],
.submit {
	@extend .btn;
	border: none;
	outline: none;
	color: white;
	background: $brand;
	border-radius: 4px;
	transition: all .35s;
	padding: 10px 12px;
	margin: 0;
	max-width: 150px;

	&:hover {
		background: #770e16;
	}
}

input[type="number"].form-control {
	padding-right: 0 !important;
}


label.form-control {
	cursor: pointer;
	transition: background-color .35s;

	&:hover {
		background-color: #f0f0f0;
	}
}

label.config-checkbox {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 38px;
	line-height: 38px;
	padding: 0 10px;
	position: relative;
	vertical-align: middle;

	&.u-textSm {
		font-size: 14px !important;
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 4px;
		top: -1px;
		position: relative;
		vertical-align: middle;
	}

	span {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: blue;
			opacity: 0;
			transition: opacity .35s;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		&:checked {
			&~span {
				&:after {
					opacity: .08;
				}
			}
		}
	}
}

.no-objectfit {

	input[type="submit"],
	.submit {
		min-width: 150px;
	}
}

.wufoo ul,
.wufoo li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.wufoo {
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
}

span.slider-filter-wrapper {
    margin: 5px 0 0 15px;
    white-space: nowrap;
    display: inline-block;
}