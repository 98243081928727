.style_guide:not(.preview-container) .flyoutContainer+.section {
	padding-top: 6em;
}

.component {
	margin: 0 0 1em 0;
	border: 1px solid #f7f7f7;
	//padding: 1em;
	padding: 0 1em;
	position: relative;
	transition:background-color .35s;
	> span {
		cursor: pointer;
	}
}

.component:not(.isExpanded):hover {
	//background-color:#f7f7f7;
}

.component-flex {
	//display:flex;
}

.component-title {
	cursor: pointer;
	padding: 0 1em;
	background:#f7f7f7;
	font-weight: bold;
    margin: 0 -18px 18px -17px;
}

.component-title i[class*="caret"] {
	margin-right: 10px;
}

.component-title.isActive i[class*="caret"] {
	transform:rotate(90deg);
}

.component-html,
.component-summary {
	//width: 50%;
	border-radius: 6px;
}

.component-summary {
	padding: 1em
}

.component-summary p {
	margin: 0;
}

.component-wrapper {
	//overflow: hidden;
	//max-height: 0;
	transition:all .35s;
	perspective: 1000px;
}

.component-output {
	opacity: 0;
	transition:opacity .35s;
}

.hasLoaded .component-output {
	opacity: 1;
}

.component-flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
	> div {
		position: absolute;
		left: 0;
		top: 0;
	}
	.component-output {
		z-index: 2;
		transform:rotateY(0deg);
		background: white;
		//min-height: 536px;
	}
	.teamMember-container {
		//min-height: 390px;
	}
	.component-html {
		transform:rotateY(180deg);
	}
}

.component-columns [class*="col-"] {
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: #f7f7f7;
	border: 1px solid #ddd;
	margin-bottom: 1em;
}

.component-columns [class*="col-"] .row [class*="col-"] {
	background:#D6D6D6;
}

.component-flipper,
.component-flipper > div {
	//height: 400px;
	width: 100%;
}

.component-flipper .component-html {
	opacity: 0;
	z-index: -1;
	display:none;
}

.component-wrapper.isSwitched .component-flipper {
	transform: rotateY(180deg);
}

.component-wrapper.isSwitched .component-html {
	opacity: 1;
	z-index: 20;
	display: block;
}

.component-flipper .component-output {
	z-index: 20;
}

.component-wrapper.isSwitched .component-output {
	opacity: 0;
	z-index: -1;
	display: none;
}

.component-wrapper.isExpanded {
	max-height: 9999px;
	padding-top: 1em;
}

.component-output.isExpanded {
	padding-top: 1em;
	padding-bottom: .75em;
}

.component-output.isExpanded > p:first-of-type {
	margin-top: 0;
}

.component-colorBox {
	display:inline-block;
	width: 17%;
	margin: 0 1% 1%;
	padding: 0 0 1em 0;
	text-align: center;
	line-height: 1.2;
	color: black;
	border: 1px solid $black;
	font-size: 14px;
	> div {
		height: 60px;
		width:100%;
		margin-bottom: .5em;
	}
}

code[class*=language-], pre[class*=language-] {
	white-space: pre-wrap;
}

#colors > span {
	display: none;
}