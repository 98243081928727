.thumbnailSlider {
	margin: 0 0 1em 0;
}

.thumbnailSlider-nav {
	display: flex;
	flex-direction: row;

}

.thumbnailSlider-nav .slick-list {
	padding:0 !important;
}

.thumbnailSlider-nav .slick-track {
	//left:0 !important;
}

.thumbnailSlider-nav .slick-slide {
	flex:1 1 auto;
	min-width:0;
	margin: 0 5px 0 0;
	cursor: pointer;
	img {
		opacity: .8;
		transition:all .35s;
	}
	&:hover, &:focus, &.slick-current {
		outline:none;
		img {
			opacity:1;
		}
	}
}