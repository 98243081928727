@font-face {
    font-family: 'symphony-icons';
    src:    url('../../admin/static_resources/fonts/symphony-icons.eot?nxgb9f');
    src:    url('../../admin/static_resources/fonts/symphony-icons.eot?nxgb9f#iefix') format('embedded-opentype'),
        url('../../admin/static_resources/fonts/symphony-icons.woff2?nxgb9f') format('woff2'),
        url('../../admin/static_resources/fonts/symphony-icons.ttf?nxgb9f') format('truetype'),
        url('../../admin/static_resources/fonts/symphony-icons.woff?nxgb9f') format('woff'),
        url('../../admin/static_resources/fonts/symphony-icons.svg?nxgb9f#symphony-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'symphonyIcons';
    src:    url('../fonts/symphony-icons.eot?l2tsl2');
    src:    url('../fonts/symphony-icons.eot?l2tsl2#iefix') format('embedded-opentype'),
        url('../fonts/symphony-icons.woff2?l2tsl2') format('woff2'),
        url('../fonts/symphony-icons.ttf?l2tsl2') format('truetype'),
        url('../fonts/symphony-icons.woff?l2tsl2') format('woff'),
        url('../fonts/symphony-icons.svg?l2tsl2#symphony-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="dn-icn-"], [class*=" dn-icn-"], [class^="dn-"], [class*=" dn-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'symphony-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-rendering: auto;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.localSite {
    [class^="dn-"], 
    [class*=" dn-"],
    [class^="dn-icn-"],
    [class*=" dn-icn-"] {
        font-family: 'symphonyIcons' !important;
    }
}

.dn-clock-two:before {
  content: "\eacd";
}
.dn-website:before {
    content: "\eacb";
}
.dn-phone:before {
    content: "\eacc";
}
.dn-shield2 .path1:before {
    content: "\eab8";
    color: rgb(235, 37, 51);
}
.dn-shield2 .path2:before {
    content: "\eab9";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path3:before {
    content: "\eaba";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path4:before {
    content: "\eabb";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path5:before {
    content: "\eabc";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path6:before {
    content: "\eabd";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path7:before {
    content: "\eabe";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path8:before {
    content: "\eabf";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path9:before {
    content: "\eac0";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path10:before {
    content: "\eac1";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path11:before {
    content: "\eac2";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path12:before {
    content: "\eac3";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path13:before {
    content: "\eac4";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path14:before {
    content: "\eac5";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path15:before {
    content: "\eac6";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path16:before {
    content: "\eac7";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path17:before {
    content: "\eac8";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shield2 .path18:before {
    content: "\eac9";
    margin-left: -0.83984375em;
    color: rgb(235, 37, 51);
}
.dn-shield2 .path19:before {
    content: "\eaca";
    margin-left: -0.83984375em;
    color: rgb(255, 255, 255);
}
.dn-shelter:before {
    content: "\e947";
}
.dn-airline:before {
    content: "\e948";
}
.dn-angel:before {
    content: "\e949";
}
.dn-bar-chart:before {
    content: "\e94a";
}
.dn-board:before {
    content: "\e94b";
}
.dn-clock:before {
    content: "\e94c";
}
.dn-community:before {
    content: "\e94d";
}
.dn-disaster-infographic .path1:before {
    content: "\e94e";
    color: rgb(116, 15, 23);
}
.dn-disaster-infographic .path2:before {
    content: "\e94f";
    margin-left: -0.9970703125em;
    color: rgb(112, 13, 21);
}
.dn-disaster-infographic .path3:before {
    content: "\e950";
    margin-left: -0.9970703125em;
    color: rgb(112, 13, 21);
}
.dn-disaster-infographic .path4:before {
    content: "\e951";
    margin-left: -0.9970703125em;
    color: rgb(141, 4, 15);
}
.dn-disaster-infographic .path5:before {
    content: "\e952";
    margin-left: -0.9970703125em;
    color: rgb(141, 4, 15);
}
.dn-disaster-infographic .path6:before {
    content: "\e953";
    margin-left: -0.9970703125em;
    color: rgb(141, 4, 15);
}
.dn-disaster-infographic .path7:before {
    content: "\e954";
    margin-left: -0.9970703125em;
    color: rgb(112, 13, 21);
}
.dn-disaster-infographic .path8:before {
    content: "\e955";
    margin-left: -0.9970703125em;
    color: rgb(112, 13, 21);
}
.dn-disaster-infographic .path9:before {
    content: "\e956";
    margin-left: -0.9970703125em;
    color: rgb(148, 30, 38);
}
.dn-disaster-infographic .path10:before {
    content: "\e957";
    margin-left: -0.9970703125em;
    color: rgb(112, 13, 21);
}
.dn-disaster-infographic .path11:before {
    content: "\e958";
    margin-left: -0.9970703125em;
    color: rgb(141, 4, 15);
}
.dn-disaster-infographic .path12:before {
    content: "\e959";
    margin-left: -0.9970703125em;
    color: rgb(141, 4, 15);
}
.dn-disaster-infographic .path13:before {
    content: "\e95a";
    margin-left: -0.9970703125em;
    color: rgb(237, 28, 43);
}
.dn-disaster-infographic .path14:before {
    content: "\e95b";
    margin-left: -0.9970703125em;
    color: rgb(148, 30, 38);
}
.dn-disaster-infographic .path15:before {
    content: "\e95c";
    margin-left: -0.9970703125em;
    color: rgb(112, 13, 21);
}
.dn-disaster-infographic .path16:before {
    content: "\e95d";
    margin-left: -0.9970703125em;
    color: rgb(112, 13, 21);
}
.dn-disaster-infographic .path17:before {
    content: "\e95e";
    margin-left: -0.9970703125em;
    color: rgb(145, 5, 17);
}
.dn-disaster-infographic .path18:before {
    content: "\e95f";
    margin-left: -0.9970703125em;
    color: rgb(148, 30, 38);
}
.dn-disaster-infographic .path19:before {
    content: "\e960";
    margin-left: -0.9970703125em;
    color: rgb(148, 30, 38);
}
.dn-disaster-infographic .path20:before {
    content: "\e961";
    margin-left: -0.9970703125em;
    color: rgb(114, 14, 22);
}
.dn-disaster-infographic .path21:before {
    content: "\e962";
    margin-left: -0.9970703125em;
    color: rgb(175, 208, 216);
}
.dn-disaster-infographic .path22:before {
    content: "\e963";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path23:before {
    content: "\e964";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path24:before {
    content: "\e965";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path25:before {
    content: "\e966";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path26:before {
    content: "\e967";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path27:before {
    content: "\e968";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path28:before {
    content: "\e969";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path29:before {
    content: "\e96a";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path30:before {
    content: "\e96b";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-disaster-infographic .path31:before {
    content: "\e96c";
    margin-left: -0.9970703125em;
    color: rgb(42, 49, 63);
}
.dn-donate-goods .path1:before {
    content: "\e96d";
    color: rgb(237, 28, 43);
}
.dn-donate-goods .path2:before {
    content: "\e96e";
    margin-left: -1.279296875em;
    color: rgb(42, 49, 63);
}
.dn-donate-money .path1:before {
    content: "\e96f";
    color: rgb(42, 49, 63);
}
.dn-donate-money .path2:before {
    content: "\e970";
    margin-left: -1.1796875em;
    color: rgb(42, 49, 63);
}
.dn-donate-money .path3:before {
    content: "\e971";
    margin-left: -1.1796875em;
    color: rgb(42, 49, 63);
}
.dn-donate-money .path4:before {
    content: "\e972";
    margin-left: -1.1796875em;
    color: rgb(237, 28, 43);
}
.dn-elderly-community:before {
    content: "\e973";
}
.dn-elderly-infographic .path1:before {
    content: "\e974";
    color: rgb(42, 49, 63);
}
.dn-elderly-infographic .path2:before {
    content: "\e975";
    margin-left: -1.0185546875em;
    color: rgb(42, 49, 63);
}
.dn-elderly-infographic .path3:before {
    content: "\e976";
    margin-left: -1.0185546875em;
    color: rgb(237, 28, 43);
}
.dn-elderly-infographic .path4:before {
    content: "\e977";
    margin-left: -1.0185546875em;
    color: rgb(237, 28, 43);
}
.dn-elderly-infographic .path5:before {
    content: "\e978";
    margin-left: -1.0185546875em;
    color: rgb(42, 49, 63);
}
.dn-elderly-infographic .path6:before {
    content: "\e979";
    margin-left: -1.0185546875em;
    color: rgb(42, 49, 63);
}
.dn-family:before {
    content: "\e97a";
}
.dn-globe2:before {
    content: "\e97b";
}
.dn-greatest-need-infographic .path1:before {
    content: "\e97c";
    color: rgb(207, 227, 232);
}
.dn-greatest-need-infographic .path2:before {
    content: "\e97d";
    margin-left: -1.1943359375em;
    color: rgb(56, 67, 84);
}
.dn-greatest-need-infographic .path3:before {
    content: "\e97e";
    margin-left: -1.1943359375em;
    color: rgb(42, 49, 63);
}
.dn-greatest-need-infographic .path4:before {
    content: "\e97f";
    margin-left: -1.1943359375em;
    color: rgb(237, 28, 43);
}
.dn-greatest-need-infographic .path5:before {
    content: "\e980";
    margin-left: -1.1943359375em;
    color: rgb(186, 202, 206);
}
.dn-greatest-need-infographic .path6:before {
    content: "\e981";
    margin-left: -1.1943359375em;
    color: rgb(178, 21, 32);
}
.dn-greatest-need-infographic .path7:before {
    content: "\e982";
    margin-left: -1.1943359375em;
    color: rgb(119, 14, 22);
}
.dn-greatest-need-infographic .path8:before {
    content: "\e983";
    margin-left: -1.1943359375em;
    color: rgb(255, 201, 9);
}
.dn-greatest-need-infographic .path9:before {
    content: "\e984";
    margin-left: -1.1943359375em;
    color: rgb(255, 201, 9);
}
.dn-greatest-need-infographic .path10:before {
    content: "\e985";
    margin-left: -1.1943359375em;
    color: rgb(255, 255, 255);
}
.dn-greatest-need-infographic .path11:before {
    content: "\e986";
    margin-left: -1.1943359375em;
    color: rgb(255, 255, 255);
}
.dn-greatest-need-infographic .path12:before {
    content: "\e987";
    margin-left: -1.1943359375em;
    color: rgb(255, 201, 9);
}
.dn-greatest-need-infographic .path13:before {
    content: "\e988";
    margin-left: -1.1943359375em;
    color: rgb(255, 201, 9);
}
.dn-greatest-need-infographic .path14:before {
    content: "\e989";
    margin-left: -1.1943359375em;
    color: rgb(255, 201, 9);
}
.dn-greatest-need-infographic .path15:before {
    content: "\e98a";
    margin-left: -1.1943359375em;
    color: rgb(207, 227, 232);
}
.dn-hands-reaching:before {
    content: "\e98b";
}
.dn-hands:before {
    content: "\e98c";
}
.dn-heart-plus:before {
    content: "\e98d";
}
.dn-hearthand-white:before {
    content: "\e98e";
}
.dn-home-3:before {
    content: "\e98f";
}
.dn-homework:before {
    content: "\e990";
}
.dn-keyboard:before {
    content: "\e991";
}
.dn-kid-ball:before {
    content: "\e992";
}
.dn-letter-blocks .path1:before {
    content: "\e993";
    color: rgb(42, 49, 63);
}
.dn-letter-blocks .path2:before {
    content: "\e994";
    margin-left: -1em;
    color: rgb(237, 28, 43);
}
.dn-letter-blocks .path3:before {
    content: "\e995";
    margin-left: -1em;
    color: rgb(175, 208, 216);
}
.dn-letter-blocks .path4:before {
    content: "\e996";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-medical-bag:before {
    content: "\e997";
}
.dn-music-infographic .path1:before {
    content: "\e998";
    color: rgb(237, 28, 43);
}
.dn-music-infographic .path2:before {
    content: "\e999";
    margin-left: -0.9755859375em;
    color: rgb(241, 73, 85);
}
.dn-music-infographic .path3:before {
    content: "\e99a";
    margin-left: -0.9755859375em;
    color: rgb(244, 119, 128);
}
.dn-music-infographic .path4:before {
    content: "\e99b";
    margin-left: -0.9755859375em;
    color: rgb(248, 164, 170);
}
.dn-music-infographic .path5:before {
    content: "\e99c";
    margin-left: -0.9755859375em;
    color: rgb(42, 49, 63);
}
.dn-music-note:before {
    content: "\e99d";
}
.dn-paint:before {
    content: "\e99e";
}
.dn-paintbrush:before {
    content: "\e99f";
}
.dn-planned-giving:before {
    content: "\e9a0";
}
.dn-shelter-bed:before {
    content: "\e9a1";
}
.dn-shelter-infographic .path1:before {
    content: "\e9a2";
    color: rgb(42, 49, 63);
}
.dn-shelter-infographic .path2:before {
    content: "\e9a3";
    margin-left: -0.81640625em;
    color: rgb(237, 28, 43);
}
.dn-shelter-infographic .path3:before {
    content: "\e9a4";
    margin-left: -0.81640625em;
    color: rgb(237, 28, 43);
}
.dn-shelter-infographic .path4:before {
    content: "\e9a5";
    margin-left: -0.81640625em;
    color: rgb(237, 28, 43);
}
.dn-shelter-infographic .path5:before {
    content: "\e9a6";
    margin-left: -0.81640625em;
    color: rgb(237, 28, 43);
}
.dn-shelter-infographic .path6:before {
    content: "\e9a7";
    margin-left: -0.81640625em;
    color: rgb(237, 28, 43);
}
.dn-shelter-infographic .path7:before {
    content: "\e9a8";
    margin-left: -0.81640625em;
    color: rgb(237, 28, 43);
}
.dn-shield:before {
    content: "\e9a9";
}
.dn-snowflake:before {
    content: "\e9aa";
}
.dn-spiritual-care-infographic .path1:before {
    content: "\e9ab";
    color: rgb(175, 208, 216);
}
.dn-spiritual-care-infographic .path2:before {
    content: "\e9ac";
    margin-left: -0.8662109375em;
    color: rgb(42, 49, 63);
}
.dn-spiritual-care-infographic .path3:before {
    content: "\e9ad";
    margin-left: -0.8662109375em;
    color: rgb(42, 49, 63);
}
.dn-spiritual-care-infographic .path4:before {
    content: "\e9ae";
    margin-left: -0.8662109375em;
    color: rgb(42, 49, 63);
}
.dn-spiritual-care-infographic .path5:before {
    content: "\e9af";
    margin-left: -0.8662109375em;
    color: rgb(42, 49, 63);
}
.dn-spiritual-care:before {
    content: "\e9b0";
}
.dn-stocking .path1:before {
    content: "\e9b1";
    color: rgb(237, 28, 43);
}
.dn-stocking .path2:before {
    content: "\e9b2";
    margin-left: -0.763671875em;
    color: rgb(207, 227, 232);
}
.dn-stocking .path3:before {
    content: "\e9b3";
    margin-left: -0.763671875em;
    color: rgb(207, 227, 232);
}
.dn-stocking .path4:before {
    content: "\e9b4";
    margin-left: -0.763671875em;
    color: rgb(42, 49, 63);
}
.dn-stocking .path5:before {
    content: "\e9b5";
    margin-left: -0.763671875em;
    color: rgb(42, 49, 63);
}
.dn-veterans-infographic .path1:before {
    content: "\e9b6";
    color: rgb(42, 49, 63);
}
.dn-veterans-infographic .path2:before {
    content: "\e9b7";
    margin-left: -1.2626953125em;
    color: rgb(237, 28, 43);
}
.dn-veterans-infographic .path3:before {
    content: "\e9b8";
    margin-left: -1.2626953125em;
    color: rgba(42, 49, 63, 0.3);
}
.dn-veterans-infographic .path4:before {
    content: "\e9b9";
    margin-left: -1.2626953125em;
    color: rgba(42, 49, 63, 0.3);
}
.dn-veterans-infographic .path5:before {
    content: "\e9ba";
    margin-left: -1.2626953125em;
    color: rgb(42, 49, 63);
}
.dn-veterans-infographic .path6:before {
    content: "\e9bb";
    margin-left: -1.2626953125em;
    color: rgba(42, 49, 63, 0.3);
}
.dn-veterans-infographic .path7:before {
    content: "\e9bc";
    margin-left: -1.2626953125em;
    color: rgb(42, 49, 63);
}
.dn-veterans-infographic .path8:before {
    content: "\e9bd";
    margin-left: -1.2626953125em;
    color: rgba(42, 49, 63, 0.3);
}
.dn-veterans-infographic .path9:before {
    content: "\e9be";
    margin-left: -1.2626953125em;
    color: rgb(255, 255, 255);
}
.dn-veterans-infographic .path10:before {
    content: "\e9bf";
    margin-left: -1.2626953125em;
    color: rgb(255, 255, 255);
}
.dn-veterans-infographic .path11:before {
    content: "\e9c0";
    margin-left: -1.2626953125em;
    color: rgb(255, 255, 255);
}
.dn-p5millionstat .path1:before {
    content: "\e9c1";
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path2:before {
    content: "\e9c2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-p5millionstat .path3:before {
    content: "\e9c3";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path4:before {
    content: "\e9c4";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path5:before {
    content: "\e9c5";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path6:before {
    content: "\e9c6";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path7:before {
    content: "\e9c7";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path8:before {
    content: "\e9c8";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path9:before {
    content: "\e9c9";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path10:before {
    content: "\e9ca";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path11:before {
    content: "\e9cb";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-p5millionstat .path12:before {
    content: "\e9cc";
    margin-left: -1em;
    color: rgb(42, 49, 63);
}
.dn-millionstat .path1:before {
    content: "\e9cd";
    color: rgb(42, 49, 63);
}
.dn-millionstat .path2:before {
    content: "\e9ce";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path3:before {
    content: "\e9cf";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path4:before {
    content: "\e9d0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path5:before {
    content: "\e9d1";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path6:before {
    content: "\e9d2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path7:before {
    content: "\e9d3";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path8:before {
    content: "\e9d4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path9:before {
    content: "\e9d5";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path10:before {
    content: "\e9d6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path11:before {
    content: "\e9d7";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path12:before {
    content: "\e9d8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path13:before {
    content: "\e9d9";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path14:before {
    content: "\e9da";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path15:before {
    content: "\e9db";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path16:before {
    content: "\e9dc";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path17:before {
    content: "\e9dd";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path18:before {
    content: "\e9de";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path19:before {
    content: "\e9df";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path20:before {
    content: "\e9e0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path21:before {
    content: "\e9e1";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path22:before {
    content: "\e9e2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path23:before {
    content: "\e9e3";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path24:before {
    content: "\e9e4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path25:before {
    content: "\e9e5";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path26:before {
    content: "\e9e6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path27:before {
    content: "\e9e7";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path28:before {
    content: "\e9e8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path29:before {
    content: "\e9e9";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path30:before {
    content: "\e9ea";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path31:before {
    content: "\e9eb";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path32:before {
    content: "\e9ec";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path33:before {
    content: "\e9ed";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path34:before {
    content: "\e9ee";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path35:before {
    content: "\e9ef";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path36:before {
    content: "\e9f0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path37:before {
    content: "\e9f1";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path38:before {
    content: "\e9f2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path39:before {
    content: "\e9f3";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path40:before {
    content: "\e9f4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path41:before {
    content: "\e9f5";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path42:before {
    content: "\e9f6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path43:before {
    content: "\e9f7";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path44:before {
    content: "\e9f8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path45:before {
    content: "\e9f9";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path46:before {
    content: "\e9fa";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path47:before {
    content: "\e9fb";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path48:before {
    content: "\e9fc";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path49:before {
    content: "\e9fd";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path50:before {
    content: "\e9fe";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path51:before {
    content: "\e9ff";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path52:before {
    content: "\ea00";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path53:before {
    content: "\ea01";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path54:before {
    content: "\ea02";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path55:before {
    content: "\ea03";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path56:before {
    content: "\ea04";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path57:before {
    content: "\ea05";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path58:before {
    content: "\ea06";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path59:before {
    content: "\ea07";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path60:before {
    content: "\ea08";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path61:before {
    content: "\ea09";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path62:before {
    content: "\ea0a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path63:before {
    content: "\ea0b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path64:before {
    content: "\ea0c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path65:before {
    content: "\ea0d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path66:before {
    content: "\ea0e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path67:before {
    content: "\ea0f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path68:before {
    content: "\ea10";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path69:before {
    content: "\ea11";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-millionstat .path70:before {
    content: "\ea12";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-stat .path1:before {
    content: "\ea13";
    color: rgb(42, 49, 63);
}
.dn-stat .path2:before {
    content: "\ea14";
    margin-left: -1.53515625em;
    color: rgb(244, 119, 128);
}
.dn-stat .path3:before {
    content: "\ea15";
    margin-left: -1.53515625em;
    color: rgb(235, 0, 41);
}
.dn-stat .path4:before {
    content: "\ea16";
    margin-left: -1.53515625em;
    color: rgb(235, 0, 41);
}
.dn-stat .path5:before {
    content: "\ea17";
    margin-left: -1.53515625em;
    color: rgb(235, 0, 41);
}
.dn-stat .path6:before {
    content: "\ea18";
    margin-left: -1.53515625em;
    color: rgb(235, 0, 41);
}
.dn-stat .path7:before {
    content: "\ea19";
    margin-left: -1.53515625em;
    color: rgb(235, 0, 41);
}
.dn-stat .path8:before {
    content: "\ea1a";
    margin-left: -1.53515625em;
    color: rgb(42, 49, 63);
}
.dn-stat .path9:before {
    content: "\ea1b";
    margin-left: -1.53515625em;
    color: rgb(42, 49, 63);
}
.dn-stat .path10:before {
    content: "\ea1c";
    margin-left: -1.53515625em;
    color: rgb(42, 49, 63);
}
.dn-stat .path11:before {
    content: "\ea1d";
    margin-left: -1.53515625em;
    color: rgb(42, 49, 63);
}
.dn-addiction:before {
    content: "\ea1e";
}
.dn-assistpeople:before {
    content: "\ea1f";
}
.dn-book:before {
    content: "\ea20";
}
.dn-bread:before {
    content: "\ea21";
}
.dn-breadth-of-service:before {
    content: "\ea22";
}
.dn-bricks:before {
    content: "\ea23";
}
.dn-briefcase:before {
    content: "\ea24";
}
.dn-center-heart:before {
    content: "\ea25";
}
.dn-cog:before {
    content: "\ea26";
}
.dn-dashed-circle:before {
    content: "\ea27";
}
.dn-disaster:before {
    content: "\ea28";
}
.dn-domestic_abuse:before {
    content: "\ea29";
}
.dn-domestic-abuse-stat:before {
    content: "\ea2a";
}
.dn-education:before {
    content: "\ea2b";
}
.dn-elderly:before {
    content: "\ea2c";
}
.dn-families-2:before {
    content: "\ea2d";
}
.dn-families:before {
    content: "\ea2e";
}
.dn-fire:before {
    content: "\ea2f";
}
.dn-flag .path1:before {
    content: "\ea30";
    color: rgb(42, 49, 63);
}
.dn-flag .path2:before {
    content: "\ea31";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-flag .path3:before {
    content: "\ea32";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-flag .path4:before {
    content: "\ea33";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-flag .path5:before {
    content: "\ea34";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.dn-flower:before {
    content: "\ea35";
}
.dn-folder:before {
    content: "\ea36";
}
.dn-globe:before {
    content: "\ea37";
}
.dn-gospel:before {
    content: "\ea38";
}
.dn-hand-with-shield .path1:before {
    content: "\ea39";
    color: rgb(237, 185, 93);
}
.dn-hand-with-shield .path2:before {
    content: "\ea3a";
    margin-left: -0.9267578125em;
    color: rgb(56, 67, 84);
}
.dn-hand-with-shield .path3:before {
    content: "\ea3b";
    margin-left: -0.9267578125em;
    color: rgb(42, 49, 63);
}
.dn-hand-with-shield .path4:before {
    content: "\ea3c";
    margin-left: -0.9267578125em;
    color: rgb(237, 28, 43);
}
.dn-hand-with-shield .path5:before {
    content: "\ea3d";
    margin-left: -0.9267578125em;
    color: rgb(255, 255, 255);
}
.dn-hand-with-shield .path6:before {
    content: "\ea3e";
    margin-left: -0.9267578125em;
    color: rgb(178, 21, 32);
}
.dn-hand-with-shield .path7:before {
    content: "\ea3f";
    margin-left: -0.9267578125em;
    color: rgb(119, 14, 22);
}
.dn-hand-with-shield .path8:before {
    content: "\ea40";
    margin-left: -0.9267578125em;
    color: rgb(255, 201, 9);
}
.dn-hand-with-shield .path9:before {
    content: "\ea41";
    margin-left: -0.9267578125em;
    color: rgb(255, 255, 255);
}
.dn-hand-with-shield .path10:before {
    content: "\ea42";
    margin-left: -0.9267578125em;
    color: rgb(255, 255, 255);
}
.dn-hand-with-shield .path11:before {
    content: "\ea43";
    margin-left: -0.9267578125em;
    color: rgb(255, 255, 255);
}
.dn-hand-with-shield .path12:before {
    content: "\ea44";
    margin-left: -0.9267578125em;
    color: rgb(255, 255, 255);
}
.dn-hand-with-shield .path13:before {
    content: "\ea45";
    margin-left: -0.9267578125em;
    color: rgb(255, 201, 9);
}
.dn-hand-with-shield .path14:before {
    content: "\ea46";
    margin-left: -0.9267578125em;
    color: rgb(255, 201, 9);
}
.dn-hand-with-shield .path15:before {
    content: "\ea47";
    margin-left: -0.9267578125em;
    color: rgb(255, 201, 9);
}
.dn-hand-with-shield .path16:before {
    content: "\ea48";
    margin-left: -0.9267578125em;
    color: rgb(247, 169, 33);
}
.dn-handup:before {
    content: "\ea49";
}
.dn-heartbeat:before {
    content: "\ea4a";
}
.dn-hearthand:before {
    content: "\ea4b";
}
.dn-holidays .path1:before {
    content: "\ea4c";
    color: rgb(42, 49, 63);
}
.dn-holidays .path2:before {
    content: "\ea4d";
    margin-left: -0.9267578125em;
    color: rgb(42, 49, 63);
}
.dn-holidays .path3:before {
    content: "\ea4e";
    margin-left: -0.9267578125em;
    color: rgb(237, 28, 43);
}
.dn-holidays .path4:before {
    content: "\ea4f";
    margin-left: -0.9267578125em;
    color: rgb(237, 28, 43);
}
.dn-home-2:before {
    content: "\ea50";
}
.dn-home .path1:before {
    content: "\ea51";
    color: rgb(157, 197, 154);
}
.dn-home .path2:before {
    content: "\ea52";
    margin-left: -2.109375em;
    color: rgb(92, 158, 87);
}
.dn-home .path3:before {
    content: "\ea53";
    margin-left: -2.109375em;
    color: rgb(157, 197, 154);
}
.dn-home .path4:before {
    content: "\ea54";
    margin-left: -2.109375em;
    color: rgb(92, 158, 87);
}
.dn-home .path5:before {
    content: "\ea55";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path6:before {
    content: "\ea56";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path7:before {
    content: "\ea57";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path8:before {
    content: "\ea58";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path9:before {
    content: "\ea59";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path10:before {
    content: "\ea5a";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path11:before {
    content: "\ea5b";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path12:before {
    content: "\ea5c";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path13:before {
    content: "\ea5d";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path14:before {
    content: "\ea5e";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path15:before {
    content: "\ea5f";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path16:before {
    content: "\ea60";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path17:before {
    content: "\ea61";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path18:before {
    content: "\ea62";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path19:before {
    content: "\ea63";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path20:before {
    content: "\ea64";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path21:before {
    content: "\ea65";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path22:before {
    content: "\ea66";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path23:before {
    content: "\ea67";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path24:before {
    content: "\ea68";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path25:before {
    content: "\ea69";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path26:before {
    content: "\ea6a";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path27:before {
    content: "\ea6b";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path28:before {
    content: "\ea6c";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path29:before {
    content: "\ea6d";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path30:before {
    content: "\ea6e";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path31:before {
    content: "\ea6f";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-home .path32:before {
    content: "\ea70";
    margin-left: -2.109375em;
    color: rgb(226, 236, 235);
}
.dn-hopeless:before {
    content: "\ea71";
}
.dn-hourglass .path1:before {
    content: "\ea72";
    color: rgb(42, 49, 63);
}
.dn-hourglass .path2:before {
    content: "\ea73";
    margin-left: -0.6962890625em;
    color: rgb(237, 28, 43);
}
.dn-hourglass .path3:before {
    content: "\ea74";
    margin-left: -0.6962890625em;
    color: rgb(237, 28, 43);
}
.dn-hunger:before {
    content: "\ea75";
}
.dn-jobs:before {
    content: "\ea76";
}
.dn-justice:before {
    content: "\ea77";
}
.dn-knifefork:before {
    content: "\ea78";
}
.dn-list:before {
    content: "\ea79";
}
.dn-love:before {
    content: "\ea7a";
}
.dn-magnifying-glass .path1:before {
    content: "\ea7b";
    color: rgb(42, 49, 63);
}
.dn-magnifying-glass .path2:before {
    content: "\ea7c";
    margin-left: -0.8154296875em;
    color: rgb(42, 49, 63);
}
.dn-magnifying-glass .path3:before {
    content: "\ea7d";
    margin-left: -0.8154296875em;
    color: rgb(42, 49, 63);
}
.dn-magnifying-glass .path4:before {
    content: "\ea7e";
    margin-left: -0.8154296875em;
    color: rgb(42, 49, 63);
}
.dn-magnifying-glass .path5:before {
    content: "\ea7f";
    margin-left: -0.8154296875em;
    color: rgb(42, 49, 63);
}
.dn-magnifying-glass .path6:before {
    content: "\ea80";
    margin-left: -0.8154296875em;
    color: rgb(42, 49, 63);
}
.dn-magnifying-glass .path7:before {
    content: "\ea81";
    margin-left: -0.8154296875em;
    color: rgb(175, 208, 216);
}
.dn-magnifying-glass .path8:before {
    content: "\ea82";
    margin-left: -0.8154296875em;
    color: rgb(237, 28, 43);
}
.dn-magnifying-glass .path9:before {
    content: "\ea83";
    margin-left: -0.8154296875em;
    color: rgb(244, 119, 128);
}
.dn-magnifying-glass .path10:before {
    content: "\ea84";
    margin-left: -0.8154296875em;
    color: rgb(223, 236, 239);
}
.dn-magnifying-glass .path11:before {
    content: "\ea85";
    margin-left: -0.8154296875em;
    color: rgb(175, 208, 216);
}
.dn-music:before {
    content: "\ea86";
}
.dn-need .path1:before {
    content: "\ea87";
    color: rgb(237, 28, 43);
}
.dn-need .path2:before {
    content: "\ea88";
    margin-left: -3.16015625em;
    color: rgb(237, 28, 43);
}
.dn-need .path3:before {
    content: "\ea89";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path4:before {
    content: "\ea8a";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path5:before {
    content: "\ea8b";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path6:before {
    content: "\ea8c";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path7:before {
    content: "\ea8d";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path8:before {
    content: "\ea8e";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path9:before {
    content: "\ea8f";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path10:before {
    content: "\ea90";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path11:before {
    content: "\ea91";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-need .path12:before {
    content: "\ea92";
    margin-left: -3.16015625em;
    color: rgb(42, 49, 63);
}
.dn-plate .path1:before {
    content: "\ea93";
    color: rgb(212, 214, 217);
}
.dn-plate .path2:before {
    content: "\ea94";
    margin-left: -1.50390625em;
    color: rgb(175, 208, 216);
}
.dn-plate .path3:before {
    content: "\ea95";
    margin-left: -1.50390625em;
    color: rgb(42, 49, 63);
}
.dn-plate .path4:before {
    content: "\ea96";
    margin-left: -1.50390625em;
    color: rgb(212, 214, 217);
}
.dn-plate .path5:before {
    content: "\ea97";
    margin-left: -1.50390625em;
    color: rgb(212, 214, 217);
}
.dn-plate .path6:before {
    content: "\ea98";
    margin-left: -1.50390625em;
    color: rgb(255, 255, 255);
}
.dn-prayer:before {
    content: "\ea99";
}
.dn-ribbon:before {
    content: "\ea9a";
}
.dn-soccer:before {
    content: "\ea9b";
}
.dn-speech-2:before {
    content: "\ea9c";
}
.dn-speech:before {
    content: "\ea9d";
}
.dn-swords:before {
    content: "\ea9e";
}
.dn-time .path1:before {
    content: "\ea9f";
    color: rgb(42, 49, 63);
}
.dn-time .path2:before {
    content: "\eaa0";
    margin-left: -0.962890625em;
    color: rgb(255, 248, 248);
}
.dn-time .path3:before {
    content: "\eaa1";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path4:before {
    content: "\eaa2";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path5:before {
    content: "\eaa3";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path6:before {
    content: "\eaa4";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path7:before {
    content: "\eaa5";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path8:before {
    content: "\eaa6";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path9:before {
    content: "\eaa7";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path10:before {
    content: "\eaa8";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path11:before {
    content: "\eaa9";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path12:before {
    content: "\eaaa";
    margin-left: -0.962890625em;
    color: rgb(42, 49, 63);
}
.dn-time .path13:before {
    content: "\eaab";
    margin-left: -0.962890625em;
    color: rgb(237, 28, 43);
}
.dn-trafficking:before {
    content: "\eaac";
}
.dn-traffickingstat .path1:before {
    content: "\eaad";
    color: rgb(42, 49, 63);
}
.dn-traffickingstat .path2:before {
    content: "\eaae";
    margin-left: -0.6591796875em;
    color: rgb(237, 28, 43);
}
.dn-traffickingstat .path3:before {
    content: "\eaaf";
    margin-left: -0.6591796875em;
    color: rgb(237, 28, 43);
}
.dn-traffickingstat .path4:before {
    content: "\eab0";
    margin-left: -0.6591796875em;
    color: rgb(237, 28, 43);
}
.dn-traffickingstat .path5:before {
    content: "\eab1";
    margin-left: -0.6591796875em;
    color: rgb(237, 28, 43);
}
.dn-traffickingstat .path6:before {
    content: "\eab2";
    margin-left: -0.6591796875em;
    color: rgb(237, 28, 43);
}
.dn-traffickingstat .path7:before {
    content: "\eab3";
    margin-left: -0.6591796875em;
    color: rgb(237, 28, 43);
}
.dn-trust:before {
    content: "\eab4";
}
.dn-veterans:before {
    content: "\eab5";
}
.dn-wallet:before {
    content: "\eab6";
}
.dn-woman-child:before {
    content: "\eab7";
}
.dn-icn-archive-bold:before {
    content: "\e93e";
}
.dn-icn-archive-thin:before {
    content: "\e93f";
}
.dn-icn-ministries:before {
    content: "\e940";
}
.dn-icn-landing:before {
    content: "\e941";
}
.dn-icn-lightbulb:before {
    content: "\e942";
}
.dn-icn-gavel:before {
    content: "\e943";
}
.dn-icn-invest-bold:before {
    content: "\e944";
}
.dn-icn-raft:before {
    content: "\e945";
}
.dn-icn-heart:before {
    content: "\e946";
}
.dn-icn-thought:before {
    content: "\e90b";
}
.dn-icn-walking:before {
    content: "\e93b";
}
.dn-icn-car:before {
    content: "\e93c";
}
.dn-icn-transit:before {
    content: "\e93d";
}
.dn-icn-new-page:before {
    content: "\e908";
}
.dn-icn-blank-page:before {
    content: "\e923";
}
.dn-icn-house:before {
    content: "\e931";
}
.dn-icn-star-outline:before {
    content: "\e932";
}
.dn-icn-star:before {
    content: "\e933";
}
.dn-icn-toggle-on .path1:before {
    content: "\e934";
    color: rgb(243, 243, 243);
}
.dn-icn-toggle-on .path2:before {
    content: "\e935";
    margin-left: -1.8232421875em;
    color: rgb(204, 204, 204);
}
.dn-icn-toggle-on .path3:before {
    content: "\e936";
    margin-left: -1.8232421875em;
    color: rgb(48, 155, 4);
}
.dn-icn-toggle-off .path1:before {
    content: "\e937";
    color: rgb(243, 243, 243);
}
.dn-icn-toggle-off .path2:before {
    content: "\e938";
    margin-left: -1.8232421875em;
    color: rgb(204, 204, 204);
}
.dn-icn-toggle-off .path3:before {
    content: "\e939";
    margin-left: -1.8232421875em;
    color: rgb(183, 0, 0);
}
.dn-icn-arrow-up:before {
    content: "\e93a";
}
.dn-icn-magnify:before {
    content: "\e900";
}
.dn-icn-circle:before {
    content: "\e901";
}
.dn-icn-advisory:before {
    content: "\e903";
}
.dn-icn-calendar:before {
    content: "\e904";
}
.dn-icn-book:before {
    content: "\e905";
}
.dn-icn-menu:before {
    content: "\e902";
}
.dn-icn-menu-bar:before {
    content: "\e906";
}
.dn-icn-invest:before {
    content: "\e909";
}
.dn-icn-hands:before {
    content: "\e907";
}
.dn-icn-hands-outline:before {
    content: "\e90a";
}
.dn-icn-phone-circle:before {
    content: "\e90c";
}
.dn-icn-email-circle:before {
    content: "\e90d";
}
.dn-icn-church:before {
    content: "\e90e";
}
.dn-icn-alerts:before {
    content: "\e913";
}
.dn-icn-theater-manager:before {
    content: "\e911";
}
.dn-icn-header-footer:before {
    content: "\e912";
}
.dn-icn-page-format:before {
    content: "\e914";
}
.dn-icn-seed-content:before {
    content: "\e915";
}
.dn-icn-media-library:before {
    content: "\e916";
}
.dn-icn-help:before {
    content: "\e917";
}
.dn-icn-properties:before {
    content: "\e918";
}
.dn-icn-social-media:before {
    content: "\e919";
}
.dn-icn-popout:before {
    content: "\e91a";
}
.dn-icn-distribute:before {
    content: "\e91b";
}
.dn-icn-trash:before {
    content: "\e91c";
}
.dn-icn-edit:before {
    content: "\e91d";
}
.dn-icn-new-slide:before {
    content: "\e91e";
}
.dn-icn-module:before {
    content: "\e91f";
}
.dn-icn-horn-outline:before {
    content: "\e920";
}
.dn-icn-horn:before {
    content: "\e922";
}
.dn-icn-settings:before {
    content: "\e921";
}
.dn-icn-admin-notifications:before {
    content: "\e927";
}
.dn-icn-integrations:before {
    content: "\e926";
}
.dn-icn-cir-twitter:before {
    content: "\e925";
}
.dn-icn-cir-youtube:before {
    content: "\e910";
}
.dn-icn-cir-facebook:before {
    content: "\e924";
}
.dn-icn-cir-instagram:before {
    content: "\e90f";
}
.dn-icn-salesforce:before {
    content: "\e928";
}
.dn-icn-desktop:before {
    content: "\e929";
}
.dn-icn-tablet:before {
    content: "\e92a";
}
.dn-icn-mobile:before {
    content: "\e92b";
}
.dn-path-9:before {
    content: "\e92c";
}
.dn-icn-book-outline:before {
    content: "\e92d";
}
.dn-icn-chat:before {
    content: "\e92e";
}
.dn-icn-analytics:before {
    content: "\e92f";
}
.dn-icn-marker:before {
    content: "\e930";
}
