.greyBoxes img {
	vertical-align: bottom;
}

.greyBoxes-title {
	vertical-align: middle;
	font-size: 18px;
	text-transform: none;
	font-weight: 700;
	line-height: 30px;
}

.greyBoxes-title {
	svg, img, .icon, .fa, .fab, .fas, .far {
		height: 30px;
		margin-right: 10px;
		min-width:30px;
		display:inline-block;
		line-height: 30px;
		text-align: center;
		background-size: contain;
		margin-bottom: -5px;
	}
}

.greyBoxes-box {
	@extend .u-greyLightBg;
    padding:30px 30px 10px;
	margin:0 0 12px 0;
	text-align:center;
}

.greyBoxes-box.u-textCenter ul {
	text-align: left;
}

.greyBoxes-box br {
	display:none;
}

.greyBoxes-gridItem {
	@extend .u-greyLightBg;
	max-width:49%;
	margin:1px;
	padding:30px 30px 10px;
	text-align: left;
}

@mixin desktop() {
	.greyBoxes {
		display: flex;
		align-items:stretch;
		justify-content: center;
		margin: 0 -6px;
	}
	.greyBoxes-box br {
		display:block;
	}
	.greyBoxes-box {
		justify-content:center;
		text-align: left;
		margin: 12px 6px 0;
		flex: 1 1 50%;
	}
	.greyBoxes-box.u-textCenter {
		text-align: center;
	}
	.greyBoxes-box.isFullWidth {
		flex:1 1 100%;
	}
	.greyBoxes-title {
		font-size: 22px;
	}
	.greyBoxes-box ul {
		display: inline-block;
		vertical-align: top;
		margin-top: 0;
		width: 48%;
	}
	.greyBoxes-box.u-textCenter ul {
		text-align: left;
	}
}

@media (min-width:$screen-md-min) {
	&:not(.preview-container) {
		@include desktop();
	}
}

&.preview-container.desktop {
	@include desktop();
}